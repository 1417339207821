import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'
import { useQuery } from 'wagmi'
import { ApiKey } from '../components/enums'

export const getIncidentApplicableCount = async () => {
  return axios.get(endpoints.incidentsApplicableCount)
}

export const useGetIncidentApplicableCount = () => {
  return useQuery([ApiKey.Incident_Applicable_Count], getIncidentApplicableCount)
}
