import React, { useEffect, useRef, useState } from 'react'
import { StyledLabel, StyledDropdownWrap } from 'components/form/Textfield.styles'
import Button from '@mui/material/Button'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuList from '@mui/material/MenuList'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ServiceListing } from 'features/quote/components/type'
import { CircularProgress } from '@mui/material'
import { CircularSpinnerCss } from 'features/incidents/components/style'
import { OptionsLabel } from 'features/incidents/components/enums'
import { Box } from 'components/elements'
import CSS from 'csstype'

export type Listing = {
  label: string
  value: string | number
  key?: number
  premiumAmount?: number
  maxCoverage?: number
  primaryService?: ServiceListing
  services?: ServiceListing[]
  discount?: number
}

type MenuDropdownProps = {
  label?: string
  value?: Listing
  placeholder?: string
  setValue?: (...arg: any) => void
  list?: Listing[]
  isResponseFetching?: boolean
  isDisabled?: boolean
  style?: CSS.Properties
}

export const MenuDropdown = ({ placeholder, label, value, setValue, list, isResponseFetching, isDisabled, style }: MenuDropdownProps) => {
  const [open, setOpen] = useState(false)
  const [filteredList, setFilteredList] = useState<Listing[] | undefined>(list)
  const [filterValue, setFilterValue] = useState<string>('')
  const anchorRef = useRef<HTMLButtonElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
    setFilterValue('')
  }

  useEffect(() => {
    if (!filterValue.trim()) {
      setFilteredList(list)
    } else {
      const filtered = list?.filter(item => item?.label.toLowerCase().includes(filterValue.trim().toLowerCase()))
      setFilteredList(filtered?.length ? filtered : list)
    }
  }, [filterValue, list])

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (open) {
      inputRef?.current?.focus()
    }
  }, [open])

  return (
    <StyledDropdownWrap>
      <StyledLabel css={{ color: '$gray5' }}>{label}</StyledLabel>
      <Button
        disabled={isDisabled}
        ref={anchorRef}
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleToggle}
        className="main"
        endIcon={isResponseFetching ? <CircularProgress size={12} sx={CircularSpinnerCss} /> : <KeyboardArrowDownIcon />}
      >
        {value?.label || placeholder}
      </Button>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal className="dropdownWidth" style={style}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <input type="text" placeholder="Search" className="dropdown-hidden" value={filterValue} onChange={e => setFilterValue(e.target.value)} ref={inputRef} />
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ padding: '.75rem 0', borderRadius: '.5rem' }}
                >
                  {filteredList && filteredList.length > 0 ? (
                    filteredList.map((item, index) => (
                      <li
                        key={index}
                        onClick={e => {
                          handleClose(e)
                          setValue &&
                            setValue({
                              label: item?.label,
                              value: item?.value,
                              key: item?.key,
                              premiumAmount: item?.premiumAmount || null,
                              maxCoverage: item?.maxCoverage || null,
                              primaryService: item?.primaryService || null,
                              services: item?.services || null,
                              discount: item?.discount,
                            })
                        }}
                        className="dropdownList"
                      >
                        {item?.label}
                      </li>
                    ))
                  ) : (
                    <Box as="li" className="dropdownList" aria-disabled css={{ cursor: 'not-allowed' }}>
                      {OptionsLabel.NO_OPTIONS}
                    </Box>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </StyledDropdownWrap>
  )
}
