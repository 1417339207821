import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiKey } from 'features/incidents/components/enums'

export const editProfile = async (data: object) => {
  return axios.put(endpoints.userProfile, data)
}

export const useEditProfile = () => {
  const client = useQueryClient()
  return useMutation(editProfile, {
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['auth-user'] })
      client.invalidateQueries([ApiKey.policies, ApiKey.list])
    },
  })
}
