export const validUrl = {
  // eslint-disable-next-line
  pattern: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  message: 'Please provide valid company url',
}

export const validDescription = {
  pattern: /^(?=\S)(?=.{5,200})([a-zA-Z0-9!@#$%^&*()[\]{}\-_+=~`|:;"'<>,.?/\n]+ ?)*[a-zA-Z0-9!@#$%^&*()[\]{}\-_+=~`|:;"'<>,.?/\n]$/,
  message: 'Please enter between 5 and 200 characters, and avoid ending with a space.',
}
export const validName = {
  pattern: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
  message: 'Can not enter only spaces ',
}
export const validIssue = {
  pattern: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
  message: 'Can not enter only spaces',
}
