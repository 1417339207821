import { Box } from 'components/elements/Box'
import { styled } from 'styles'
import { Grid } from '@mui/material'
import { BoxTextCenter } from 'views/style'
import { LandingPageVideoLink } from 'utils/enum'

const Hero = () => {
  return (
    <StyledSection>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={BoxTextCenter}>
          <Box>
            <Box
              as="h1"
              css={{
                'fontSize': '$2xl',
                'mb': '$2',
                '@sm': { fontSize: '$3xl' },
                '@md': { fontSize: '2.625rem' },
              }}
            >
              Fast Cyber Coverage, Faster Payouts
            </Box>
            <Box
              as="p"
              css={{
                'color': '$gray4',
                '@md': {
                  fontSize: '$xl',
                },
              }}
            >
              Maximize Financial Protection for Your Business Using Your Current IT Safeguards
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box css={{ 'dflex': 'center', '@md': { ml: 'auto', mr: '$2' } }}>
            <video autoPlay muted loop playsInline controls style={{ maxWidth: '70%' }}>
              <source src={LandingPageVideoLink.LINK} type="video/mp4" />
            </video>
          </Box>
        </Grid>
      </Grid>
    </StyledSection>
  )
}

// replace with genereic section
const StyledSection = styled('section', {
  'mt': '$12',
  'mb': '$12',
  '@md': {
    display: 'flex',
    alignItems: 'center',
  },
})

export default Hero
