import SnackBar from 'components/SnackBar'
import { Box, Flex } from 'components/elements'
import { TextField } from 'components/form'
import { Modal } from 'components/modal'
import { RegPattern, ServicesEnum, ValidationErrors } from 'utils/enum'
import { useAddCoveActivation } from '../api/coveActivation'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { errorMessage, getFormSchemaAndFields, getUpperCase } from 'utils/function'
import { useActivatePatchService } from '../api/activatePatchService'
import { useEditProfile } from '../api/editProfile'
import { Monitoring_Services_Notification } from 'configs/constants'
import { AxiosError } from 'axios'
import { ErrorBox } from './EditProfileForm'
import { GenericActivationModalProps, ServiceActivationKeys, ServiceModalProp } from 'features/quote/components/type'

const GenericActivationModal = (props: GenericActivationModalProps) => {
  const { active, hideModal, onAccept, title, formSchema, formFields, confirmLabel, isLoading, error } = props
  const isEDRModal = title?.includes('EDR')
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = (data: ServiceActivationKeys) => {
    onAccept(data)
  }
  const handleCancel = () => {
    hideModal()
    reset()
  }

  return (
    <Modal
      active={active}
      hideModal={handleCancel}
      onAccept={handleSubmit(onSubmit)}
      title={title}
      description={''}
      children={
        <Flex as="form" direction="column" css={{ gap: '$5' }}>
          {!!error && <ErrorBox>{getUpperCase(error)}</ErrorBox>}

          {formFields.map((field: { name: string; placeholder: string; label: string }) => (
            <TextField
              key={field.name}
              css={{ width: '100% !important' }}
              placeholder={field.placeholder}
              {...register(field?.name)}
              label={field.label}
              error={!!errors[field?.name]}
              helperText={(errors[field?.name]?.message as string) ?? ''}
              type={'text'}
            />
          ))}
          {isEDRModal && (
            <Box as="p">
              Don’t have EDR?&nbsp;
              <Box as="a" css={{ color: '$primary', cursor: 'pointer' }} href="https://www.asgardmsp.com/services/endpoint-security/" target="_blank">
                Click Here&nbsp;
              </Box>
              to learn more and get covered now.
            </Box>
          )}
        </Flex>
      }
      isForm={true}
      acceptText={isLoading ? 'Connecting' : confirmLabel}
    />
  )
}
export const ServiceActivationModal = (props: ServiceModalProp) => {
  const { isOpenModal, setIsOpenModal, setInitialCheckDone } = props
  const [notification, setNotification] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')
  const { mutate: CoverService, isLoading: isLoadingCove } = useAddCoveActivation()
  const { mutate: PatchService, isLoading: isLoadingPatch } = useActivatePatchService()
  const { mutate: editProfile, isLoading: editingProfile } = useEditProfile()
  const { schema, fields, title } = getFormSchemaAndFields(isOpenModal as ServicesEnum)
  useEffect(() => {
    const customs_message = Monitoring_Services_Notification[isOpenModal as ServicesEnum]
    if (customs_message?.Notification) {
      setNotificationMessage(customs_message.Notification)
    }
  }, [isOpenModal])

  const handleSubmit = (data: ServiceActivationKeys) => {
    let apiCall
    if (isOpenModal === ServicesEnum.COVE_DATA) apiCall = CoverService
    else if (isOpenModal === ServicesEnum.N_SIGHT_PATCH) apiCall = PatchService
    else if (isOpenModal === ServicesEnum.EDR) apiCall = editProfile
    setSubmitError('')
    //creating new object at run time based on API need,as data hold all the states
    const obj: Record<string, string | null | undefined> = {}

    fields.forEach((field: { name: string }) => {
      const fieldName = field.name as keyof ServiceActivationKeys
      //adding the  endpoint to the url
      if (fieldName === 'sentinelOneConsoleUrl') {
        obj[fieldName] = data[fieldName] + 'web/api/v2.1/threats' ?? null
      } else {
        obj[fieldName] = data[fieldName] ?? null
      }
    })
    if (apiCall) {
      apiCall(obj, {
        onSuccess: () => {
          setNotification(true)
          setIsOpenModal(null)
          setSubmitError('')
          setInitialCheckDone?.(true)
        },
        onError: (error: unknown) => {
          if (error instanceof AxiosError) {
            setSubmitError(errorMessage(error))
          }
        },
      })
    }
  }
  return (
    <>
      <GenericActivationModal
        active={isOpenModal !== null}
        hideModal={() => {
          setIsOpenModal(null)
          setSubmitError('')
        }}
        onAccept={(data: ServiceActivationKeys) => {
          handleSubmit(data)
        }}
        title={title}
        formSchema={schema}
        formFields={fields}
        confirmLabel="Connect"
        error={submitError}
        isLoading={isLoadingCove || isLoadingPatch || editingProfile}
      />
      <SnackBar
        open={notification}
        onClose={() => {
          setNotification(false)
          setIsOpenModal(null)
        }}
        text={notificationMessage}
        severity={'success'}
      />
    </>
  )
}

export const schema = Yup.object().shape({
  password: Yup.string().required(ValidationErrors.PASSWORD),
  userName: Yup.string().email(ValidationErrors.EMAIL_VALIDATION).required(ValidationErrors.EMAIL),
})
export const patchSchema = Yup.object().shape({
  apiKey: Yup.string().required(ValidationErrors.Patch).matches(RegPattern.NO_WHITE_SPACE, ValidationErrors.NO_WHITE_SPACE),
})
export const edrScheme = Yup.object().shape({
  sentinelOneApiKey: Yup.string().required(ValidationErrors.SENTINEL).matches(RegPattern.NO_WHITE_SPACE, ValidationErrors.NO_WHITE_SPACE),
  sentinelOneConsoleUrl: Yup.string().required(ValidationErrors.SENTINELONE_URL).matches(RegPattern.SENTINELONE_URL, ValidationErrors.SENTINELONE_URL_VALIDATION),
})
