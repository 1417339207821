import { useMutation } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'

type SupportRequestDTO = {
  email: string
  name: string
  companyName: string
  coverage: string
}

export function supportRequest(data: SupportRequestDTO) {
  return axios.post(endpoints.supportRequest, data)
}

export const useSupportRequest = () => {
  return useMutation(supportRequest)
}
