export enum IncidentEnums {
  SENTINEL_VALIDATION = 'Sentinel key is required',
  SENTINEL_SUCCESS_MESSAGE = 'Sentinel key updated successfully',
  SENTINEL_ERROR_MESSAGE = 'Failed to update sentinel key',
  DESCRIPTION_AMOUNT = 'Aggregate Coverage Amount',
  DESCRIPTION_DATE = 'Validation Date',
  INSURANCE_DETAIL = 'insuranceDetail',
  PENDING = 'Pending',
  VALID = 'Valid',
  INVALID = 'Invalid',
  RISK_SCORE = 'Risk Score',
  THREAT_STATUS = 'threatStatus',
  THREAT_ID = 'threatId',
  AGENT_DETECTION_INFO = 'Agent Detection Info',
  GROUP_ID = 'Group Id',
  GROUP_NAME = 'Group Name',
  AGENT_OS_NAME = 'Agent Os Name',
  THREAT_NAME = 'Threat Name',
  THREAT_INFO = 'Threat Info',
  COMPANY_URL = 'companyLogoUrl',
  COMPANY_NAME = 'companyName',
  PLAN_NAME = 'planName',
  ACTIVE = 'active',
  CREATED_AT = 'CreatedAt',
  THREAT_CREATED_AT = 'Created At',
  ACTIVED = 'Active',
  regOnChainStatus = 'regOnChainStatus',
  REG_ON_CHAIN_STATUS = 'Reg On Chain Status',
  MISP_DATA = 'mispData',
}
export enum InsurancePolicyEnum {
  ACTIVED = 'Active',
  CANCELLED = 'Cancelled',
  PAYMENT_OVER_DUE = 'Payment Overdue',
  EXPIRED = 'Expired',
  MESSAGE = 'you have not been assigned any plan yet',
  PENDING = 'Service Activation Pending',
}
export enum FileExtension {
  PDF = '.pdf',
  IMAGE = '.img',
  DOCX = '.docx',
}
export const AlertKeyEnum = {
  STATUS: 'status',
  FA: '2FA',
  EDR: 'EDR',
  BACK_UPS: 'Backups',
  PERSONAL_TRAINING: 'Personal Training',
  PATCH_MANAGEMENT: 'Patch Management',
  PREMIUM: 'Premium',
}
export enum STATUS_MAPPING {
  ACTIVE = 'Active',
  SUCCESS = 'success',
  PENDING = 'Pending',
  WARNING = 'warning',
  INACTIVE = 'Inactive',
  ERROR = 'error',
  COMING_SOON = 'coming soon',
  EXPIRED = 'Expired',
}
export enum ApiKey {
  message = 'message',
  comment = 'comments',
  insurance = 'insurance',
  request = 'request',
  features = 'features',
  list = 'list',
  policies = 'policies',
  incidents = 'incidents',
  incidentsHash = 'incidentshash',
  paymentStripe = 'paymentStripe',
  scoreCard = 'soreCard',
  Quote = 'Quote',
  Wait = 'Wait',
  Blog = 'Blog',
  QuoteReg = 'QuoteRegister',
  Services = 'services',
  planListing = 'planListing',
  cove = 'cove',
  CoveLisiting = 'CoveLisiting',
  PreviousPolicies = 'PreviousPolicies',
  N_SIGHT_PATCH_Listing = 'N Sight Listing',
  Incident_Applicable_Count = 'incident applicable count',
  merchantAffiliateDetails = 'merchant affiliate details',
  details = 'details',
  MISP = 'mip',
}

export enum Comment {
  message = 'message',
  userThreatId = 'userThreatId',
  type = 'type',
}
export enum Comment_Type {
  type = 'External',
}
export enum AlertKeyEnums {
  CONFIDENCE_LEVEL = 'Confidence Level',
  CLASSIFICATION = 'Classification',
}

export enum ClaimStatus {
  ALL = 'All',
  APPLICABLE = 'Applicable',
  NOT_APPLICABLE = 'Not Applicable',
  CLAIM_RAISED = 'Claim Raised',
  APPROVED = 'Approved',
  DISAPPROVED = 'Disapproved',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  RE_EVALUATE = 'Re-Evaluate',
  PENDING = 'Pending',
}

export enum Assessment {
  COMING_SOON = 'Coming Soon',
  ADD = 'Add',
  CONNECT = 'Connect ',
}

export enum PlanStatus {
  ALL = 'All',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  OVERDUE = 'Payment Overdue',
}

export type LossRunReportProps = {
  title: string
  threatName?: string
  classification?: string
  description?: string
  documentUrl?: string[]
  documentName?: string
  amount?: string
  time?: string
  show: boolean
  status?: string
  lossAmount?: string
}
export enum ReactionRespone {
  ERROR = 'jwt is required',
  CUSTOMERROR = 'Please Login First',
  LIKE = 'Like',
  DISLIKE = 'Dislike',
  CLEAR = 'Clear',
}

export enum IncidentStatus {
  ALL = 'All',
  INCIDENT_DETECTED = 'Incident Detected',
  INCIDENT_VERIFIED = 'Incident Verified',
  CLAIM_INITIATED = 'Claim Initiated',
  INSURANCE_CONFIRMATION = 'Insurance Confirmation',
  CLAIM_RESOLVED = 'Claim Resolved',
}

export enum ThreatStatus {
  CLAIM_INITIATED = 'Claim Initiated',
  INCIDENT_DETECTED = 'Incident Detected',
  INCIDENT_VERIFIYING = 'Incident Verified',
  INSURANCE_CONFIRMATION = 'Insurance Confirmation',
  CLAIM_RESOLVED = 'Claim Resolved',
}

export enum IncidentEnum {
  SENTINEL_VALIDATION = 'Sentinel key is required',
  SENTINEL_SUCCESS_MESSAGE = 'Sentinel key updated successfully',
  SENTINEL_ERROR_MESSAGE = 'Failed to update sentinel key',
  DESCRIPTION_AMOUNT = 'Aggregate Coverage Amount',
  DESCRIPTION_DATE = 'Validation Date',
  INSURANCE_DETAIL = 'Insurance Detail',
  PENDING = 'Pending',
  VALID = 'Valid',
  INVALID = 'Invalid',
  RISK_SCORE = 'Risk Score',
  RE_EVALUATION = 'Re-evaluation',
  CASE_CLOSED = 'CASE CLOSED',
  ACTION_COMPLETED_SUCCESSFULLY = 'ACTION COMPLETED SUCCESSFULLY',
  DOC_LABEL = 'Upload an Document',
  DOC_UPLOAD_LABEL = 'Upload an official file/doc supporting your action',
  USER_DOC_UPLOAD_LABEL = 'User Re-evalution file/doc supporting the claim amount',
}

export enum TabNavigation {
  Tab0 = 0,
  Tab1 = 1,
  Tab2 = 2,
}
export enum BlogContentType {
  IMAGE = 'image',
  PACKAGE_IMAGE = 'IMAGE',
  EMBEDDED_LINK = 'EMBEDDED_LINK',
  LINK = 'LINK',
}
export enum ReactQueryStatus {
  LOADING = 'loading',
}
export enum ModalEnum {
  ADD_SERVICES = 'Add Services',
  CHANGE_POLICY = 'Change Policy',
  CHANGE_PaymentMethod = 'Change PaymentMethod',
  RECURRING_PAYMENT = 'Recurring Payment',
}
export enum EvaluationStatus {
  EDR = 'EDR',
  COVE = 'Cove',
  NSIGHT = 'NSight',
}
export enum RECURRING_PAYMENTS {
  ENABLE = 'enable',
  DISABLE = 'disable',
}
export enum StripePaymentStatus {
  PAYING = 'Paying',
  PAY = 'Pay',
  CHANGE = 'Change',
  CHANGING = 'Changing',
}
export enum StripeCardType {
  VISA = 'visa',
  MASTER = 'mastercard',
  AMEX = 'amex',
  DISCOVER = 'discover',
  JCB = 'jcb',
  DINERS = 'diners',
  ELO = 'elo',
}
export enum AssessmentStatus {
  ACTIVE = ' is connected so your policy is Active now',
  NON_ACTIVE = ' to activate your Policy',
}
export enum Mitigation_Status {
  NOT_MITIGATED = 'Not Mitigated',
  MITIGATED = 'mitigated',
}
export enum Environment_Status {
  DEVELOPMENT = 'development',
}
export enum Quote_Email {
  EMAIL = 'info@dltalert.com',
  SUBJECT = 'Request For Warranty Info',
  BODY = 'Please send me more information.',
}
export enum OptionsLabel {
  NO_OPTIONS = 'No option available',
}
export enum PrimaryService_ID {
  EDR = 1,
  COVE_DATA = 2,
  N_SIGHT_PATCH = 4,
}
export enum Zendesk_Email {
  EMAIL = 'support@dltalert.com',
}
export enum BoxUserEnum {
  PLAN_ID = 279,
  PLAN_NAME = 'Ransomware policy (EDR)',
  COVERAGE_VALUE = '5000',
  AFFILIATE_ID = 'vOkMHzjcIo0zUDo7',
}
