import HeroSection from './Hero'
import AttackProcess from './AttackProcess'
import C2Action from './C2Action'
import CoverageSteps from './CoverageSteps'
import OurNetwork from './OurNetwork'
import PitchSection from './Pitch'
import { useEffect } from 'react'
import CyberWarranty from './CyberWarrnaty'
import QuoteButton from './QuoteButton'
import Zendesk from './Zendesk'

const HomeView = () => {
  useEffect(() => {
    window.scrollTo(0, 0)

    document.title = 'Cyber Security Warranty Provider with Coverage & Protections'
    const metaList = document.getElementsByTagName('meta')
    for (let i = 0; i < metaList.length; i++) {
      if (metaList[i].name === 'description') {
        metaList[i].content =
          'Stay cyber secured with DLT Alert! Get blockchain-based cyber security warranty and coverage of your invaluable data. Perfect for startups and SMBs. Sign up now!'
      }
    }
  }, [])

  return (
    <>
      <HeroSection />
      <Zendesk />
      <QuoteButton />
      <CyberWarranty />
      <CoverageSteps />
      <PitchSection />
      <AttackProcess />
      <QuoteButton />
      <OurNetwork />
      <C2Action />
      <QuoteButton />
    </>
  )
}

export default HomeView
