import { Monitoring_Notification, ServiceConfig } from 'features/quote/components/type'
import {
  ClaimStatus,
  CommunicationMediumEnum,
  NotificationMessages,
  ServicesAssessmentsEnum,
  ServicesEnum,
  ThreatMonitoringStartDate,
  TimeZone,
  ComingSoonServicesEnum,
} from 'utils/enum'

export const NODE_ENV = process.env.REACT_APP_NODE_ENV
export const API_URL = process.env.REACT_APP_API_URL
export const POLY_EXPLORER_URL = process.env.REACT_APP_EXPLORER_URL
export const PLOYGON_AMOY_RPC_URL = process.env.REACT_APP_PLOYGON_AMOY_RPC_URL
export const Contract_Address = process.env.REACT_APP_CONTRACT_ADDRESS
export const Chain_Id = Number(process.env.REACT_APP_CHAIN_ID)
export const StripPublicKey = String(process.env.REACT_APP_API_STRIPE_PAYEMENT)
export const routes = {
  // general
  home: '/',
  about: '/about',
  security: '/security',
  support: '/support',
  warranty: '/warranty',
  blog: '/blog',
  blogCatagory: '/blog/:catagory',
  blogDetail: '/blog/:catagory/:detail/:id',
  stripPayment: '/stripe-payment',
  //quote
  qoute: '/CRC/get-a-qoute', //first time login
  quoteSupportForm: '/get-a-quote-support',
  quoteSentinelOne: '/get-a-quote-sentinel-one',

  //affiliate id base user by subadmin carrier
  customLanding: '/box-carrier',

  // auth
  login: '/auth/login',
  register: '/auth/register',
  reset: '/auth/reset-password',
  forgot: '/auth/forgot-password',
  sentinel: '/misc/sentinel-one',
  sentinelNew: '/misc/sentinel-one/new',
  accountVerification: '/auth/account-verification',
  // dashboard
  dashboard: '/app/incidents',
  incidents: '/app/incidents',
  insurancePolicy: '/app/insurance',
  editProfile: '/app/profile/edit',
  changePass: '/app/profile/change',
  assessments: '/app/profile/assessments',
  insuranceForm: '/Insurance-form',
  resendVerificationLink: '/auth/resend-verification-link',
}
export const steps = ['Incident Detected', 'Verifiying Incident', 'Initiate Claim', 'Insurance Confirmation', ' Resolved']
export const ClaimStatusList = [
  {
    label: ClaimStatus.ALL,
    value: '',
  },
  {
    label: ClaimStatus.APPLICABLE,
    value: ClaimStatus.APPLICABLE,
  },
  {
    label: ClaimStatus.NOT_APPLICABLE,
    value: ClaimStatus.NOT_APPLICABLE,
  },
  {
    label: ClaimStatus.CLAIM_RAISED,
    value: ClaimStatus.CLAIM_RAISED,
  },
  {
    label: ClaimStatus.APPROVED,
    value: ClaimStatus.APPROVED,
  },
  {
    label: ClaimStatus.DISAPPROVED,
    value: ClaimStatus.DISAPPROVED,
  },
  {
    label: ClaimStatus.ACCEPTED,
    value: ClaimStatus.ACCEPTED,
  },
  {
    label: ClaimStatus.REJECTED,
    value: ClaimStatus.REJECTED,
  },
  {
    label: ClaimStatus.RE_EVALUATE,
    value: ClaimStatus.RE_EVALUATE,
  },
]
export const startDates = [
  {
    label: 'As soon as possible',
    value: ThreatMonitoringStartDate.ASAP,
  },
  {
    label: 'Withing a month or two',
    value: ThreatMonitoringStartDate.IN_MONTH,
  },
  {
    label: '2-4 months',
    value: ThreatMonitoringStartDate.TWO_TO_FOUR_MONTHS,
  },
  {
    label: 'More than 4 months in advance',
    value: ThreatMonitoringStartDate.MORE_THAN_4_Months,
  },
]
export const commMediumList = [
  {
    label: CommunicationMediumEnum.PHONENUMBER,
    value: CommunicationMediumEnum.PHONENUMBER,
  },
  {
    label: CommunicationMediumEnum.EMAIL,
    value: CommunicationMediumEnum.EMAIL,
  },
  {
    label: CommunicationMediumEnum.WHATSAPP,
    value: CommunicationMediumEnum.WHATSAPP,
  },
]

export const TimeList = [
  {
    label: TimeZone.MORNING,
    value: TimeZone.MORNING_VALUE,
  },
  {
    label: TimeZone.AFTERNOON,
    value: TimeZone.AFTERNOON_VALUE,
  },
  {
    label: TimeZone.EVENING,
    value: TimeZone.EVENING_VALUE,
  },
]

//TODO:In start client want all the services handling,and now only three of them is integrated
// right now the all the catageorization and classfication done in FE
// Next scope all this will be done from BE
//was handle in generic way,which is now conflict so divvide into 2 group
export const serviceLableAssessments: { [key in ServicesEnum | string]: string } = {
  [ServicesEnum.COVE_DATA]: 'Backup',
  [ServicesEnum.EDR]: 'EDR',
  [ServicesEnum.N_SIGHT_PATCH]: 'Patch Management',
  [ServicesEnum.N_SIGHT_AV]: 'Anti-Virus',
  [ServicesEnum.N_CENTERAL]: 'Patch Management',
}

export const serviceArrayAssessment = [ServicesEnum.EDR, ServicesEnum.COVE_DATA, ServicesEnum.N_SIGHT_AV, ServicesEnum.N_SIGHT_PATCH, ServicesEnum.N_CENTERAL]

export const serviceArray = [ServicesEnum.EDR, ServicesEnum.COVE_DATA, ServicesEnum.N_SIGHT_PATCH]

export const serviceConfig: ServiceConfig = {
  [ServicesEnum.EDR]: {
    label: 'EDR',
    title: 'How to get started with EDR',
    youtubeLink: 'https://www.youtube.com/embed/sTwJ7Cu0w-g',
  },
  [ServicesEnum.COVE_DATA]: {
    label: 'Cove',
    title: 'How to get started with Cove',
    youtubeLink: 'https://www.youtube.com/embed/AtDAOfP8Wn8',
  },
  [ServicesEnum.N_SIGHT_PATCH]: {
    label: 'N Sight Patch Management',
    title: 'How to get started with N-Sight-Patch Management',
    youtubeLink: 'https://www.youtube.com/embed/vWgwv6R7-ZI',
  },
  [ServicesEnum.N_SIGHT_AV]: {
    label: 'N-Sight AV',
    title: 'How to get started with N-Sight AV Management',
    youtubeLink: 'https://www.youtube.com/embed/x4K4UAuP_gc',
  },
  [ServicesEnum.N_CENTERAL]: {
    label: 'N-Central',
    title: 'How to get started with N-Central',
    youtubeLink: 'https://www.youtube.com/embed/x4K4UAuP_gc',
  },
  [ServicesAssessmentsEnum.VEEM]: {
    label: '',
    title: '',
    youtubeLink: '',
  },
}

export const Monitoring_Services_Notification: Monitoring_Notification = {
  [ServicesEnum.EDR]: {
    Notification: NotificationMessages.SENTINEL_KEY,
  },
  [ServicesEnum.N_SIGHT_PATCH]: {
    Notification: NotificationMessages.Patch_N_Sight,
  },
  [ServicesEnum.COVE_DATA]: {
    Notification: NotificationMessages.COVE,
  },
  [ServicesEnum.N_SIGHT_AV]: {
    Notification: '',
  },
  [ServicesEnum.N_CENTERAL]: {
    Notification: '',
  },
}
export const IncidentArray = ['EDR', 'Cove Data Backup', 'N Sight AV', 'N Sight Patch']
export const comingSoonServices = [
  ComingSoonServicesEnum.Crowd_Strike,
  ComingSoonServicesEnum.Bitdefender_Gravity,
  ComingSoonServicesEnum.Malware_Bytes,
  ComingSoonServicesEnum.Windows_Defender,
]
export const galaxyImageStaticDataArray = [
  {
    Orgc: {
      id: '3',
      name: 'CIRCL',
      uuid: '55f6ea5e-2c60-40e5-964f-47a8950d210f',
      local: false,
    },
    Tag: [
      {
        local: false,
        id: '7',
        name: 'tlp:white',
        colour: '#ffffff',
        numerical_value: null,
      },
      {
        local: false,
        id: '8',
        name: 'osint:source-type="blog-post"',
        colour: '#00223b',
        numerical_value: null,
      },
      {
        local: false,
        id: '148',
        name: 'misp-galaxy:ransomware="WannaCry"',
        colour: '#0088cc',
        numerical_value: null,
      },
    ],
  },

  {
    Orgc: {
      id: '31',
      name: 'abuse.ch',
      uuid: '9b086132-8588-49ed-97fd-8578a777822c',
      local: false,
    },
    Tag: [
      {
        id: '1437',
        name: 'exe',
        colour: '#D984D4',
        numerical_value: null,
        is_galaxy: false,
        local: false,
      },
      {
        id: '3573',
        name: 'WannaCry',
        colour: '#130D20',
        numerical_value: null,
        is_galaxy: false,
        local: false,
      },
      {
        id: '5',
        name: 'type:OSINT',
        colour: '#004646',
        numerical_value: null,
        inherited: 1,
      },
      {
        id: '7',
        name: 'tlp:white',
        colour: '#ffffff',
        numerical_value: null,
        inherited: 1,
      },
    ],
  },
]
