import { ServicesForm } from './GetAQuoteProcess/ServicesForm'
import { useEffect, useState } from 'react'
import { GetAQuoteForm } from './GetAQuoteForm'
import { Stages } from 'utils/enum'
import { QuoteServicesProps, PlanSelectionReviewProps, BoxCarrierDetails } from './type'
import PlanSelection from './GetAQuoteProcess/PlanSelection'
import CoveragePlan from './GetAQuoteProcess/CoveragePlan'
import QuoteFormRegistered from './GetAQuoteProcess/UserQuoteFormRegistered'
import QuoteReview from './GetAQuoteProcess/QuoteReview'
import StripeComponent from 'features/stripe/page/StripeComponent'
import { PaymentQuoteForm } from 'features/stripe/components/PaymentQuote'
import { useLocation } from 'react-router-dom'
import { useGetMerchantAffiliateDetails } from '../api/getmerchantAffiliateDetails'
import { AxiosError } from 'axios'
import AffiliateUserLandingPage from 'features/customLandingPage/component/AffiliateUserLandingPage'

const GetAQuoteRegister = () => {
  const [stage, setStage] = useState<Stages>(Stages.STAGE1)
  const [quoteUserReg, setQuoteUserReg] = useState<QuoteServicesProps>()
  const [quoteReview, setQuoteReview] = useState<PlanSelectionReviewProps>()
  const [carrierDetails, setCarrierDetails] = useState<BoxCarrierDetails>()
  // button of register form disabled till box carrier plan auto assign
  const [isMerchantAffiliateDetailSet, setIsMerchantAffiliateDetailSet] = useState(true)
  const location = useLocation()
  //if url has sepecific affiliateId then user is coming from box carrier
  //and there policy and coverage is already selected
  const searchParams = new URLSearchParams(location.search)
  const carrier_AffiliateId = searchParams.get('affiliateId') || ''

  const { data: merchantAffiliateDetails, isFetched, error } = useGetMerchantAffiliateDetails({ affiliateId: carrier_AffiliateId })
  const merchantInfo = merchantAffiliateDetails?.data

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (isFetched && merchantAffiliateDetails) {
      const boxUserPreSelectedPlan = {
        ...quoteUserReg,
        coverage: merchantInfo?.maxCoverage,
        planId: merchantInfo?.id,
        service: [merchantInfo?.primaryServiceId],
      }
      const boxCarrierDetails = {
        companyLogo: merchantInfo?.comapnyLogo,
        companyName: merchantInfo?.companyName,
        companyWebsite: merchantInfo?.companyWebsite,
        policyName:merchantInfo?.name
      }
      setQuoteUserReg(boxUserPreSelectedPlan)
      setCarrierDetails(boxCarrierDetails)
      setIsMerchantAffiliateDetailSet(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, merchantAffiliateDetails])
  //if the user is coming from box carrier(specific carrier) then plan is preassigned and user is paid .only registration is required
  const apiError = error instanceof AxiosError ? error.response?.data?.message : undefined
  if (carrier_AffiliateId) {
    return (
      <AffiliateUserLandingPage
        isMerchantAffiliateDetailSet={isMerchantAffiliateDetailSet}
        apiError={apiError}
        quoteReview={quoteReview}
        setQuoteReview={setQuoteReview}
        setQuoteUserReg={setQuoteUserReg}
        quoteUserReg={quoteUserReg}
        setStage={setStage}
        carrierDetails={carrierDetails}
      />
    )
  } else {
    if (stage === Stages.STAGE2) return <ServicesForm setStage={setStage} setQuoteUserReg={setQuoteUserReg} quoteUserReg={quoteUserReg} />
    else if (stage === Stages.STAGE3) return <CoveragePlan setQuoteUserReg={setQuoteUserReg} quoteUserReg={quoteUserReg} setStage={setStage} />
    else if (stage === Stages.STAGE4)
      return <GetAQuoteForm quoteReview={quoteReview} setQuoteReview={setQuoteReview} setQuoteUserReg={setQuoteUserReg} quoteUserReg={quoteUserReg} setStage={setStage} />
    else if (stage === Stages.STAGE5) return <QuoteReview setStage={setStage} quoteReview={quoteReview} quoteUserReg={quoteUserReg} />
    else if (stage === Stages.STAGE6) return <QuoteFormRegistered setQuoteUserReg={setQuoteUserReg} setStage={setStage} />
    else if (stage === Stages.STAGE7) return <StripeComponent WrappedComponent={() => <PaymentQuoteForm quoteUserReg={quoteUserReg} quoteReview={quoteReview} />} />
    return <PlanSelection setQuoteReview={setQuoteReview} setQuoteUserReg={setQuoteUserReg} quoteUserReg={quoteUserReg} setStage={setStage} />
  }
}

export default GetAQuoteRegister
