import { styled } from 'styles'

const StyledButton = styled('button', {
  'display': 'inline-flex',
  'padding': 0,
  'border': 'none',
  'alignItems': 'center',
  'justifyContent': 'center',
  'flexShrink': 0,
  'userSelect': 'none',
  'cursor': 'pointer',
  'minWidth': '160px',
  'flexGrow': 1,

  '@sm': {
    flexGrow: 'initial',
  },

  '&:disabled': {
    opacity: 0.5,
    pointerEvents: 'none',
  },

  'variants': {
    size: {
      sm: {
        borderRadius: '$sm',
        padding: '.65rem 1.15rem',
        fontSize: '$base',
        minWidth: '120px',
      },
      md: {
        borderRadius: '$sm',
        padding: '.88rem 1.25rem',
        fontSize: '$base',
      },
      lg: {
        borderRadius: '$sm',
        padding: '1.15rem 2.5rem',
        fontSize: '$base',
      },
    },
    capitalized: {
      true: {
        textTransform: 'uppercase',
      },
    },
    color: {
      primary: {
        'backgroundColor': '$primary',
        // boxShadow: "inset 0 0 0 1px $colors$blue7",
        'border': '1px solid $primaryBorder',
        'color': '$white',
        '&:hover': {
          boxShadow: 'inset 0 0 0 1px $primary',
          bgColor: '$primaryHover',
        },
        '&:focus': {
          boxShadow: 'inset 0 0 0 1px $colors$blue8',
        },
        '&:active': {
          boxShadow: 'inset 0 0 0 1px $colors$blue8',
        },
      },
      secondary:{
        'backgroundColor': '$primaryMain',
        'border': '1px solid $primaryMain',
        'color': 'black',
        '&:hover': {
          boxShadow: 'inset 0 0 0 1px #f1cb32',
          bgColor: '#f1cb32',
          color:'$white77'
        },
        '&:focus': {
          boxShadow: 'inset 0 0 0 1px $colors$blue8',
        },
        '&:active': {
          boxShadow: 'inset 0 0 0 1px $colors$blue8',
        },
      }
    },
    bordered: {
      true: {
        'bgColor': 'transparent',
        'boxShadow': 'none',
        '&:hover': {
          boxShadow: 'inset 0 0 0 1px $primary',
          bgColor: '$primary10',
        },
      },
      false:{
        bgColor:'$white77',
        'border': '1px solid $primaryMain',
        'boxShadow': 'none',
        color:'$primaryMain',
        '&:hover': {
          boxShadow: 'inset 0 0 0 1px $primaryMain',
          bgColor: '$primaryMain',
          color:'black'
        },
      }
    },
    transparent: {
      true: {
        bgColor: 'transparent',
        boxShadow: 'none',
        border: 'none',
      },
    },
    light: {
      true: {
        bgColor: 'transparent',
        border: 'none',
      },
    },
  },
  'compoundVariants': [
    {
      bordered: 'true',
      css: {
        border: '1px solid $primaryBorder',
        color: '$white',
      },
    },
    {
      transparent: 'true',
      css: {
        border: 'none',
        color: '$white',
      },
    },
    {
      light: 'true',
      css: {
        color: '$white',
      },
    },
  ],
  'defaultVariants': {
    size: 'md',
    color: 'primary',
  },
})

export const Button = StyledButton
