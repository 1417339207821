import React, { useMemo } from 'react'
import { type CSS } from 'styles/stitches.config'
import Style from 'csstype'
import { StyledHelperText, StyledInput, StyledInputContent, StyledInputMainContainer, StyledInputWrapper, StyledLabel } from './Textfield.styles'
import { Box } from 'components/elements'

type Props = {
  placeholder?: string
  size?: 'sm' | 'md' | 'lg'
  color?: 'default' | 'error' | 'black'
  label?: string
  helperText?: string
  error?: boolean
  // labelPlaceholder?: string;
  // labelLeft?: string | React.ReactNode;
  // labelRight?: string | React.ReactNode;
  contentLeft?: React.ReactNode
  contentRight?: React.ReactNode
  disabled?: boolean
  fieldDisabled?: boolean
  contentClickable?: boolean
  contentRightStyling?: boolean
  contentLeftStyling?: boolean
  isTextarea?: boolean
  filePlaceholder?: string
  width?: string
  fullWidth?: boolean
  className?: string
  contentRightStyle?: Style.Properties
  contentLeftStyle?: Style.Properties
  css?: CSS
  as?: string
  rows?: string
  isCustom?: boolean

  onContentClick?: (key: 'left' | 'right', e: React.MouseEvent<HTMLDivElement>) => void
}

type NativeInputProps = Omit<React.ComponentPropsWithoutRef<typeof StyledInput>, keyof Props>

type TextFieldProps = Props & NativeInputProps

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const {
    label,
    color,
    width: widthProp,
    fullWidth,
    disabled,
    fieldDisabled,
    helperText,
    contentLeft,
    contentRight,
    error,
    contentClickable,
    size,
    isTextarea = false,
    filePlaceholder = '',
    contentLeftStyling = true,
    contentRightStyling = true,
    contentRightStyle,
    contentLeftStyle,
    onContentClick,
    css,
    isCustom,
    ...rest
  } = props

  const width = useMemo(() => {
    if (fullWidth) return '100%'
    if (widthProp) return widthProp

    return 'initial'
  }, [widthProp, fullWidth])

  const contentClickHandler = (key: 'left' | 'right', e: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return
    onContentClick && onContentClick(key, e)
  }

  return (
    <StyledInputMainContainer color={error ?(isCustom?'customError': 'error') : color} size={size} css={{ width, ...css }} className="input-main-container">
      {/* TODO: improve labels */}
      {label && (
        <StyledLabel htmlFor={label} css={{ color: isCustom ? '$black39' : '$gray4' }}>
          {label}
        </StyledLabel>
      )}
      <StyledInputWrapper isTextarea={isTextarea} css={{ color: fieldDisabled ? '$gray4' : '$white' }} className="input-wrapper">
        {contentLeft && (
          <StyledInputContent
            applyStyles={contentLeftStyling}
            // className={clsx(`${preClass}-content`, `${preClass}-content--left`)}
            clickable={contentClickable}
            style={contentLeftStyle}
            onClick={e => contentClickHandler('left', e)}
          >
            {contentLeft}
          </StyledInputContent>
        )}
        {filePlaceholder && (
          <Box as="label" className="file-placeholder" style={{ paddingLeft: '1rem', margin: 0, flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {filePlaceholder}
          </Box>
        )}
        <StyledInput ref={ref} hasLeftContent={!!contentLeft} hasRightContent={!!contentRight} isTextarea={isTextarea} disabled={fieldDisabled} {...rest} />
        {contentRight && (
          <StyledInputContent applyStyles={contentRightStyling} clickable={contentClickable} style={contentRightStyle} onClick={e => contentClickHandler('right', e)}>
            {contentRight}
          </StyledInputContent>
        )}
      </StyledInputWrapper>
      {helperText && <StyledHelperText>{helperText}</StyledHelperText>}
    </StyledInputMainContainer>
  )
})

export default TextField
