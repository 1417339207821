import * as React from 'react'
import { SVGProps } from 'react'

const SvgIrReport = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#ir-report_svg__a)">
      <path d="M32.666 7v9.333A2.333 2.333 0 0 0 35 18.667h9.333" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M39.666 49H16.334a4.667 4.667 0 0 1-4.666-4.667V11.667A4.667 4.667 0 0 1 16.332 7h16.334l11.666 11.667v25.666A4.667 4.667 0 0 1 39.667 49Z"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#ir-report_svg__b)">
        <path
          d="M28 28c2-2.96 0-7-1-8 0 3.038-1.773 4.741-3 6-1.226 1.26-2 3.24-2 5a6 6 0 1 0 12 0c0-1.532-1.056-3.94-2-5-1.786 3-2.791 3-4 2Z"
          stroke="#B79313"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="ir-report_svg__a">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
      <clipPath id="ir-report_svg__b">
        <path fill="#fff" transform="translate(16 16)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgIrReport
