export const endpoints = {
  removeSentinelKey: '/user/sentinel',
  userProfile: '/user/profile',
  setPassword: '/user/set/password',
  addComment: '/comments/add',
  getComment: 'comments/',
  addInsuranceRequests: '/insurance/requests/add',
  removeComment: 'comments/remove',
  updateComment: 'comments/update',
  supportRequest: 'support/request/add',
  getPolicies: 'user/insurance/plans/current',
  getFeatures: 'user/insurance/plans/policies',
  cancelPolicies: 'user/insurance/plans/withdraw',
  claimRaised: 'claim/request/raised',
  reEvaluationRequest: 'claim/request/confirmation',
  getClaimRequest: 'claim/request',
  getPreviousPolicyList: 'user/insurance/plans',
  getThreatChainTransaction: 'threats/user/chain/hash',
  plansPayment: 'user/insurance/plans/payment',
  addClaimManually: 'claim/request/manually',
  addScoreCard: 'domain/security/score/add/request',
  connectRequest: 'admin/connection/request',
  getBlog: 'blog/',
  blogEmojiReaction: 'blog/emoji/reaction',
  signupByQuote: 'user/quote/registration',
  servicesListing: 'user/services/list',
  submitRegReq: 'user/registration/request',
  getPlanForQuote: 'insurance/plans/listing/for/quote',
  getUserQuote: 'user/quote/registration',
  addService: 'user/insurance/plans/add/service',
  activateCoveActivation: 'user/activate/cove',
  getCoveThreatLisiting: 'cove/threats/user/listing',
  getCoveClaimRequest: 'cove/claim/request',
  coveReEvaluationRequest: 'cove/claim/request/confirmation',
  claimRaisedCove: 'cove/claim/request/raised',
  changePolicy: 'user/insurance/plans/change',
  activatePatchService: 'user/activate/patch',
  //patch
  getNSightPatchThreatLisiting: 'patch/threats/user/listing',
  getNSightPatchClaimRequest: 'patch/claim/request',
  NSightPatchReEvaluationRequest: 'patch/claim/request/confirmation',
  claimRaisedNSightPatch: 'patch/claim/request/raised',
  deactivateCove: 'user/deactivate/cove',
  deactivateNSight: 'user/deactivate/patch',
  changePaymentMethod: 'user/add/payment/method',
  addUserRegPayment: 'user/registration/payment',
  //base on primary Service
  incidentsApplicableCount: 'user/applicable/count',
  merchantAffiliateDetails: 'merchants/affiliate/details',
  MISP_API: '/misp',
  LossRunRation: 'threats/loss',
}
