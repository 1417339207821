import * as React from 'react'
import { SVGProps } from 'react'

const SvgBackToNormal = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#back-to-normal_svg__a)" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M44.333 20.323 31.89 10.645a6.22 6.22 0 0 0-7.64 0l-12.446 9.678a6.219 6.219 0 0 0-2.4 4.912v16.8a4.666 4.666 0 0 0 4.666 4.667h28a4.667 4.667 0 0 0 4.667-4.667v-16.8a6.22 6.22 0 0 0-2.404-4.912Z"
        stroke="#fff"
      />
      <path d="m24 28-4 4 4 4m-4-4h11a4 4 0 1 0 0-8h-1" stroke="#B79313" />
    </g>
    <defs>
      <clipPath id="back-to-normal_svg__a">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgBackToNormal
