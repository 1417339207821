import { useState, useEffect } from 'react'

const useMediaQuery = (width: number) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [matches, width])

  return matches
}

export default useMediaQuery
