import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/button'
import { Box, Flex, Spinner } from 'components/elements'
import FormWrapper from 'features/auth/components/FormWrapper'
import { ErrorBox } from 'features/profile/components/EditProfileForm'
import { Controller, FieldError, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { FormData, QuoteProps, Service } from '../type'
import { ApiErrorCode, MESSAGE_ENUM, Stages, ValidationErrors } from 'utils/enum'
import { useNavigate } from 'react-router-dom'
import { routes } from 'configs/constants'
import { CustomRadioButton, ErrorCSS, Form, Label, userRegHeadingCss } from 'views/style'
import _ from 'lodash'
import { useGetPlanListingForQuote } from 'features/quote/api/getPlanListingForQuote'
import { useEffect, useState } from 'react'

import { QouteLayout } from 'features/quote/page/GetAQuotes'
import { AxiosError } from 'axios'
import { getUpperCase } from 'utils/function'
import { BackNavArrowIcon } from 'components/icons'

export const step2Schema = Yup.object().shape({
  coverage: Yup.string().required(ValidationErrors.COVERAGE_PLAN),
})
const CoveragePlan = (props: QuoteProps) => {
  const navigate = useNavigate()
  const { data: planList, isLoading, isError, error } = useGetPlanListingForQuote()
  const [submitError, setSubmitError] = useState('')
  const coverageRange = planList?.data.plans
  if (coverageRange) {
    coverageRange.sort((a: { maxCoverage: number }, b: { maxCoverage: number }) => a.maxCoverage - b.maxCoverage)
  }
  const maxCoverageRange: Service[] = coverageRange?.map((item: { maxCoverage: string }) => {
    const coverage = parseFloat(item.maxCoverage).toLocaleString()

    // Format the number with thousands separators
    return {
      label: `$${coverage}`,
      value: `${item.maxCoverage}`,
    }
  })
  // remove the duplicates from maxCoverageRange
  const uniqueMaxCoverageRange = maxCoverageRange?.reduce((acc: Service[], curr: Service) => {
    const existingItem = acc.find((item: Service) => item.value === curr.value)
    if (!existingItem) {
      acc.push(curr)
    }
    return acc
  }, [])

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(step2Schema),
    defaultValues: {
      coverage: (props?.quoteUserReg && props?.quoteUserReg.coverage?.toString()) || '',
    },
  })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (error instanceof AxiosError) {
      if (error.code === ApiErrorCode.CODE) {
        setSubmitError(MESSAGE_ENUM.ERROR_MESSAGE)
      }
    }
  }, [error, isError])

  const onSubmit = async (data: FormData) => {
    if (props?.quoteUserReg?.coverage) {
      if (props?.quoteUserReg?.coverage.toString() !== data.coverage) {
        const obj = {
          ...props.quoteUserReg,
          planId: undefined,
          coverage: data.coverage,
        }
        props.setQuoteUserReg && props.setQuoteUserReg(obj)
      }
    } else {
      const obj = {
        ...props.quoteUserReg,
        coverage: data.coverage,
      }
      props.setQuoteUserReg && props.setQuoteUserReg(obj)
    }
    props?.setStage && props.setStage(Stages.STAGE4)
  }
  const chunkedAs = _.chunk(uniqueMaxCoverageRange, 4)
  return (
    <>
      {isLoading ? (
        <Flex justify="center" align="center" css={{ m: '6rem' }}>
          <Spinner />
        </Flex>
      ) : isError ? (
        submitError && (
          <>
            <Flex align="start" justify="center" css={QouteLayout}>
              <Flex justify="between" css={{ mb: 0 }}>
                <FormWrapper controlWidth={{ maxWidth: 700 }}>
                  <Flex as="h4" justify={'center'} css={{ ...userRegHeadingCss, color: '$error' }}>
                    {getUpperCase(submitError)}
                  </Flex>
                </FormWrapper>
              </Flex>
            </Flex>
          </>
        )
      ) : (
        <>
          <Flex align="start" justify="center" css={QouteLayout}>
            <Flex justify="between" css={{ mb: 0 }}></Flex>
            <FormWrapper controlWidth={{ maxWidth: 700 }}>
              <Flex align="center">
                <BackNavArrowIcon fontSize={24} style={{ cursor: 'pointer' }} onClick={() => props?.setStage && props.setStage(Stages.STAGE2)} />
                <Flex as="h2" justify={'center'} css={userRegHeadingCss}>
                  Get A Quote
                </Flex>
              </Flex>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <Box css={{ textAlign: 'left' }}>
                  <Box css={{ fontSize: '1rem', mb: '1rem' }}>Please select Ransomware Coverage</Box>
                  <Flex direction={'row'} wrap={'wrap'} justify={'between'} css={{ maxWidth: '500px', width: '100%', columnGap: '20px' }}>
                    {chunkedAs?.map((row, rowIndex) => (
                      <Box key={rowIndex} css={{ flex: 1 }}>
                        {row.map((service: Service, index) => (
                          <Box key={index}>
                            <Label key={service.value}>
                              <Controller
                                name="coverage"
                                control={control}
                                render={({ field }) => (
                                  <CustomRadioButton
                                    type="radio"
                                    value={service.value}
                                    checked={field.value === service.value}
                                    onChange={e => {
                                      field.onChange(e.target.value)
                                    }}
                                    data-state={field.value === service.value ? 'checked' : 'unchecked'}
                                  />
                                )}
                              />
                              <Box> {service.label}</Box>
                            </Label>
                          </Box>
                        ))}
                      </Box>
                    ))}
                  </Flex>

                  {!!errors.coverage && <ErrorBox css={{ ...ErrorCSS, textAlign: 'left' }}>{(errors.coverage as FieldError).message}</ErrorBox>}
                </Box>

                <Box css={buttonCss}>
                  <Button bordered={true} type="button" onClick={() => navigate(routes.login)}>
                    Cancel
                  </Button>
                  <Button type="submit">Next</Button>
                </Box>
              </Form>
            </FormWrapper>
          </Flex>
        </>
      )}
    </>
  )
}

export const buttonCss = {
  'display': 'grid',
  'gridTemplateColumns': '1fr',
  'gap': 28,
  '@sm': {
    gridTemplateColumns: '1fr 1fr',
  },
}
export default CoveragePlan
