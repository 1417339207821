import { AxiosError } from 'axios'
import { Box, Flex } from 'components/elements'
import FormWrapper from 'features/auth/components/FormWrapper'
import { DetailContainer, InfoColumn, MainWrapCSS } from 'features/incidents/components/style'
import { ErrorBox } from 'features/profile/components/EditProfileForm'
import { useSignupByQuote } from 'features/quote/api/signupByQuote'
import { BoxCss, Notification, textCss } from 'features/quote/components/GetAQuoteProcess/QuoteReview'
import { PaymentProps } from 'features/quote/components/type'
import { useState, useEffect, useMemo } from 'react'
import { TotalDiscountApplied, errorMessage, getUpperCase } from 'utils/function'
import StripePayment from './StripePayment'
import { calculateDiscount } from 'features/quote/components/GetAQuoteProcess/ServiceFormBase'

export const PaymentQuoteForm = (props: PaymentProps) => {
  const [submitError, setSubmitError] = useState('')
  const [hasServices, setHasServices] = useState('')
  const [hasDiscount, setHadDiscount] = useState(false)
  const services = useMemo(() => {
    return (props?.quoteUserReg && props?.quoteUserReg.service) || []
  }, [props?.quoteUserReg])
  const secondaryServices = services.filter(item => item !== props?.quoteUserReg?.primaryService?.id)
  const totalDiscount = (props?.quoteUserReg && props?.quoteUserReg.discount) || 0
  const discountApplied = (props?.quoteReview?.insurancePlan?.premiumAmount ?? 0) * (calculateDiscount(secondaryServices, totalDiscount) / 100)
  // nullish  operator to provide a default value of 0
  const discountedAmount = (props?.quoteReview?.insurancePlan?.premiumAmount ?? 0) - discountApplied

  const { mutate, isLoading } = useSignupByQuote()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const doc: Document = document
  const body = doc.querySelector('body')
  useEffect(() => {
    body?.classList.remove('overflow--hidden--modal')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    //settting the discount on the base on services
    TotalDiscountApplied({
      services: secondaryServices || [],
      totalDiscount: totalDiscount,
      setHasServices: setHasServices,
      setHadDiscount: setHadDiscount,
    })
  }, [props.quoteUserReg, secondaryServices, totalDiscount])
  const handleStripeSubmit = (paymentMethodId: string) => {
    const services = props?.quoteUserReg && props?.quoteUserReg?.service
    const newObj = {
      ...props.quoteUserReg,
      ...props.quoteUserReg?.users,
      services,
      paymentMethodId,
      isAutoSubscriptionEnabled: true,
    }
    delete newObj.service
    delete newObj.users
    delete newObj.status
    mutate(newObj, {
      onSuccess: data => {
        window.location.href = data?.data.url
      },
      onError: error => {
        if (error instanceof AxiosError) {
          setSubmitError(errorMessage(error))
        }
      },
    })
  }

  return (
    <Flex align="start" justify="center" css={{ mt: '$10', mb: '$10' }}>
      <FormWrapper title="Stripe Payment" style={{ textAlign: 'start' }} controlWidth={{ maxWidth: 600 }}>
        <Flex justify={'between'} wrap={'wrap'} css={{ mb: '$3', rowGap: '1rem' }}>
          <Box as="h4" css={{ textAlign: 'start', m: 0 }}>
            Payment Information
          </Box>
          {hasDiscount && (
            <Notification>
              <Box as="span" css={{ textAlign: 'center' }}>
                You saved {hasServices}
              </Box>
            </Notification>
          )}
        </Flex>
        {props?.quoteReview?.insurancePlan && (
          <Box
            className="detail-box"
            style={{ padding: 0 }}
            css={{
              ...MainWrapCSS,
              background: '$cardGradient',
              borderRadius: '8px',
              mb: '$5',
            }}
          >
            <DetailContainer className="custom--grid">
              <InfoColumn css={BoxCss}>
                <Box as="p" className="t-title m-0">
                  Original Premium{' '}
                </Box>
                <Box as="p" className={`t-value `} css={textCss}>
                  ${props?.quoteReview?.insurancePlan?.premiumAmount}
                </Box>
              </InfoColumn>
              <InfoColumn css={BoxCss}>
                <Box as="p" className="t-title m-0" css={{ wordBreak: 'auto-phrase !important' }}>
                  Discounted Premium
                </Box>
                <Box as="p" className={`t-value `} css={{ ...textCss, color: '$primary', fontSize: '$xl' }}>
                  ${discountedAmount}
                </Box>
              </InfoColumn>
            </DetailContainer>
          </Box>
        )}
        <Box as="h4" css={{ textAlign: 'start' }}>
          Card Information
        </Box>
        <Flex direction="column" css={{ gap: '$5' }}>
          {!!submitError && <ErrorBox css={{ textAlign: 'left', mb: '$3' }}>{getUpperCase(submitError)}</ErrorBox>}
          <StripePayment onSubmit={handleStripeSubmit} isLoading={isLoading} />
        </Flex>
      </FormWrapper>
    </Flex>
  )
}
