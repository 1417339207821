import { styled } from 'styles'

export const ModalBlock = styled('div', {
  'display': 'flex',
  'justifyContent': 'center',
  'alignItems': 'flex-end',
  'overflow': 'hidden',
  'position': 'fixed',
  'bottom': 0,
  'left': 0,
  'right': 0,
  'top': 0,
  'opacity': 1,
  'zIndex': 400,

  '@sm': {
    alignItems: 'center',
  },
})

export const ModalOverlay = styled('div', {
  bottom: 0,
  cursor: 'default',
  display: 'block',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  backgroundColor: 'rgb(0,0,0,.4)',
})

export const ModalClose = styled('a', {
  float: 'right !important',
  textDecoration: 'none !important',
  cursor: 'pointer',
  fontSize: '1rem',
})

export const ModalContainer = styled('div', {
  'bg': '$cardGradient',
  'backdropFilter': 'blur(50px)',
  'borderRadius': '.75rem .75rem 0 0',
  'display': 'flex',
  'flexDirection': 'column',
  'maxHeight': '75vh',
  'maxWidth': '550px',
  'padding': '2rem',
  'width': '100%',
  'animation': 'slide-down 0.2s ease 1',
  'zIndex': 1,
  'boxShadow': '0px 4px 24px -1px $black33',
  'overflowY': 'auto',

  '@sm': {
    borderRadius: '$base',
  },
})

export const ModalBody = styled('div', {
  position: 'relative',
})

export const ModalHeader = styled('div', {
  justifyContent: 'space-between',
  color: '$gray9',
  margin: 'auto',
  paddingBottom: '1rem',
})

export const ModalTitle = styled('h2', {
  textTransform: 'capitalize',
  textAlign: 'center',
  color: '$white',
})

export const ModalDescription = styled('h4', {
  textAlign: 'center',
  color: '$white',
  margin: 0,
})

export const ModalFooter = styled('div', {
  display: 'flex',
  padding: '10px 0px',
})

export const Button = styled('button', {
  background: '$purple',
  color: '$white',
  fontSize: '1em',
  margin: '10px',
  padding: '5px 10px',
  border: '2px solid $purple',
  borderRadius: '3px',
  cursor: 'pointer',
})
