import { styled } from 'styles/stitches.config'

export const StyledInputMainContainer = styled('div', {
  $$inputBorderWeight: '1px',
  $$inputBorderRadius: '4px',
  $$inputColor: '$colors$white1A',
  $$inputTextColor: '$colors$text',
  $$inputPlaceholderColor: '$colors$gray4',

  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  WebkitBoxAlign: 'center',

  variants: {
    color: {
      default: {
        $$inputBorderColor: '$colors$border',
        $$inputLabelColor: '$colors$text',
        $$inputHelperColor: '$colors$text',
      },
      black: {
        $$inputBorderColor: '$colors$gray13',
        $$inputLabelColor: '$colors$black39',
        $$inputHelperColor: '$colors$black39',
        $$inputColor: '$colors$white77',
        $$inputTextColor: '$colors$black39',
      },
      error: {
        $$inputBorderColor: '$colors$error',
        $$inputLabelColor: '$colors$error',
        $$inputHelperColor: '$colors$error',
      },
      customError: {
        $$inputBorderColor: '$colors$error',
        $$inputLabelColor: '$colors$error',
        $$inputHelperColor: '$colors$error',
        $$inputColor: '$colors$white77',
        $$inputTextColor: '$colors$black39',
      },
    },
    size: {
      sm: {},
      md: {
        $$inputBorderRadius: '4px',
        $$inputBorderWeight: '1px',
        $$inputFontSize: '$fontSizes$base',
        $$inputHeightRatio: '2',
        borderRadius: '$$inputBorderRadius',
      },
      lg: {},
    },
  },
  defaultVariants: {
    color: 'default',
    size: 'md',
  },
})

export const StyledInputContainer = styled('div', {})

export const StyledInputWrapper = styled('div', {
  // flex: 1,
  position: 'relative',
  bgColor: '$$inputColor',
  display: 'inline-flex',
  color: '$white',
  verticalAlign: 'middle',
  borderRadius: '$$inputBorderRadius',
  border: '$$inputBorderWeight solid $$inputBorderColor',
  boxShadow: 'none',
  transition: 'box-shadow 0.25s ease',
  alignItems: 'center',
  userSelect: 'none',
  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
    isTextarea: {
      true: {
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
      },
      false: {
        display: 'inline-flex',
        alignItems: 'center',
        height: 'calc($$inputHeightRatio * 25px)',
      },
    },
  },
})

export const UploadFile = styled('div', {
  'input': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },

  '&.hideInput': {
    '.file-placeholder': {
      display: 'none',
    },

    '.input-wrapper': {
      '& > div:first-child': {
        justifyContent: 'flex-start',
        flexGrow: 1,
        overflow: 'hidden',
      },
    },
  },
})

export const StyledInput = styled('input', {
  'padding': '.75rem 1rem',
  'appearance': 'none',
  'fontSize': '$$inputFontSize',
  'lineHeight': 1.5,
  'bgColor': 'transparent',
  'color': '$$inputTextColor',
  'border': 'none',
  'borderRadius': 0,
  'borderWidth': '0',
  'outline': 'none',
  'size': '100%',
  'minWidth': 0,
  'WebkitAppearance': 'none',
  '&:-internal-autofill-selected': {
    '-webkit-text-fill-color': '$$inputTextColor !important',
    '-webkit-box-shadow': '0 0 0 1000px $$inputColor inset !important',
  },

  '&::placeholder': {
    color: '$$inputPlaceholderColor',
  },

  '&:focus': {
    boxShadow: 'inset 0px 0px 0px 1px $colors$blue8',
  },

  '&:disabled': {
    'pointerEvents': 'none',
    'color': '$slate8',
    'cursor': 'not-allowed',
    '&::placeholder': {
      color: '$slate7',
    },
  },

  '&:read-only': {
    'backgroundColor': '$slate2',
    '&:focus': {
      boxShadow: 'inset 0px 0px 0px 1px $colors$slate7',
    },
  },

  'variants': {
    hasLeftContent: {
      true: {
        ml: 0,
      },
    },
    hasRightContent: {
      true: {
        mr: 0,
      },
    },
    isTextarea: {
      true: {
        boxShadow: 'none',
        display: 'block',
        size: '100%',
        resize: 'none',
        border: 'none',
        outline: 'none',
      },
    },
  },
})

export default StyledInput

export const StyledInputContent = styled('div', {
  variants: {
    applyStyles: {
      true: {
        display: 'flex',
        boxSizing: 'content-box',
        width: 'calc($$inputHeightRatio * $space$4)',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        padding: '0 calc($$inputHeightRatio * $3)',
        color: '$$inputTextColor',
        lineHeight: '$xs',
        position: 'relative',
        cursor: 'default',
        // pointerEvents: "none",
      },
    },
    clickable: {
      true: {
        cursor: 'pointer',
        pe: 'auto',
      },
    },
  },
})

export const StyledLabel = styled('label', {
  display: 'block',
  color: '$gray4',
  fontSize: '$base',
  marginBottom: '$2',
})

export const StyledHelperText = styled('p', {
  textAlign: 'right',
  fontSize: '$sm',
  color: '$$inputHelperColor',
  lineHeight: 1.5,
  margin: 0,
})

export const StyledDropdownWrap = styled('div', {
  'position': 'relative',

  '&.comment': {
    height: 'auto',
    marginRight: '20px',
  },
  '& > .phoneNumber': {
    '& > .phone_label': {
      color: '$gray4',
      display: 'block',
      fontSize: '$base',
      marginBottom: '8px',
    },
    'input': {
      'fontSize': '$base',
      'lineHeight': 1.5,
      'padding': '.75rem 1rem',
      'background': '$white1A',
      'color': 'white',
      'border': '1px solid $white',
      'borderRadius': '4px',
      '&.error': {
        border: '1px solid $red',
      },
      '&:focus ': {
        border: '1px solid $white',
        outline: 'none',
      },
      '& > .error': {
        border: '2px solid $red',
      },
    },
  },

  '& > .phoneNumberError': {
    input: {
      'borderColor': '$red',
      '&:focus ': {
        borderColor: '$border',
      },
    },
  },
  '& > .phoneNumberCustomError': {
    input: {
      'borderColor': '$red',
      'color': '$black39',
      'background': '$white77 ',
      '&:focus ': {
        borderColor: '$border',
      },
    },
  },
  '& > .phoneNumbercustom-carrier': {
    input: {
      'border': '1px solid $gray13',
      'color': '$black39',
      'background': '$white77 ',

      '&:focus ': {
        borderColor: '$border',
      },
    },
  },

  '& > .main': {
    'width': '100%',
    'color': '$$inputTextColor !important',
    'outline': 'none',
    'bgColor': '$white1A',
    'cursor': 'pointer',
    'border': '1px solid $border',
    'borderRadius': '4px',
    'display': 'flex',
    'justifyContent': 'space-between',
    'outlineWidth': 0,
    'textTransform': 'none',
    'padding': '0.75rem 1rem',
    'fontSize': '$base !important',
    'lineHeight': 1.5,

    '.MuiInputAdornment-root': {
      height: 'auto',
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '.MuiInputBase-root': {
      'padding': 0,

      '.MuiSelect-select': {
        color: '$white',
      },
    },
    'textarea': {
      color: '$white',
    },
    '&:focus-visible': {
      outline: 'unset',
      borderColor: 'unset',
    },

    'input': {
      color: '$white',
      border: 'none',
      padding: 0,
    },
  },
  '& > .errorText': {
    position: 'absolute',
    top: '100%',
    right: 0,
    margin: 0,
    textAlign: 'end',
    color: '$error',
    fontSize: '0.875rem',
  },
  '& > .error': {
    borderColor: '$colors$error',
  },
  '& > .menuItem': {
    width: '188px',
  },
})

export const StyledEditorWrap = styled('div', {
  'border': '1px solid $border',
  'borderRadius': '4px',

  '& > .toolbar': {
    '--w-e-toolbar-color': '$white',
    '--w-e-toolbar-bg-color': '$white1A',
    '--w-e-toolbar-active-color': '$white1A',
    '--w-e-toolbar-active-bg-color': '$white1A',
    '--w-e-toolbar-disabled-color': '$white1A',
    '--w-e-toolbar-border-color': '$white',
    '--w-e-modal-button-bg-color': '$blue1',
    '--w-e-modal-button-border-color': '$gray11',
  },

  '& > .editor': {
    '--w-e-textarea-bg-color': '$white1A',
    '--w-e-textarea-color': '$white',
    '--w-e-textarea-border-color': '$gray5',
    '--w-e-textarea-slight-border-color': '$gray5',
    '--w-e-textarea-slight-color': '$gray11',
    '--w-e-textarea-slight-bg-color': '$white1A',
    '--w-e-textarea-selected-border-color': '$blue1',
    '--w-e-textarea-handler-bg-color': '$blue1',
  },
})
