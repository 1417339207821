import { Box, Flex } from 'components/elements'
import { DetailContainer, DetailWrapper, DetailedCard, InfoColumn, MainWrapCSS } from 'features/incidents/components/style'
import { styled } from '@stitches/react'
import { PlanReviewSummaryProps } from '../type'
import { useEffect, useState, useMemo } from 'react'
import { routes, serviceArrayAssessment, serviceLableAssessments } from 'configs/constants'
import FormWrapper from 'features/auth/components/FormWrapper'
import { CheckBoxInput, Form, Label, userRegHeadingCss } from 'views/style'
import { Button } from 'components/button/Button'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Modal } from 'components/modal'
import { ModalForm } from 'features/profile/components/EditProfileForm'
import { BorderCss } from './PlanSelection'
import { BackNavArrowIcon } from 'components/icons'
import { Stages } from 'utils/enum'
import { TotalDiscountApplied } from 'utils/function'
import { calculateDiscount } from './ServiceFormBase'
import { QouteLayout } from 'features/quote/page/GetAQuotes'
import { buttonCss } from './CoveragePlan'

const QuoteReview = (prop: PlanReviewSummaryProps) => {
  const [hasServices, setHasServices] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [hasDiscount, setHadDiscount] = useState(false)
  const services = useMemo(() => {
    return (prop?.quoteUserReg && prop.quoteUserReg.service) || []
  }, [prop?.quoteUserReg])

  const secondaryServices = services.filter(item => item !== prop?.quoteUserReg?.primaryService?.id)
  const navigate = useNavigate()
  const totalDiscount = (prop?.quoteUserReg && prop?.quoteUserReg.discount) || 0
  // nullish  operator to provide a default value of 0
  const discountAmount =
    (prop?.quoteReview?.insurancePlan?.premiumAmount ?? 0) - (prop?.quoteReview?.insurancePlan?.premiumAmount ?? 0) * (calculateDiscount(secondaryServices, totalDiscount) / 100)

  useEffect(() => {
    //settting the discount on the base on services
    TotalDiscountApplied({
      services: secondaryServices || [],
      totalDiscount: totalDiscount,
      setHasServices: setHasServices,
      setHadDiscount: setHadDiscount,
    })
  }, [prop.quoteUserReg, secondaryServices, services, totalDiscount])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { handleSubmit, control, setValue } = useForm()
  const onSubmit = () => {
    if (openModal) {
      prop?.setStage && prop.setStage(Stages.STAGE7)
    } else setOpenModal(true)
  }
  const handleAccept = () => {
    setValue('terms', true)
    setOpenModal(false)
    prop?.setStage && prop.setStage(Stages.STAGE7)
  }
  return (
    <>
      <Flex align="start" justify="center" css={{ ...QouteLayout, mt: '$12', mb: '$12' }}>
        <Flex justify="between" css={{ mb: 0 }}></Flex>
        <FormWrapper controlWidth={{ maxWidth: 700 }}>
          <Flex align="center" css={{ alignItems: 'center' }}>
            <BackNavArrowIcon fontSize={24} style={{ cursor: 'pointer' }} onClick={() => prop?.setStage && prop.setStage(Stages.STAGE4)} />

            <Flex as="h2" justify={'center'} css={userRegHeadingCss}>
              Quote Review
            </Flex>
          </Flex>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box css={{ textAlign: 'left' }}>
              <Box as="p" css={{ fontSize: '1rem', fontWeight: '$normal', color: '$gray4' }}>
                Based on your selection, following are your policy details
              </Box>
              {prop?.quoteReview?.insurancePlan && (
                <Box className="detail-box" style={{ padding: 0 }} css={{ ...MainWrapCSS, background: '$cardGradient', borderRadius: '8px' }}>
                  <DetailedCard css={{ maxWidth: 'none', marginBottom: 0 }}>
                    <Flex justify="start" direction="column">
                      <DetailWrapper css={{ border: 'none', p: 0 }}>
                        <Flex justify={'between'} wrap={'wrap'} css={{ mb: '$3' }}>
                          <Box as="h4" css={{ textAlign: 'start', m: 0 }}>
                            Policy details
                          </Box>
                          {hasDiscount && (
                            <Notification>
                              <Box as="span" css={{ textAlign: 'center' }}>
                                You saved {hasServices}
                              </Box>
                            </Notification>
                          )}
                        </Flex>
                        <DetailContainer css={BorderCss}>
                          <InfoColumn css={BoxCss}>
                            <Box as="p" className="t-title  m-0 t-box">
                              Policy
                            </Box>
                            <Box as="p" className={`t-value `} css={textCss}>
                              {prop?.quoteReview?.insurancePlan?.label}
                            </Box>
                          </InfoColumn>
                          <InfoColumn css={BoxCss}>
                            <Box as="p" className="t-title  m-0">
                              Coverage
                            </Box>
                            <Box as="p" className={`t-value `} css={textCss}>
                              ${prop?.quoteReview?.insurancePlan?.maxCoverage}
                            </Box>
                          </InfoColumn>
                          <InfoColumn css={BoxCss}>
                            <Box as="p" className="t-title  m-0">
                              Original Premium{' '}
                            </Box>
                            <Box as="p" className={`t-value `} css={textCss}>
                              ${prop?.quoteReview?.insurancePlan?.premiumAmount}
                            </Box>
                          </InfoColumn>
                          <InfoColumn css={BoxCss}>
                            <Box as="p" className="t-title  m-0">
                              Discounted Premium
                            </Box>
                            <Box as="p" className={`t-value `} css={{ ...textCss, color: '$primary', fontSize: '$xl' }}>
                              ${discountAmount}
                            </Box>
                          </InfoColumn>
                        </DetailContainer>
                      </DetailWrapper>
                    </Flex>
                    <Flex justify={'start'} direction={'column'} css={{ mt: '$5' }}>
                      <Box as="h3" css={{ textAlign: 'start', fontSize: '1rem', fontWeight: '$normal', color: '$white', margin: 0 }}>
                        Monitoring services
                      </Box>
                      <Box css={{ textAlign: 'start' }}>
                        <Flex direction={'column'} wrap={'wrap'}>
                          {services?.map((item, idx) => {
                            const serviceName = serviceArrayAssessment[item - 1]
                            return (
                              <Flex direction={'row'} key={idx} wrap={'wrap'} css={{ width: '100%', padding: '.5rem' }}>
                                <Box as="span" css={{ fontWeight: '$normal', flexBasis: '40%', color: '$gray5' }}>
                                  {serviceLableAssessments[serviceName]}
                                </Box>
                                <Box as="span" css={{ flexBasis: '50%' }}>
                                  {serviceName}
                                </Box>
                              </Flex>
                            )
                          })}
                        </Flex>
                      </Box>
                    </Flex>
                  </DetailedCard>
                </Box>
              )}
            </Box>
            <Flex>
              <Box>
                <Label>
                  <Controller
                    name="terms"
                    control={control}
                    render={({ field }) => (
                      <CheckBoxInput
                        type="checkbox"
                        css={{ width: '34px !important', height: '17px !important', borderRadius: '50% !important' }}
                        data-state={field.value ? 'checked' : 'unchecked'}
                        onChange={
                          // e => field.onChange(e.target.value)
                          () => setOpenModal(true)
                        }
                      />
                    )}
                  />

                  <Box as="p" css={{ textAlign: 'left' }}>
                    By checking the box and pressing [“Agree”], I acknowledge&nbsp;
                    <Box as="a" href="/privacy-policy.html" target="_blank" rel="noopener noreferrer" css={{ fontWeight: '$bold', textDecoration: 'underline' }}>
                      DLT Alert’s PRIVACY POLICY
                    </Box>{' '}
                    and agree to&nbsp;
                    <Box as="a" href="/terms-of-service.html" target="_blank" rel="noopener noreferrer" css={{ fontWeight: '$bold', textDecoration: 'underline' }}>
                      DLT Alert’s TERMS OF SERVICE
                    </Box>{' '}
                    including the arbitration provision linked&nbsp;
                    <Box as="a" href="/terms-of-service.html" target="_blank" rel="noopener noreferrer" css={{ fontWeight: '$bold', textDecoration: 'underline' }}>
                      here
                    </Box>{' '}
                  </Box>
                </Label>
              </Box>
            </Flex>
            <Box css={buttonCss}>
              <Button bordered={true} type="button" onClick={() => navigate(routes.login)}>
                Cancel
              </Button>
              <Button type="submit">Proceed To Payment</Button>
            </Box>
          </Form>
        </FormWrapper>
        <Modal
          active={openModal}
          hideModal={() => setOpenModal(false)}
          onAccept={handleAccept}
          title=""
          description={''}
          style={{ maxWidth: '800px' }}
          children={
            <>
              <ModalForm onSubmit={handleAccept} css={{ gap: 10 }}>
                <Flex direction={'column'} wrap={'wrap'}>
                  <Box as="h2" css={{ fontWeight: '$semibold', color: '$primary' }}>
                    Terms and Conditions
                  </Box>
                  <Box as="h4">Your Agreement</Box>
                </Flex>
                <Flex direction={'row'} wrap={'wrap'}>
                  <Box as="p">Last Revised: December 16, 2022</Box>
                  <Box as="p">
                    Welcome to DLT Alert. This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions
                    contain legal obligations, please read them carefully.
                  </Box>
                  <Box as="h3">1. YOUR AGREEMENT</Box>
                  <Box as="p">
                    By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not
                    use this site. PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless
                    otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site
                    following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these
                    standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.
                  </Box>
                  <Box as="h3">2. PRIVACY</Box>
                  <Box as="p">Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.</Box>
                </Flex>
              </ModalForm>
            </>
          }
          isForm={true}
          acceptText={'Accept'}
        />
      </Flex>
    </>
  )
}
export const Notification = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  height: 'fit-content',
  background: '$primary',
  borderRadius: '90px',
  padding: ' 7px 15px',
})
export const BoxCss = {
  marginBottom: 0,
  maxWidth: '200px',
  width: '100%',
  padding: '1rem 1.5rem',
}
export const textCss = {
  fontSize: '$base',
  fontWeight: '$semibold',
  wordBreak: 'break-word !important',
}
export default QuoteReview
