import { styled } from 'styles'

export const IconButton = styled('button', {
  'appearance': 'none',
  'display': 'inline-flex',
  'alignItems': 'center',
  'flexShrink': 0,
  'justifyContent': 'center',
  'lineHeight': '1',
  'outline': 'none',
  'padding': '$2',
  'borderWidth': '0',
  'textDecoration': 'none',
  'userSelect': 'none',
  'cursor': 'pointer',
  'WebkitTapHighlightColor': 'transparent',
  'color': '$text',
  'backgroundColor': 'transparent',
  '&:hover': {
    backgroundColor: '$white1A',
  },
  '&:focus': {
    boxShadow: 'inset 0 0 0 1px $colors$slateA8, 0 0 0 1px $colors$slateA8',
  },
  '&:active': {
    backgroundColor: '$slateA4',
  },
  '&:disabled': {
    pointerEvents: 'none',
    backgroundColor: 'transparent',
    color: '$slate6',
  },
  '&.text-icon': {
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    padding: '.5rem 1.15rem',
    borderRadius: 6,
  },
  '&.text-back': {
    'textTransform': 'capitalize',
    'p': 0,

    '&:hover': {
      background: 'transparent',
      color: '$primary',
    },
  },
})
