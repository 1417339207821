import { MainLayout } from 'components/Layout'
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom'
import { globalStyles } from 'styles'
import { routes } from 'configs/constants'
import { lazy, Suspense } from 'react'
import Home from 'views/Home'
import { Flex } from 'components/elements/Flex'
import { SidebarLayout } from 'components/sidebarLayout'
import useAuth from 'hooks/use-auth'
import Header from 'components/Header'
import { Box, Spinner } from 'components/elements'
import { InsuranceForm } from 'features/profile/components/InsuranceForm'
import { QouteLayout } from 'features/quote/page/GetAQuotes'

const SuspenseLoader = () => {
  return (
    <Box
      css={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner />
    </Box>
  )
}

const Suspended = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

// misc
const About = Suspended(lazy(() => import('views/About')))
const Security = Suspended(lazy(() => import('views/Security')))
const Support = Suspended(lazy(() => import('views/Support')))
const Warranty = Suspended(lazy(() => import('views/Warranty')))
const Blog = Suspended(lazy(() => import('views/Blog')))
const BlogCatagory = Suspended(lazy(() => import('views/Blog/BlogCatagory')))
const BlogDetail = Suspended(lazy(() => import('views/Blog/BlogDetail')))
const Quote = Suspended(lazy(() => import('views/Quote/index')))
const QuoteSupportForm = Suspended(lazy(() => import('features/quote/components/QuoteSupportForm/QuoteSupportForm')))
const QuoteSentinelOne = Suspended(lazy(() => import('features/quote/components/QuoteSupportForm/SentinelOneQuoteSupport')))
const AddUserStripePayment = Suspended(lazy(() => import('views/Stripe/AddUserStripe')))

// auth
const Login = Suspended(lazy(() => import('features/auth/pages/Login')))
const Register = Suspended(lazy(() => import('features/auth/pages/Register')))
const VerfiyEmail = Suspended(lazy(() => import('features/auth/pages/VerifyEmail')))
const ForgotPassword = Suspended(lazy(() => import('features/auth/pages/ForgotPassword')))
const ResetPassword = Suspended(lazy(() => import('features/auth/pages/ResetPassword')))
const SetPassword = Suspended(lazy(() => import('features/auth/pages/SetPassword')))
// incidents
const Incidents = Suspended(lazy(() => import('features/incidents/pages/Incidents')))
//Policy
const Insurance = Suspended(lazy(() => import('features/insurance/pages/Insurance')))
// profile
const SentinelOne = Suspended(lazy(() => import('features/profile/pages/SentinelOne')))
const EditProfile = Suspended(lazy(() => import('features/profile/pages/EditProfile')))
const UserAssessment = Suspended(lazy(() => import('features/profile/pages/UserAssessments')))
const ChangePassword = Suspended(lazy(() => import('features/profile/pages/ChangePassword')))
// const VerifyRequest = Suspended(
//   lazy(() => import("features/auth/components/VerifyRequest"))
// );

const AcountVerification = Suspended(lazy(() => import('features/auth/pages/AcountVerification')))

const ResendVerificationLink = Suspended(lazy(() => import('features/auth/pages/ResendVerificationLink')))

// TODO: move this to a layouts folder
const AuthLayout = () => {
  return (
    <>
      <Header />
      <Flex align="start" justify="center" css={QouteLayout}>
        <Outlet />
      </Flex>
    </>
  )
}

const Protected = ({ children }: { children: JSX.Element }) => {
  const { user, authStatus, error } = useAuth()
  const { pathname } = useLocation()

  if (authStatus === 'loading') return <div>Loading...</div>

  if (authStatus === 'error') {
    // eslint-disable-next-line no-console
    console.log(error)
    return <div>Error loading</div>
  }

  if (!user) {
    return <Navigate to={routes.login} state={{ from: pathname }} />
  }
  return children
}

function App() {
  globalStyles()
  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path={routes.security} element={<Security />} />
          <Route path={routes.about} element={<About />} />
          <Route path={routes.support} element={<Support />} />
          <Route path={routes.warranty} element={<Warranty />} />
          <Route path={routes.blog} element={<Blog />} />
          <Route path={routes.blogDetail} element={<BlogDetail />} />
          <Route path={routes.blogCatagory} element={<BlogCatagory />} />
          <Route path={routes.qoute} element={<Quote />} />
          <Route path={routes.quoteSupportForm} element={<QuoteSupportForm />} />
          <Route path={routes.quoteSentinelOne} element={<QuoteSentinelOne />} />
          <Route path={routes.stripPayment} element={<AddUserStripePayment />} />
        </Route>

        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="set-password" element={<SetPassword />} />
          <Route path="verify-email" element={<VerfiyEmail />} />
          <Route path="account-verification" element={<AcountVerification />} />
          <Route path="resend-verification-link" element={<ResendVerificationLink />} />
        </Route>

        <Route
          path="/app"
          element={
            <Protected>
              <SidebarLayout />
            </Protected>
          }
        >
          <Route path="incidents" element={<Incidents />} />
          <Route path="/app/insurance" element={<Insurance />} />
          <Route path="profile/edit" element={<EditProfile />} />
          <Route path="profile/assessments" element={<UserAssessment />} />
          <Route path="profile/change" element={<ChangePassword />} />
        </Route>

        <Route
          path="/misc"
          element={
            <Protected>
              <AuthLayout />
            </Protected>
          }
        >
          <Route path="sentinel-one" element={<SentinelOne />} />
        </Route>

        <Route path="Insurance-form" element={<InsuranceForm />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  )
}

export default App
