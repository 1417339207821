import * as React from 'react'
import { SVGProps } from 'react'

const SvgRansomware = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#ransomware_svg__a)" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M46.667 9.334H9.333A2.333 2.333 0 0 0 7 11.667V35a2.333 2.333 0 0 0 2.333 2.334h37.334A2.333 2.333 0 0 0 49 35V11.667a2.333 2.333 0 0 0-2.333-2.333Z"
        stroke="#fff"
      />
      <path d="M33 22H23a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2Z" stroke="#B79313" />
      <path d="M28 28a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" stroke="#fff" />
      <path d="M24 22v-4a4 4 0 1 1 8 0v4" stroke="#B79313" />
      <path d="M16.334 46.666h23.333M21 37.334v9.333M35 37.334v9.333" stroke="#fff" />
    </g>
    <defs>
      <clipPath id="ransomware_svg__a">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgRansomware
