import { Box } from 'components/elements/Box'
import { BgCircularPattern } from 'components/elements/BgPattern'
import { BoxTextCenter, CardTextBox, Section } from 'views/style'
import { Grid } from '@mui/material'

const Pitch = () => {
  return (
    <Section css={{ position: 'relative' }}>
      <BgCircularPattern css={{ left: '-3%', top: '24%' }} />
      <BgCircularPattern css={{ right: '-5%', top: '-20%' }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={BoxTextCenter}>
          <Box css={CardTextBox}>
            <Box as="h2" css={{ '@md': { fontSize: '2rem' } }}>
              How we're better
            </Box>
            <Box as="p" css={{ 'color': '$gray4', 'mb': '3rem', '@md': { fontSize: '$xl', lineHeight: 1.5 } }}>
              By embedding directly into leading cyber services and the fastest blockchains, DLT Alert instantly validates incidents and makes financial payout to clients when they
              need it most.
            </Box>
            {/* <Button css={{ width: 180, height: 55 }} bordered>
              <Box as="span" css={{ mr: "$1" }}>
                Read More
              </Box>
              <ReadMoreIcon fontSize={42} />
            </Button> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <img src="/images/secure-pc.svg" alt="a pc with secure lock" />
          </Box>
        </Grid>
      </Grid>
    </Section>
  )
}

export default Pitch
