import { Flex } from 'components/elements/Flex'
import { Box } from 'components/elements/Box'
import { Card, CardBorder } from 'components/elements/Card'
import { styled } from 'styles'
import { IntegrateIcon, ValidateIcon, RemediateIcon } from 'components/icons'
import { Section } from 'views/style'

const converageSteps = [
  {
    id: 1,
    icon: <IntegrateIcon />,
    title: 'Integrate',
    description: 'Embedded coverage means your warranty and service are one unified solution.',
  },
  {
    id: 2,
    icon: <ValidateIcon />,
    title: 'Validate',
    description: 'AI powered claims approvals provide immediate payouts.',
  },
  {
    id: 3,
    icon: <RemediateIcon />,
    title: 'Remediate',
    description: 'Tamper proof incident and coverage summary enables IR teams to recover faster.',
  },
]

const CoverageSteps = () => {
  return (
    <Section css={{ position: 'relative' }}>
      <h2 className="section-title">Cyber Coverage in 3 Setps</h2>
      <StyledWrapper css={{ position: 'relative' }}>
        {converageSteps.map(step => (
          <Flex key={step.id} direction="column" css={{ gap: '$$badgeGap', maxWidth: 405 }}>
            <Box
              css={{
                padding: 2,
                bg: '$borderGradient',
                borderRadius: '$rounded',
                alignSelf: 'center',
              }}
            >
              <StyledStepBadge>{step.id}</StyledStepBadge>
            </Box>

            <CardBorder innerCss={{ height: '322px' }}>
              <Card className={'card-' + step.id} css={{ 'padding': '$6', 'height': '100%', '@md': { padding: '$8' } }}>
                <StyledIconWrapper css={{ fontSize: '3rem' }}>{step.icon}</StyledIconWrapper>
                <Box
                  as="h3"
                  css={{
                    fontFamily: '$lexend',
                    fontSize: '$xl',
                    mb: '$2',
                  }}
                >
                  {step.title}
                </Box>
                <Box as="p" css={{ color: '$gray5' }}>
                  {step.description}
                </Box>
              </Card>
            </CardBorder>
          </Flex>
        ))}
      </StyledWrapper>
    </Section>
  )
}

const StyledWrapper = styled('div', {
  '$$badgeSize': '44px',
  '$$badgeGap': '24px',
  'display': 'flex',
  'flexWrap': 'wrap',
  'justifyContent': 'center',
  'gap': '2.5rem $6',
  '@media(min-width: 1387px)': {
    '& .card-2::before': {
      content: "''",
      position: 'absolute',
      top: 'calc($$badgeSize / 2)',
      right: '52%',
      bgImage: "url('/images/dotted-line.svg')",
      width: 368,
      height: 2,
    },
    '& .card-2::after': {
      content: "''",
      position: 'absolute',
      top: 'calc($$badgeSize / 2)',
      left: '52%',
      bgImage: "url('/images/dotted-line.svg')",
      width: 368,
      height: 2,
    },
  },
})

const StyledStepBadge = styled('div', {
  'dflex': 'center',
  'size': '$$badgeSize',
  'linearGradient': '$linearGradientYellow',
  'boxShadow': '$md',
  'backdropFilter': 'blur(50px)',
  'borderRadius': '$rounded',

  'fontSize': '$2xl',
  'fontWeight': '$bold',
  'position': 'relative',
  '&::after': {
    content: "''",
    position: 'absolute',
    // 2px + 2px to account for border width
    bottom: 'calc(-50% - 4px )',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '$$badgeGap',
    border: '1px solid $gray3',
  },
})

const StyledIconWrapper = styled('div', {
  size: 64,
  dflex: 'center',
  fontSize: '$5xl',
  borderRadius: '$base',
  bgColor: '$white1A',
  mb: '$4',
})

export default CoverageSteps
