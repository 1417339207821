import * as React from 'react'
import { SVGProps } from 'react'

const SvgRemediateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#remediate-icon_svg__a)" stroke="#B79313" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round">
      <path d="M40 22a16.2 16.2 0 0 0-31-4m-1-8v8h8M8 26a16.2 16.2 0 0 0 31 4m1 8v-8h-8" />
    </g>
    <defs>
      <clipPath id="remediate-icon_svg__a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgRemediateIcon
