import { Box } from 'components/elements'
import { SupportIcon } from 'components/icons'
import { Zendesk_Email } from 'features/incidents/components/enums'

const Zendesk = () => {
  return (
    <>
      <Box css={ZendeskCss}>
        <Box as="a" href={`mailto:${Zendesk_Email.EMAIL}`} css={ZendeskLinkCss}>
          <SupportIcon />
        </Box>
      </Box>
    </>
  )
}

export const ZendeskCss = {
  'position': 'fixed',
  'right': '10px',
  'bottom': '20px',
  'zIndex': '1000',
  'backgroundColor': '$primary',
  'width': '50px',
  'height': '50px',
  'borderRadius': '50%',
  'alignContent': 'center',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'transition': 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '$primaryHover',
    cursor: 'pointer',
  },
}
export const ZendeskLinkCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}
export default Zendesk
