const storagePrefix = 'dlt_portal'

const storage = {
  getToken(): string | null {
    return JSON.parse(localStorage.getItem(`${storagePrefix}_token`) as string)
  },
  setToken(token: string) {
    localStorage.setItem(`${storagePrefix}_token`, JSON.stringify(token))
  },
  clearToken() {
    localStorage.removeItem(`${storagePrefix}_token`)
  },
  setLoginBefore(state: string) {
    localStorage.setItem(`${storagePrefix}_logged_before`, state)
  },
  getLoginBefore() {
    return localStorage.getItem(`${storagePrefix}_logged_before`)
  },
}

export default storage
