import CSS from 'csstype'
import { styled } from 'styles'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'components/modal'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { convertDocUrlsToBase64, useDebounceEffect } from 'utils/function'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { TextField } from 'components/form'
import { Box, Flex } from 'components/elements'
import { Button } from 'components/button'
import { canvasPreview } from './canvasPreview'
import { OverflowScroll } from 'features/profile/components/EditProfileForm'

type ModalProps = {
  defaultImage?: string
  preview?: string
  setPreview?: (value: string) => void
  selectedFile?: Blob | undefined
  setSelectedFile?: (value: Blob | undefined) => void
  containerStyle?: CSS.Properties
}

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export const UploadImage = (props: ModalProps) => {
  const { defaultImage = '/images/img-placeholder.png', preview, selectedFile, setSelectedFile, containerStyle } = props

  const [showPreview, setShowPreview] = useState(false)
  const [profileImage, setProfileImage] = useState<string>('')

  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(16 / 9)

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
      }
    },
    500,
    [completedCrop, scale, rotate]
  )

  const getImageUrl = () => {
    const canvas = document.getElementById('cropped-preview')
    if (canvas instanceof HTMLCanvasElement) {
      canvas.toBlob(blob => {
        if (blob) {
          const file = new File([blob], 'profile.png', { type: blob.type })
          const objectUrl = URL.createObjectURL(blob)
          setProfileImage(objectUrl)
          setSelectedFile?.(file)
        }
      })
    }
  }

  const handleToggleAspectClick = () => {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(16 / 9)
      setCrop(centerAspectCrop(width, height, 16 / 9))
    }
  }

  const profileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile?.(selectedFile)
      return
    }
    setSelectedFile?.(e.target.files[0])
    const objectUrl = URL.createObjectURL(e.target.files[0])
    setProfileImage(objectUrl)
  }

  const handleModal = () => {
    setShowPreview(true)
  }

  useEffect(() => {
    const profileImg = async () => {
      if (preview) {
        const base64IUrl = await convertDocUrlsToBase64([preview])
        setProfileImage(base64IUrl[0])
      }
    }
    profileImg()
  }, [preview])

  return (
    <>
      <ImageUpdateContainer style={containerStyle}>
        <img
          src={profileImage || defaultImage}
          alt="Profile"
          className="background"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleModal()
          }}
        />
        <label htmlFor="upload-photo" className="update">
          <EditOutlinedIcon className="icon" />
        </label>
      </ImageUpdateContainer>
      <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" accept="image/*" onChange={profileHandler} />
      <Modal
        active={showPreview}
        hideModal={() => setShowPreview(false)}
        onAccept={() => {
          getImageUrl()
          setShowPreview(false)
        }}
        children={
          <>
            <Flex css={flexWrapCss}>
              <Box css={boxItemsCss}>
                <TextField label="Scale:" id="scale-input" type="number" step="0.1" value={scale} disabled={!profileImage} onChange={e => setScale(Number(e.target.value))} />
              </Box>
              <Box css={boxItemsCss}>
                <TextField
                  label="Rotate:"
                  id="rotate-input"
                  type="number"
                  value={rotate}
                  disabled={!profileImage}
                  onChange={e => setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))}
                />
              </Box>
              <Box css={boxItemsCss}>
                <Button bordered={true} type="button" onClick={handleToggleAspectClick}>
                  Free Selection {aspect ? 'off' : 'on'}
                </Button>
              </Box>
            </Flex>
            <Box css={OverflowScroll} style={{ height: '45vh' }}>
              {!!profileImage && (
                <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={c => setCompletedCrop(c)} aspect={aspect}>
                  <img ref={imgRef} alt="Crop me" src={profileImage} style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} onLoad={onImageLoad} />
                </ReactCrop>
              )}
              {!!completedCrop && (
                <canvas
                  id="cropped-preview"
                  ref={previewCanvasRef}
                  style={{
                    display: 'block',
                    border: '1px solid black',
                    objectFit: 'contain',
                    marginTop: '1rem',
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              )}
            </Box>
          </>
        }
        acceptText={'Crop'}
      />
    </>
  )
}

const ImageUpdateContainer = styled('div', {
  'position': 'relative',
  'width': '80px',
  'height': '80px',
  'margin': '0 0 1.5rem',

  '@sm': {
    mx: 12,
  },

  '.background': {
    width: '80px',
    height: '80px',
    borderRadius: '100px',
    objectFit: 'cover',
  },
  '.update': {
    'cursor': 'pointer',
    'position': 'absolute',
    'width': '26px',
    'height': '26px',
    'right': 0,
    'bottom': 0,
    'backgroundColor': '$background',
    'border': '1px solid $primary',
    'borderRadius': '16px',
    'padding': '3px',

    '.icon': {
      width: '18px',
      height: '18px',
    },
  },
})

export const boxItemsCss = {
  flex: '1 0 130px',
}

export const flexWrapCss = {
  flexWrap: 'wrap',
  alignItems: 'flex-end',
  gap: '.75rem',
  marginBottom: '1rem',
}
