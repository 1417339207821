import { Card } from '@mui/material'
import { Link } from 'react-router-dom'
import { styled } from 'styles'

export const StyledGradientDot = styled('div', {
  width: '16px',
  height: '16px',
  background: '$cardGradient',
  border: '1px solid $gray4',
  boxShadow: '0px 4px 24px -1px $black33',
  backdropfilter: 'blur(25px)',
  borderRadius: '50%',
})

export const StyledGradientLine = styled('div', {
  width: '4px',
  height: 'calc(100% - 36px)',
  background: '$gradientYellow',
  borderRadius: '4px',
})

export const MainWrapCSS = {
  'bgImage': '$offcanvasGradient',
  'padding': '1rem',

  '@xs': {
    padding: '2rem',
  },

  '&.no--bg': {
    bgImage: 'none',
  },

  '&.controlled--width': {
    'maxWidth': '100%',

    '@md': {
      maxWidth: 'calc(100vw - 390px)',
    },
  },
}
export const MainCard = styled('div', {
  '&::-webkit-scrollbar': {
    width: '6px',
  },

  '&::-webkit-scrollbar-track': {
    background: '$white1A',
    borderRadius: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '$white',
    borderRadius: '4px',
  },

  '& > .tabs': {
    'backgroundColor': '$background',
    'boxShadow': 'unset',
    'margin': '0 10px',

    '& > .MuiTabs-scroller': {
      '& > .MuiTabs-flexContainer': {
        '& > .MuiButtonBase-root': {
          color: '$white',
          borderRadius: '8px',
          backgroundColor: '$primary10',
        },
        '& > .Mui-selected': {
          color: '$white',
          borderRadius: '8px',
          backgroundColor: '$primary',
        },
      },
    },
  },

  '& > .paginator': {
    'color': '$white',

    '& > .MuiToolbar-root': {
      '& > .MuiTablePagination-actions': {
        '& > .Mui-disabled': {
          color: '$gray6',
        },
      },
      '& > .MuiInputBase-root': {
        '& > .MuiSvgIcon-root': {
          color: '$gray6',
        },
      },
    },
  },
})

export const ComponentCardItemsCSS = {
  'width': '100%',
  'pb': '1rem',
  'maxWidth': 400,

  '@bssm': {
    width: 'calc(33.33% - 10px)',
    pb: 0,
  },

  '&.two--items': {
    '@bssm': {
      width: 'calc(50% - 15px)',
      pb: 0,
    },
  },
}

export const CircleBg = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$green',
  borderRadius: '50%',
  overflow: 'hidden',
  padding: 4,
  width: '1.5rem',
  height: '1.5rem',
})

export const StyledLineBoxWrap = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '10px',
  width: '16px',
  paddingTop: '3px',
})

export const ComponentCard = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'end',
  justifyContent: 'end',
  gap: 15,
  paddingBottom: '$5',
})
export const OverflowScroll = {
  'overflowX': 'auto',

  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '$white1A',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '$white',
    borderRadius: '4px',
  },
}

export const EmptyRecord = styled('th', {
  padding: '1.25rem',
})
export const DetailContainer = styled('div', {
  'display': 'grid',
  'columnGap': 20,
  '&.custom--grid': {
    gridTemplateColumns: '1fr 1fr',
  },
  '@sm': {
    gridTemplateColumns: 'repeat(2, minmax(200px, 610px))',
  },
})

export const DetailWrapper = styled('div', {
  'p': '2rem 0 1rem',
  'borderBottom': '1px solid $gray5',
  'borderTop': '1px solid $gray5',
  '& ~ div': {
    borderTop: 'none',
  },
})

export const Divider = styled('div', {
  height: '1px',
  width: '100%',
  margin: '0 15px',
  backgroundColor: '$gray5',
})
export const InlineCSS = {
  display: 'inline-block',
  margin: 0,
  wordBreak: 'break-all',
}

export const InfoColumn = styled('div', {
  'marginBottom': '$5',

  '& .t-key': {
    fontSize: '$md',
    fontWeight: 600,
    color: '$gray5',
    wordBreak: 'break-word',
    margin: '0 0 .75rem',
  },
  '& .t-title': {
    'fontSize': '$sm',
    'fontWeight': 500,
    'color': '$white',
    'wordBreak': 'break-word',
    'margin': '0 0 .75rem',
    '&.m-0': {
      margin: '0px',
    },
  },
  '& .t-value': {
    'fontSize': '$sm',
    'wordBreak': 'break-all',
    'margin': 0,

    '&.transformation': {
      textTransform: 'capitalize',
    },

    '&.claim': {
      fontSize: '22px',
      fontWeight: 'bold',
      color: '$primary',
      m: 0,
    },

    '&.colorBox': {
      display: 'inline-block',
      borderRadius: 8,
      padding: '4px 10px 6px',
    },
  },
})

export const StyledTable = styled('table', {
  'borderCollapse': 'separate',
  'borderSpacing': 0,
  'border': '1px solid $gray8',
  'borderRadius': 14,
  'width': '100%',
  'overflow': 'hidden',
  '.responsive-mobile': {
    border: '1px solid $gray8',
    justifyContent: 'center',
  },
})

export const BoxBorder = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  p: '.75rem',
  paddingtop: '.85rem',
  border: '1px solid $primary',
  borderRadius: 8,
})
export const InlineTextCss = {
  display: 'flex',
  columnGap: 10,
}
export const TextOverFlowCSS = {
  m: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const CopiedBgCSS = {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  m: 0,
  p: '.75rem',
  background: '$white',
  borderRadius: 8,
  color: '$primary',
  textAlign: 'center',
}

export const StyledRow = styled('tr', {
  '& > th': {
    borderBottom: '1px solid $gray8',
  },
  'td': {
    'borderBottom': '1px solid $gray8',

    '&:last-child': {
      'borderBottom': 'none',
      '@md': {
        borderBottom: '1px solid $gray8',
      },
    },
  },
  '& .td': {
    border: '1px solid $gray12',
  },
})
export const DetailedCard = styled('div', {
  'maxWidth': '380px',
  'width': '100%',
  'p': '1.25rem',
  'border': '2px solid $white',
  'borderRadius': '8px',
  'fontFamily': 'Inter',
  'fontStyle': 'normal',
  'lineHeight': '34px',
  'color': '$white',
  'mb': '20px',

  '& > .wrap > .description ': {
    fontWeight: '500',
    fontsize: '14px',
    lineHeight: '150%',
    color: '$gray5',
    textAlign: 'justify',
    m: 0,
  },
  '& > .wrap > .title': {
    fontWeight: '600',
    fontsize: '16px',
    lineHeight: '150%',
    m: 0,
  },
  '& > .heading': {
    fontWeight: '700',
    fontSize: '20px',
    mb: '15px',
  },
  '& > .main-wrap': {
    'display': 'flex',
    'width': '100%',
    '& > .img-wrap': {
      display: 'flex',
      alignSelf: 'center',
      marginRight: '10px',
    },
    '& > .sub-wrap > .title': {
      fontWeight: '500',
      fontsize: '14px',
      lineHeight: '150%',
      color: '$gray5',
      m: 0,
    },
    '& > .sub-wrap > .description': {
      fontWeight: '600',
      fontsize: '16px',
      lineHeight: '150%',
      m: 0,
    },
  },
})
export const StyledStatusBox = styled('div', {
  display: 'inline-block',
  p: '2px 10px 6px',
  borderRadius: 8,
  textAlign: 'center',
})

export const SummaryCard = styled('div', {
  'width': '100%',

  '@md': {
    width: 'calc(100% - 360px)',
  },
  '@bssm': {
    width: 'calc(100% - 280px)',
  },
})

export const SideCard = styled('div', {
  'width': '100%',
  'color': '$white',

  '@md': {
    maxWidth: 360,
    pl: '2rem',
    ml: '2rem',
    borderLeft: '1px solid $gray8',
  },
  '@bssm': {
    maxWidth: 280,
    pl: '1.5rem',
    pr: '1.5rem',
    ml: '1.5rem',
    borderLeft: '1px solid $gray8',
  },
  '& .title': {
    fontWeight: '600',
    fontSize: '20px',
  },
  '& .heading': {
    fontWeight: '500',
    fontSize: '16px',
    mb: '15px',
  },
  '& .subheading': {
    fontWeight: '500',
    fontSize: '14px',
    mb: '15px',
  },
})

export const StyledTCard = styled(Card, {
  'minWidth': 320,
  'p': '.75rem 1rem',
  'borderRadius': '8px',

  '& > .main-wrap': {
    'display': 'flex',
    'width': '100%',
    '& > .img-wrap': {
      display: 'flex',
      alignSelf: 'center',
      marginRight: '10px',
    },
    '& > .sub-wrap > .title': {
      fontWeight: '500',
      fontsize: '14px',
      lineHeight: '150%',
      color: '$gray5',
      m: 0,
    },
    '& > .sub-wrap > .description': {
      fontWeight: '600',
      fontsize: '16px',
      lineHeight: '150%',
      m: 0,
    },
  },
})

export const uploadCss = {
  width: 'auto',
  padding: '0 2px 0 0',
  margin: '0',
}

export const ProgressCard = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: '1px solid $gray8',
})

export const ShowLessTextCss = {
  fontSize: 16,
  fontWeight: '$semibold',
  lineHeight: '34px',
  flexGrow: 1,
  mb: 0,
}

export const ToggleButtonCss = {
  padding: '.3rem',
  background: '$primary10',
  borderRadius: 8,
  marginTop: '1.25rem',
  justifyContent: 'center',
  gap: '1rem',
}
export const browseButtonCss = {
  textAlign: 'center',
  backgroundColor: '$primary',
  width: '100%',
  borderRadius: '8px',
  border: 'none',
  padding: '.75rem 1.5rem',
  cursor: 'pointer',
}

export const ButtonCellCSS = {
  padding: '1.25rem',
}

export const ExpendedCSS = {
  border: 'none !important',
  marginBottom: '0 !important',
}
export const flexCss = {
  'display': 'flex',
  'flexDirection': 'column-reverse',
  'gap': '1rem',
  'width': '100%',

  '@sm': {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
}
export const imgCss = {
  borderRadius: '8px',
  marginBottom: '1.5rem',
  cursor: 'zoom-in',
}
export const claimbgCss = {
  background: '$gradientGray',
  borderRadius: '4px',
  padding: '.5rem 1rem',
  maxWidth: 220,
}

export const CardCss = {
  maxWidth: '380px',
  width: '100%',
  mb: 30,
}

export const InsurancePolicyCss = {
  borderCollapse: 'separate',
  borderSpacing: 0,
  border: '1px solid $gray8',
  borderRadius: 14,
  width: '100%',
  overflow: 'hidden',
}

export const TooltipTextCss = {
  fontWeight: 'bold',
  color: '$black',
  textAlign: 'left',
  backgroundColor: '$beigeBackGround',
  maxWidth: 'fit-content',
  fontSize: '14px',
}

export const ToolTipcss = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'var(--colors-white) !important',
  },
}
export const LinkUrl = styled(Link, {
  color: '$primary',
  cursor: 'pointer',
  textDecoration: 'underline',
})

export const StripeLabel = styled('label', {
  display: 'block',
  color: '$gray4',
  fontSize: '$base',
})
export const CircularSpinnerCss = {
  '& .MuiCircularProgress-svg': {
    color: 'var(--colors-primary) !important',
  },
}
