import { styled, keyframes } from 'styles/stitches.config'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import React from 'react'

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const contentStyles = {
  'position': 'relative',
  'minWidth': 220,
  'bgImage': '$aboutGradient',

  'padding': '$4',
  'backdropFilter': 'blur(10px)',
  'animationDuration': '400ms',
  'animationTimingFunction': 'cubic-bezier(0.16, 1, 0.3, 1)',
  'willChange': 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
  'zIndex': 1,
  'borderRadius': 0,

  '@lg': {
    borderRadius: 15,
  },
}

const StyledContent = styled(DropdownMenuPrimitive.Content, contentStyles)

const StyledSubContent = styled(DropdownMenuPrimitive.SubContent, contentStyles)

function Content(props: React.ComponentPropsWithoutRef<typeof StyledContent>) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledContent {...props} />
    </DropdownMenuPrimitive.Portal>
  )
}

function SubContent(props: React.ComponentPropsWithoutRef<typeof StyledSubContent>) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledSubContent {...props} />
    </DropdownMenuPrimitive.Portal>
  )
}

const itemStyles = {
  'fontSize': '$base',
  'lineHeight': 1,
  'color': '$white',
  'display': 'flex',
  'alignItems': 'center',
  'height': 35,
  'padding': '0 8px',
  'position': 'relative',
  'userSelect': 'none',
  'gap': '$2',

  '&:focus-visible': {
    outline: 'none',
  },

  '&[data-disabled]': {
    pointerEvents: 'none',
  },

  '&[data-highlighted]': {
    borderRadius: '$sm',
    bgColor: '$white1A',
  },
}

const StyledItem = styled(DropdownMenuPrimitive.Item, itemStyles)
const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, itemStyles)
const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, itemStyles)
const StyledSubTrigger = styled(DropdownMenuPrimitive.SubTrigger, {
  ...itemStyles,
  '&[data-state="open"]': {
    // backgroundColor: violet.violet4,
    // color: violet.violet11,
  },
})

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: '25px',
  color: '$text',
})

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  opacity: 0.1,
  height: 1,
  bgColor: '$gray5',
  margin: 5,
})

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
})

// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger
export const DropdownMenuContent = Content
export const DropdownMenuItem = StyledItem
export const DropdownMenuCheckboxItem = StyledCheckboxItem
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup
export const DropdownMenuRadioItem = StyledRadioItem
export const DropdownMenuItemIndicator = StyledItemIndicator
export const DropdownMenuLabel = StyledLabel
export const DropdownMenuSeparator = StyledSeparator
export const DropdownMenuSub = DropdownMenuPrimitive.Sub
export const DropdownMenuSubTrigger = StyledSubTrigger
export const DropdownMenuSubContent = SubContent
