import clsx from 'clsx'
import { styled } from 'styles'
import { routes } from 'configs/constants'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { DownArrowIcon, IncidentIcon, SettingsIcon, EditIcon, KeyIcon, InsuranceIcon } from 'components/icons'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'components/collapsible'
import { Box } from 'components/elements'
import { Assessment } from '@mui/icons-material'
import { useGetIncidentApplicableCount } from 'features/incidents/api/getIncidentApplicableCount'

type SidebarItem = {
  name: string
  icon: React.ReactNode
  path?: string
  disabled?: boolean
  children?: SidebarItem[]
  notificationCount?: number
}

const sidebarConfig: SidebarItem[] = [
  {
    name: 'Policy',
    icon: <InsuranceIcon />,
    path: routes.insurancePolicy,
    disabled: false,
  },
  {
    name: 'Incidents',
    icon: <IncidentIcon />,
    path: routes.incidents,
    disabled: false,
    notificationCount: 76,
  },
  {
    name: 'Monitoring Services',
    icon: <Assessment />,
    path: routes.assessments,
    disabled: false,
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    children: [
      {
        name: 'Edit Profile',
        icon: <EditIcon />,
        path: routes.editProfile,
        disabled: false,
      },
      {
        name: 'Change Password',
        icon: <KeyIcon />,
        path: routes.changePass,
        disabled: false,
      },
    ],
  },
]

function SidebarMenu() {
  const { pathname } = useLocation()

  const { data } = useGetIncidentApplicableCount()

  const potentialClaim = data?.data?.applicableClaimCount

  const getMenuItem = (entry: any, isNested = false) => (
    <Box as="li" key={entry.path}>
      <MenuLink to={entry.path} className={clsx({ active: entry.path === pathname, nested: isNested })}>
        {entry.icon}
        <span>{entry.name}</span>
        {!!entry.notificationCount && (
          <>
            {potentialClaim > 0 && (
              <NotificationNumber>
                <Box as="p" css={{ m: 0 }}>
                  {potentialClaim}
                </Box>
              </NotificationNumber>
            )}
          </>
        )}
      </MenuLink>
    </Box>
  )
  return (
    <MenuWrapper>
      <MenuList>
        {sidebarConfig.map((entry, idx) => {
          if (!entry.children) {
            return getMenuItem(entry)
          }
          return (
            <>
              <Collapsible key={idx} defaultOpen={true} asChild>
                <Box as="li">
                  <CollapsibleTrigger asChild>
                    <MenuLink as="button" className="is--setting">
                      <SettingsIcon />
                      <span>Settings</span>
                      <DownArrowIcon fontSize={14} style={{ marginLeft: 'auto' }} className="svg-icon" />
                    </MenuLink>
                  </CollapsibleTrigger>

                  <CollapsibleContent asChild>
                    <MenuList>{entry.children.map(subEntry => getMenuItem(subEntry, true))}</MenuList>
                  </CollapsibleContent>
                </Box>
              </Collapsible>
            </>
          )
        })}
      </MenuList>
    </MenuWrapper>
  )
}

const MenuWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  pt: 30,
  pb: 20,
  justifyContent: 'space-between',
})

const NotificationNumber = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '2.5rem',
  height: '2.5rem',
  p: '.5rem',
  background: '$red',
  borderRadius: '50%',
})

export const MenuList = styled('ul', {
  'listStyle': 'none',
  'padding': 0,
  'margin': 0,

  'li ul li': {
    borderTop: '1px solid $gray3',
  },
})

export const MenuLink = styled(RouterLink, {
  'display': 'flex',
  'cursor': 'pointer',
  'border': 'none',
  'background': 'none',
  'width': '100%',
  'alignItems': 'center',
  'justifyContent': 'flex-start',
  'padding': '16px 24px 16px 34px',
  'height': 60,
  'gap': 14,

  '&[data-state]': {
    '.svg-icon': {
      transition: 'transform .25s linear',
    },
  },

  '&.is--setting': {
    '&[data-state="open"]': {
      '.svg-icon': {
        transform: 'rotate(180deg)',
      },
    },
  },

  '&.nested': {
    paddingLeft: 54,
  },
  '&.active': {
    background: '$primary',
    borderRadius: '0 6px 6px 0',
  },
  'svg': {
    fontSize: 28,
  },
  'span': {
    fontSize: 16,
    fontWeight: 500,
  },
})

export default SidebarMenu
