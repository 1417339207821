import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'
import { useQuery } from '@tanstack/react-query'
import { ApiKey } from 'features/incidents/components/enums'
import { ALreadyLoginDTO } from '../components/type'

export const getUserQouteInfo = async (params: ALreadyLoginDTO) => {
  return axios.get(endpoints.getUserQuote, { params })
}

export const useGetUserQouteInfo = (params: ALreadyLoginDTO) => {
  return useQuery(
    [ApiKey.Quote, ApiKey.list, params],
    () => getUserQouteInfo(params),

    //API only get call whem email is not empty string
    {
      enabled: params.email ? params.email.includes('@') : false,
    }
  )
}
