import React from 'react'
import { styled, CSS, VariantProps } from 'styles/stitches.config'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

const Label = styled('label', {
  position: 'relative',
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$2',
})

export const RadioGroup = styled(RadioGroupPrimitive.Root, {
  display: 'flex',
  gap: '$6',
  alignItems: 'center',
})

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: 'block',
  width: '12px',
  height: '12px',
  borderRadius: 'inherit',
  backgroundColor: '$primary',
})

const StyledRadio = styled(RadioGroupPrimitive.Item, {
  'userSelect': 'none',
  'appearance': 'none',
  'display': 'inline-flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'flexShrink': 0,
  'padding': 0,
  'border': '1.5px solid $blue4',
  'backgroundColor': 'transparent',
  'borderRadius': '50%',
  'color': '$fg',
  'overflow': 'hidden',

  '&:focus-visible': {
    boxShadow: '$outline',
  },

  '&[data-state="checked"]': {
    borderColor: '$primaryLight',
  },

  'variants': {
    size: {
      sm: {
        width: '$3',
        height: '$3',
      },
      md: {
        width: '20px',
        height: '20px',
      },
    },
  },
  'defaultVariants': {
    size: 'md',
  },
})

type RadioVariants = VariantProps<typeof StyledRadio>
type RadioGroupItemPrimitiveProps = React.ComponentProps<typeof RadioGroupPrimitive.Item>
type RadioProps = RadioGroupItemPrimitiveProps & RadioVariants & { css?: CSS }

export const Radio = React.forwardRef<React.ElementRef<typeof StyledRadio>, RadioProps>(({ children, ...rest }, forwardedRef) => (
  <Label>
    <StyledRadio {...rest} ref={forwardedRef}>
      <StyledIndicator />
    </StyledRadio>
    {children}
  </Label>
))

Radio.displayName = 'Radio'
