import * as React from 'react'
import { SVGProps } from 'react'

const SvgValidateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#validate-icon_svg__a)" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round">
      <path d="M20 34c7.732 0 14-6.268 14-14S27.732 6 20 6 6 12.268 6 20s6.268 14 14 14ZM42 42 30 30" stroke="#B79313" />
      <path d="m14 20 4 4 8-8" stroke="#fff" />
    </g>
    <defs>
      <clipPath id="validate-icon_svg__a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgValidateIcon
