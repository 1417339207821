import { Box } from 'components/elements/Box'
import { BgCircularPattern } from 'components/elements/BgPattern'
import { BoxTextCenter, CardTextBox, Section } from 'views/style'
import { Grid } from '@mui/material'

const CyberWarranty = () => {
  return (
    <Section css={{ position: 'relative' }}>
      <BgCircularPattern css={{ left: '-3%', top: '24%' }} />
      <BgCircularPattern css={{ right: '-5%', top: '-20%' }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={BoxTextCenter}>
          <Box css={CardTextBox}>
            <Box as="h2" css={{ '@md': { fontSize: '2rem' } }}>
              Why a cyber warranty
            </Box>
            <Box as="p" css={{ 'color': '$gray4', 'mb': '3rem', '@md': { fontSize: '$xl', lineHeight: 1.5 } }}>
              Because the majority of small and medium sized businesses can neither afford nor easily obtain cyber insurance. Cyber warranties provide the key amount of financial
              protection business need at affordable rates.
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box css={{ 'dflex': 'center', '@md': { ml: 'auto', mr: '$2' } }}>
            <video autoPlay loop muted playsInline>
              <source src={'/videos/screen-flow.webm'} type="video/webm" />
              <source src={'/videos/screen-flow.m4v'} type="video/mp4" />
            </video>
          </Box>
        </Grid>
      </Grid>
    </Section>
  )
}

export default CyberWarranty
