import { endpoints } from 'utils/endpoints'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiKey } from 'features/incidents/components/enums'
import { axios } from 'lib/axios'
export const signupByQuote = (data: object) => {
  return axios.post(endpoints.signupByQuote, data)
}
export const useSignupByQuote = () => {
  const queryClient = useQueryClient()
  return useMutation(signupByQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiKey.QuoteReg, ApiKey.list])
    },
  })
}
