import React from 'react'
import { styled, CSS, VariantProps } from 'styles/stitches.config'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check } from '@mui/icons-material'
import { useController, UseControllerProps } from 'react-hook-form'

const CheckboxRoot = styled(CheckboxPrimitive.Root, {
  'display': 'inline-flex',
  'justifyContent': 'center',
  'alignItems': 'center',
  'appearance': 'none',
  'lineHeight': '1',
  'backgroundColor': 'transparent',
  'border': '2px solid $gray7',
  'borderRadius': '4px',
  'WebkitTapHighlightColor': 'transparent',
  'overflow': 'hidden',
  'outline': 'none',

  '&:hover': {
    boxShadow: 'inset 0 0 0 1px $colors$slate8',
  },
  '&:focus-visible': {
    boxShadow: '$outline',
  },
  '&[data-state="checked"]': {
    backgroundColor: '$primary',
    borderColor: '$primary',
  },
  'svg': {
    size: '1rem',
  },

  'variants': {
    size: {
      sm: {
        size: '$3',
      },
      md: {
        size: '20px',
      },
      lg: {
        size: '$5',
      },
    },
  },
  'defaultVariants': {
    size: 'md',
  },
})

const CheckboxIndicator = styled(CheckboxPrimitive.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$white',
  size: '100%',
})

type CheckboxPrimitiveProps = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
type CheckboxVariants = VariantProps<typeof CheckboxRoot>
type CheckboxProps = Omit<CheckboxPrimitiveProps, 'children'> & CheckboxVariants & { css?: CSS }

export const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxRoot>, CheckboxProps>((props, forwardedRef) => (
  <CheckboxRoot {...props} ref={forwardedRef}>
    <CheckboxIndicator>
      <Check />
    </CheckboxIndicator>
  </CheckboxRoot>
))

type ControllerProps = CheckboxProps & UseControllerProps<any>

export const ControlledCheckbox = (props: ControllerProps) => {
  const { control, rules, name, defaultValue = false, ...rest } = props
  const { field } = useController({ control, rules, name, defaultValue })

  return <Checkbox {...rest} checked={field.value} onCheckedChange={field.onChange} />
}

Checkbox.displayName = 'Checkbox'
