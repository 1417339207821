import { Card, CardBorder } from 'components/elements/Card'
import { styled } from 'styles'
import { Office, Phishing, Ransomware, PanickedTeam, DltAlert, BackToNormal, DottedLineMobile, IrReport } from 'components/icons'
import { BgCircularPattern } from 'components/elements/BgPattern'
import { Flex } from 'components/elements/Flex'
import { Section } from 'views/style'

const attackProcess = [
  {
    id: 1,
    icon: <Office />,
    name: 'Office',
  },
  {
    id: 2,
    icon: <Phishing />,
    name: 'Phishing Attack',
  },
  {
    id: 3,
    icon: <Ransomware />,
    name: 'Ransomware Lockout',
  },
  {
    id: 4,
    icon: <PanickedTeam />,
    name: 'Panicked Team',
  },
  {
    id: 5,
    icon: <DltAlert />,
    name: 'DLT Alert Validation',
  },
  {
    id: 6,
    icon: <IrReport />,
    name: 'IR Team/ Incident Report',
  },

  {
    id: 7,
    icon: <BackToNormal />,
    name: 'Back to Normal',
  },
]

const AttackProcess = () => {
  return (
    <Section css={{ position: 'relative' }}>
      <BgCircularPattern css={{ right: '-2%', top: '-4%' }} />

      <h2 className="section-title">Ready for the Next Global Attack?</h2>

      <StyledWrapper css={{ p: '28px 0 32px' }}>
        {attackProcess.map(({ id, icon, name }) => (
          <Flex key={id} className={`process item-${id}`} direction="column" align="center" css={{ gridArea: 'item-' + id }}>
            <CardBorder>
              <StyledCard>
                <span className="icon">{icon}</span>
                <p>{name}</p>
              </StyledCard>
            </CardBorder>
            <span className="arrow">
              <DottedLineMobile fontSize={40} />
            </span>
          </Flex>
        ))}
      </StyledWrapper>
    </Section>
  )
}

const StyledWrapper = styled('div', {
  'display': 'flex',
  'justifyContent': 'center',
  'alignItems': 'flex-start',
  'flexWrap': 'wrap',
  'gap': '0 $6',
  '& .item-6': {
    display: 'none',
  },
  '& .process:last-child .arrow': {
    display: 'none',
  },
  '@md': {
    'mt': 100, // TODO: move this to section heading
    'display': 'grid',
    'gridTemplateAreas': `
      "item-1 .      item-5"
      "item-2 item-4 item-6"
      "item-3 .      item-7"`,
    'columnGap': 85,
    '& .item-6': {
      display: 'flex',
    },
    '& .item-3 .arrow, & .item-4 .arrow': {
      display: 'none',
    },
    '& .item-4': {
      position: 'relative',
    },
    '& .item-4::before': {
      content: "''",
      bgImage: "url('/images/bottom-arrow-connect.png')",
      position: 'absolute',
      width: 395,
      height: 213,
      right: '47%',
      bottom: '-154%',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left',
    },
    '& .item-4::after': {
      content: "''",
      bgImage: "url('/images/top-arrow-connect.png')",
      position: 'absolute',
      width: 394,
      height: 213,
      left: '50%',
      top: '-151%',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top right',
    },
  },
})

const StyledCard = styled(Card, {
  'dflex': 'center',
  'minHeight': 140,
  'width': 290,
  '& .icon': {
    fontSize: 56,
  },
  '@md': {
    width: 300,
  },
})

export default AttackProcess
