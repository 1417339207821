import { useGetPlanListingForQuote } from 'features/quote/api/getPlanListingForQuote'
import * as Yup from 'yup'
import { PlanFormData, QuoteReviewProps, ServiceListing } from '../type'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { wrapStyle } from 'views/Support/SupportForm'
import { Box, Flex } from 'components/elements'
import { Listing, MenuDropdown } from 'components/menuDropdown'
import { useEffect, useMemo, useState } from 'react'
import FormWrapper from 'features/auth/components/FormWrapper'
import { Button } from 'components/button/Button'
import { useNavigate } from 'react-router-dom'
import { routes } from 'configs/constants'
import { ErrorBox } from 'features/profile/components/EditProfileForm'
import { Stages, ValidationErrors } from 'utils/enum'
import { ErrorCSS, Form, userRegHeadingCss } from 'views/style'
import { QouteLayout } from 'features/quote/page/GetAQuotes'
import { buttonCss } from './CoveragePlan'

export const planScehema = Yup.object({
  planId: Yup.number().required(ValidationErrors.QuotePolicy),
})

const PlanSelection = (props: QuoteReviewProps) => {
  const { quoteUserReg, setQuoteReview, setStage, setQuoteUserReg } = props
  const [insurancePlan, setInsurancePlan] = useState<Listing>()
  const navigate = useNavigate()
  const { data: planList, isLoading: isPlanListingLoading } = useGetPlanListingForQuote()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<PlanFormData>({
    resolver: yupResolver(planScehema),
    defaultValues: {
      planId: (quoteUserReg && quoteUserReg.planId) || undefined,
    },
  })

  //all the policy into select lisiting format
  const insurancePlansList = useMemo(() => {
    return planList?.data.plans || []
  }, [planList])

  const policyPlansList = useMemo(() => {
    if (insurancePlansList) {
      return insurancePlansList.map(
        (
          items: { name: string; id: number; basePremium: string; maxCoverage: string; primaryServiceId: ServiceListing; services: ServiceListing[]; discount: number },
          index: number
        ) => {
          return {
            label: items?.name,
            value: items?.id,
            key: index,
            discount: items?.discount,
            premiumAmount: items?.basePremium,
            maxCoverage: items?.maxCoverage,
            primaryService: items?.primaryServiceId,
            services: items?.services,
          }
        }
      )
    }
  }, [insurancePlansList])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    const plan = quoteUserReg && quoteUserReg?.planId

    if (policyPlansList) {
      const planDetails = policyPlansList.find((policy: { value: number }) => policy.value === plan)

      // Set the plan state with the matching label and value i already record exist
      if (planDetails) {
        setInsurancePlan({
          label: planDetails.label,
          value: planDetails.value,
          premiumAmount: planDetails.premiumAmount,
          maxCoverage: planDetails.maxCoverage,
          primaryService: planDetails.primaryService,
          services: planDetails.services,
          discount: planDetails?.discount,
        })
      }
    }
  }, [policyPlansList, quoteUserReg])
  const onSubmit = async (data: PlanFormData) => {
    const primaryService = insurancePlan?.primaryService
    //secondary service should not have the primary service
    const secondaryservices = insurancePlan?.services?.filter((service: { id: number }) => service.id !== primaryService?.id)

    const obj = {
      ...quoteUserReg,
      planId: data.planId,
      primaryService: primaryService,
      secondaryservices: secondaryservices,
      discount: insurancePlan?.discount,
    }

    if (insurancePlan) setQuoteReview && setQuoteReview({ insurancePlan })

    setQuoteUserReg && setQuoteUserReg(obj)
    setStage && setStage(Stages.STAGE2)
  }

  return (
    <>
      <Flex align="start" justify="center" css={QouteLayout}>
        <FormWrapper controlWidth={{ maxWidth: 800 }}>
          <Flex align="center">
            <Flex as="h2" justify={'center'} css={userRegHeadingCss}>
              Get Quote
            </Flex>
          </Flex>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box css={{ textAlign: 'left' }}>
              <Box css={wrapStyle} style={{ width: '100%', paddingLeft: 0 }}>
                <Controller
                  name={'planId'}
                  control={control}
                  render={({ field }) => (
                    <MenuDropdown
                      {...field}
                      isDisabled={isPlanListingLoading}
                      isResponseFetching={isPlanListingLoading}
                      {...register('planId')}
                      label="Select  Policy"
                      placeholder="Select  Policy"
                      value={insurancePlan}
                      list={policyPlansList}
                      setValue={e => {
                        setInsurancePlan(e)
                        field.onChange(e.value)
                        setValue('planId', e.value)
                      }}
                    />
                  )}
                />

                {!!errors.planId && <ErrorBox css={{ ...ErrorCSS, textAlign: 'left', mt: '$3' }}>{errors.planId?.message}</ErrorBox>}
              </Box>
              <Box as="h5" css={{ color: '$primary', textAlign: 'left', m: 0 }}></Box>
            </Box>

            <Box css={{ ...wrapStyle, textAlign: 'left', padding: 0 }} className="fullWidth">
              {!props?.quoteUserReg?.status && (
                <Box as="p">
                  Restore your previous flow?&nbsp;
                  <Box as="span" css={{ ...TermsCSS, cursor: 'pointer' }} onClick={() => props?.setStage && props?.setStage(Stages.STAGE6)}>
                    Click here
                  </Box>
                </Box>
              )}
            </Box>
            <Box css={buttonCss}>
              <Button bordered={true} type="button" onClick={() => navigate(routes.login)}>
                Cancel
              </Button>
              <Button type="submit">Next</Button>
            </Box>
          </Form>
        </FormWrapper>
      </Flex>
    </>
  )
}

export const TermsCSS = {
  color: '$primary',
  border: 'none',
  bgColor: 'transparent',
  textAlign: 'center',
  padding: '0px',
}

export const BorderCss = {
  border: '1px solid $white5',
}
export default PlanSelection
