import * as React from 'react'
import { SVGProps } from 'react'

const SvgUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#user-icon_svg__a)" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path d="M14 12.833A4.667 4.667 0 1 0 14 3.5a4.667 4.667 0 0 0 0 9.333ZM7 24.5v-2.333a4.667 4.667 0 0 1 4.667-4.667h4.666A4.667 4.667 0 0 1 21 22.167V24.5" />
    </g>
    <defs>
      <clipPath id="user-icon_svg__a">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgUserIcon
