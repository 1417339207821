import * as React from 'react'
import { SVGProps } from 'react'

const SvgIntegrateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6 5.5h14a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5V6a.5.5 0 0 1 .5-.5Z" stroke="#fff" strokeWidth={3} />
    <path
      d="M6 27.5h14a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5V28a.5.5 0 0 1 .5-.5ZM28 5.5h14a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5H28a.5.5 0 0 1-.5-.5V6a.5.5 0 0 1 .5-.5ZM28 27.5h14a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5H28a.5.5 0 0 1-.5-.5V28a.5.5 0 0 1 .5-.5Z"
      stroke="#B79313"
      strokeWidth={3}
    />
  </svg>
)

export default SvgIntegrateIcon
