import { styled } from 'styles'
import { Box, Flex } from 'components/elements'
import { FlexWrapCSS, inputStyle, wrapStyle } from 'views/Support/SupportForm'
import { TextField } from 'components/form'
import { StyledDropdownWrap, StyledLabel } from 'components/form/Textfield.styles'
import { FormControl, Tooltip } from '@mui/material'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { ToolTipcss } from 'features/incidents/components/style'
import { ErrorBox, FormBtnWrap, ModalbuttonWrapCss } from 'features/profile/components/EditProfileForm'
import { Button } from 'components/button'
import { Form1Props, QuoteDTO } from 'features/quote/components/type'
import { useEffect, useState } from 'react'
import { useSignupByQuote } from 'features/quote/api/signupByQuote'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAQoute } from 'features/quote/components/GetAQuoteForm'
import { AxiosError } from 'axios'
import { errorMessage, getUpperCase } from 'utils/function'
import { PHONE_VALIDATION } from 'utils/enum'
import { routes } from 'configs/constants'

const AffiliateUserLandingPage = (props: Form1Props) => {
  const { quoteUserReg, setQuoteUserReg, isMerchantAffiliateDetailSet, apiError } = props
  const user = quoteUserReg?.users
  const [submitError, setSubmitError] = useState('')
  const [phoneNum, setPhoneNumber] = useState<string>(user?.phoneNum || '')
  const [hasError, setHasError] = useState('')
  const navigate = useNavigate()
  const { mutate, isLoading } = useSignupByQuote()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const carrier_AffiliateId = searchParams.get('affiliateId')
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    reset,
  } = useForm<QuoteDTO>({
    resolver: yupResolver(getAQoute),
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      companyName: user?.companyName || '',
      phoneNum: user?.phoneNum || '',
      merchantAffiliateId: user?.merchantAffiliateId,
    },
  })
  useEffect(() => {
    if (carrier_AffiliateId) setValue('merchantAffiliateId', carrier_AffiliateId)
  }, [carrier_AffiliateId, setValue])

  const onSubmit: SubmitHandler<QuoteDTO> = async data => {
    if (hasError !== '') return
    setSubmitError('')

    const services = quoteUserReg?.service
    //box carrier is paid ,  skip Payment
    const skipPayment = true
    const newObj = {
      ...quoteUserReg,
      ...data,
      services,
      skipPayment,
    }
    data.merchantAffiliateId
    setQuoteUserReg?.({
      ...quoteUserReg,
      users: {
        ...data,
        merchantAffiliateId: data.merchantAffiliateId === '' ? null : data.merchantAffiliateId,
      },
      status: true,
    })

    delete newObj.service
    delete newObj.users
    if (newObj.merchantAffiliateId === '') {
      newObj.merchantAffiliateId = null
    }
    mutate(newObj, {
      onSuccess: data => {
        reset()
        setPhoneNumber('')
        window.location.href = data?.url
      },
      onError: error => {
        if (error instanceof AxiosError) {
          setSubmitError(errorMessage(error))
        }
      },
    })
  }

  const PhoneNumber = (newValue: string | undefined) => {
    if (newValue !== undefined) {
      setPhoneNumber(newValue)
      setValue('phoneNum', newValue, { shouldValidate: true })
      if (isValidPhoneNumber(newValue)) setHasError('')
      else {
        setHasError(PHONE_VALIDATION.PHONEERROR)
      }
    }
  }
  return (
    <>
      <LandingPageBanner>
        <GradientOverlay />
        <Flex justify={'start'} align={'center'} css={{ height: '100%', marginLeft: '5rem' }}>
          <Flex css={{ zIndex: 1, gap: '2rem', maxWidth: '600px' }} direction={'column'}>
            <Box as="img" src="/images/CRC-Lock-Up.png" css={{ maxWidth: '100%', objectFit: 'scale-down' }}></Box>
            <Box>
              <Box as="h1" css={{ fontSize: 'clamp($md, 2.5vw, $4xl)' }}>
                Activate R² Ransomware
              </Box>
              <Box as="p" css={{ marginTop: '2rem' }}>
                With CRC CyberTrust, you get access to R² Ransomware by DLT Alert which monitors your business’s connected endpoint devices with automated alerts, help & support,
                and a $5,000 emergency payment if ransomware is detected.
              </Box>
            </Box>
          </Flex>
        </Flex>
      </LandingPageBanner>
      <Box
        css={{
          'background': '$white77',
          'padding': '5rem',
          '@media(max-width: 800px)': {
            padding: 0,
          },
        }}
      >
        <Flex justify="center" align={'center'} direction={'column'} css={{ background: '#FFFFFF', padding: '1rem', maxWidth: '900px', margin: '0 auto' }}>
          <Box
            css={{
              'padding': '5rem',
              '@media(max-width: 1200px)': {
                padding: '1rem',
              },
            }}
          >
            <Box as="h3" css={{ textAlign: 'center', lineHeight: '1.5', fontSize: '1.5rem', marginBottom: '2rem', color: '$black39', fontWeight: '$bold' }}>
              Secure Your Business With Comprehensive Ransomware Protection In Under 3 Minutes.{' '}
            </Box>
            <Flex as="form" css={{ width: '90%' }} direction="column" onSubmit={handleSubmit(onSubmit)}>
              {!!submitError && <ErrorBox css={{ textAlign: 'left', mb: '$3' }}>{getUpperCase(submitError)}</ErrorBox>}
              {!!apiError && <ErrorBox css={{ textAlign: 'left', mb: '$3' }}>{getUpperCase(apiError)}</ErrorBox>}

              <Flex css={FlexWrapCSS}>
                <Box css={wrapStyle}>
                  <TextField
                    isCustom={true}
                    color="black"
                    label="First Name"
                    placeholder="First Name"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    {...register('firstName')}
                    css={inputStyle}
                  />
                </Box>
                <Box css={wrapStyle}>
                  <TextField
                    isCustom={true}
                    color="black"
                    label="Last Name"
                    placeholder="Last Name"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    {...register('lastName')}
                    css={inputStyle}
                  />
                </Box>
              </Flex>
              <Flex css={FlexWrapCSS}>
                <Box css={wrapStyle}>
                  <TextField
                    isCustom={true}
                    color="black"
                    label="Email"
                    placeholder="Email"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    {...register('email')}
                    css={inputStyle}
                  />
                </Box>
                <Box css={wrapStyle}>
                  <TextField
                    isCustom={true}
                    color="black"
                    label="Company Name"
                    placeholder="Company Name"
                    error={!!errors.companyName}
                    helperText={errors.companyName?.message}
                    {...register('companyName')}
                    css={inputStyle}
                  />
                </Box>
              </Flex>
              <Flex css={FlexWrapCSS}>
                <Box css={wrapStyle}>
                  <StyledDropdownWrap style={{ margin: 0, maxWidth: '520px' }} className="phoneNumber">
                    <FormControl
                      fullWidth
                      className={`phoneNumber 
                 ${errors.phoneNum ? 'phoneNumberCustomError' : 'phoneNumbercustom-carrier'} `}
                    >
                      <StyledLabel css={{ color: '$black39' }}>Phone Number</StyledLabel>
                      <PhoneInput
                        international
                        defaultCountry="US"
                        countryCallingCodeEditable={false}
                        placeholder="Phone number"
                        value={phoneNum}
                        onChange={newValue => {
                          PhoneNumber(newValue)
                        }}
                      />
                    </FormControl>
                    <Box as="p" className="errorText">
                      {hasError}
                    </Box>
                    {!!errors.phoneNum && (
                      <Box as="p" className="errorText">
                        {errors.phoneNum?.message}
                      </Box>
                    )}
                  </StyledDropdownWrap>
                </Box>
                <Box css={wrapStyle}>
                  <Flex wrap={'wrap'} css={{ columnGap: '10px' }}>
                    <Tooltip
                      PopperProps={{ sx: ToolTipcss }}
                      title={
                        <Box as="span" css={{ color: '$black' }}>
                          This is the affiliate ID for reference of the carrier through which you came to know of DLT.
                        </Box>
                      }
                      placement="bottom"
                      enterTouchDelay={0}
                    >
                      <StyledLabel css={{ color: '$black39', textDecoration: 'underline' }}>Carrier AffiliateId</StyledLabel>
                    </Tooltip>
                  </Flex>

                  <TextField
                    isCustom={true}
                    color="black"
                    fieldDisabled={true}
                    placeholder="Affiliate Id"
                    error={!!errors.merchantAffiliateId}
                    helperText={errors.merchantAffiliateId?.message}
                    {...register('merchantAffiliateId')}
                    css={inputStyle}
                  />
                </Box>
              </Flex>

              <Flex css={{ ...ModalbuttonWrapCss, ...FormBtnWrap }}>
                <Button bordered={false} type="button" onClick={() => navigate(routes.login)} size="lg">
                  Cancel
                </Button>
                <Button type="submit" size="lg" disabled={isMerchantAffiliateDetailSet} color={'secondary'}>
                  {isLoading ? 'Submitting...' : 'Submit'}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  )
}

const LandingPageBanner = styled(Box, {
  'position': 'relative',
  'width': '100%',
  'height': '100vh',
  'backgroundImage': "url('/images/LandingPageBanner.png')",
  'backgroundSize': 'cover',
  'backgroundPosition': 'top',
  'overflow': 'hidden',
  '@media(max-width: 768px)': {
    height: '50vh', // Adjust gradient height for smaller screens
  },
})

const GradientOverlay = styled(Box, {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(90deg, rgb(0, 0, 0) 18.18%, rgba(255, 255, 255, 0.2) 68.39%, rgba(255, 255, 255, 0.04) 74.39%)',
})
export default AffiliateUserLandingPage
