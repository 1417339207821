import * as React from 'react'
import { SVGProps } from 'react'

const SvgEditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#edit-icon_svg__a)" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path d="M8.166 8.166H6.999A2.333 2.333 0 0 0 4.666 10.5V21a2.333 2.333 0 0 0 2.333 2.333h10.5A2.334 2.334 0 0 0 19.833 21v-1.167" />
      <path d="M23.782 7.683a2.45 2.45 0 0 0-3.465-3.465L10.5 14v3.5H14l9.782-9.817v0ZM18.666 5.833l3.5 3.5" />
    </g>
    <defs>
      <clipPath id="edit-icon_svg__a">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgEditIcon
