export const insuranceForm = {
  q1: {
    typesOfSensitiveData: {
      '1': 'No compliance and no backups are running.',
      '2': 'No compliance but we backup workstations.',
      '3': 'Some compliance but we do not backup.',
      '4': 'High compliance levels and we backup.',
      '5': 'Unsure.',
    },
  },
  q2: {
    annualRevenue: {
      '1': '<$1mm',
      '2': '<$5mm',
      '3': '<$20mm',
      '4': '<$50mm',
      '5': '<$100mm',
      '6': 'More than $100mm',
    },
  },
  q3: {
    experiencedCyberAttack: {
      '1': 'No, we have not been impacted by a cyber threat and have not actively attempted to prevent one.',
      '2': 'No, we have not been impacted by a cyber threat and we employ a solution to keep it that way.',
      '3': 'Yes, we were hit by a targeted malware that took us offline.',
      '4': 'Yes, we were impacted by ransomware that came via a bad email.',
      '5': 'Yes, we were hit by a DDoS.',
      '6': 'Yes, we have been hit with multiple types of threats.',
    },
  },
  q4: {
    conductCyberAttackTraining: {
      '1': 'We do not give cyber training but have a list of policy guidelines.',
      '2': 'We do not have official cyber training, but we do train people internally on what to do and not to do online.',
      '3': 'We do offer cyber training to employees when they are initially onboarded, but not as a continuing education.',
      '4': 'We ensure all employees have cyber training once a year.',
    },
  },
  q5: {
    howManyEmployees: {
      '1': '1-25',
      '2': '25-50',
      '3': '51-100',
      '4': '101-250',
      '5': '251-500',
      '6': '501-1000',
      '7': '1000+',
    },
  },
  // q6: {
  //   backupAtSecondaryLocation: {
  //     '1': 'Yes, backups are offsite, but remain untested',
  //     '2': 'Yes, backups are offsite and tested at minimal once a year',
  //     '3': 'No, backups are only locally stored',
  //     '4': 'No, we do not have backups for any business-critical machines',
  //   },
  // },

  // q7: {
  //   totalServersCompanyHave: {
  //     '1': 'Less Than Five',
  //     '2': 'Less Than Ten',
  //     '3': 'Less Than Twenty Five',
  //     '4': 'Greater Than Twenty Five',
  //   },
  // },
  // q8: {
  //   totalEndPointsCompanyHave: {
  //     '1': 'Less Than Twenty Five',
  //     '2': 'Less Than Hundred',
  //     '3': 'Less Than Two Hundred',
  //     '4': 'Greater Than Two Hundred',
  //   },
  // },
  // q9: {
  //   isPerformVulnerabilityAndPatching: {
  //     true: true,
  //     false: false,
  //   },
  // },
  // q10: {
  //   isPiiStoredEncrypted: {
  //     true: true,
  //     false: false,
  //   },
  // },
  // q11: {
  //   isMeasuresImplementedToProtect: {
  //     true: true,
  //     false: false,
  //   },
  // },
  // q12: {
  //   employeesUndergoAwarenessTraining: {
  //     '1': 'Security training is provided at onboarding but not ongoing',
  //     '2': 'Security training is provided yearly to all employees',
  //     '3': 'We do not require security training',
  //   },
  // },
  // q13: {
  //   isSecondaryConfirmationProcessImplemented: {
  //     true: true,
  //     false: false,
  //   },
  // },

  // q14: {
  //   isNetworkSegmented: {
  //     true: true,
  //     false: false,
  //   },
  // },

  // q15: {
  //   organizationRestrictAuthorizedUsers: {
  //     '1': 'Only authorized users can install and run privileged software',
  //     '2': 'Any user can install software but cannot run privileged software',
  //     '3': 'Any user can run privileged software but not install programs',
  //     '4': 'No any user can install and run privileged software',
  //   },
  // },

  // q16: {
  //   passwordPolicyImplemented: {
  //     '1': 'Password policy is defined and implemented via group policy',
  //     '2': 'Password policy is defined and recommended but not enforced',
  //     '3': 'Password policy is undefined but strong passwords are recommended',
  //     '4': 'Password policy is undefined, and no recommendations are given',
  //   },
  // },
  // q17: {
  //   multifactorAuthenticationImplemented: {
  //     '1': 'Multifactor is enabled for both privileged and non-privileged accounts',
  //     '2': 'Multifactor is enabled for admins but not non-privileged accounts',
  //     '3': 'Multifactor is available but not configured',
  //     '4': 'Multifactor is NOT available or configured',
  //   },
  // },
  // q18: {
  //   isPacisProtected: {
  //     true: true,
  //     false: false,
  //   },
  // },
}
