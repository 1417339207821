import { Box, Flex } from 'components/elements'
import { Button } from 'components/button'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { NotificationMessages, QuoteStatement, Stages, ValidationErrors } from 'utils/enum'
import { ErrorBox } from 'features/profile/components/EditProfileForm'
import { QuoteProps, QuoteServicesProps } from '../type'
import FormWrapper from 'features/auth/components/FormWrapper'
import { useNavigate } from 'react-router-dom'
import { routes, serviceLableAssessments } from 'configs/constants'
import * as Yup from 'yup'
import { MenuList } from 'components/sidebarLayout/Sidebar/SidebarMenu'
import { FlexWrapCSS, inputStyle, wrapStyle } from 'views/Support/SupportForm'
import { StyledDropdownWrap, StyledHelperText, StyledLabel } from 'components/form/Textfield.styles'
import { TextField } from 'components/form'
import { CheckBoxInput, ErrorCSS, Form, Label, userRegHeadingCss } from 'views/style'
import { BackNavArrowIcon } from 'components/icons'
import { QouteLayout } from 'features/quote/page/GetAQuotes'
import { buttonCss } from './CoveragePlan'
import { formatServiceName } from 'utils/function'
export const step1Schema = Yup.object({
  service: Yup.array().required(ValidationErrors.SERVICESOPTIONS),
  others: Yup.string().nullable(),
})

export const ServicesFormBase = ({ props, stage }: { props: QuoteProps; stage: Stages }) => {
  const { quoteUserReg, setStage, setQuoteUserReg } = props

  const [hasServices, setHasServices] = useState('') //if user selected more then two services
  const secondaryServices = quoteUserReg?.secondaryservices
  const primary_service = quoteUserReg?.primaryService
  const discount = quoteUserReg?.discount || 0
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    register,
  } = useForm<QuoteServicesProps>({
    resolver: yupResolver(step1Schema),
    defaultValues: {
      service: [],
      others: quoteUserReg && quoteUserReg?.others,
    },
  })

  useEffect(() => {
    //settting the value of checkboxes if already checked
    if (quoteUserReg && quoteUserReg.service) {
      quoteUserReg &&
        quoteUserReg.service.forEach(item => {
          setValue(`service.${item}`, item)
        })
      //exculding the primary services
      //discount will applied if user have more then on secondary services
      const services = quoteUserReg && quoteUserReg.service.filter(item => item !== primary_service?.id)
      if (services && services.length > 1) {
        const appliedDiscount = calculateDiscount(services, discount)
        setHasServices(`${appliedDiscount}${NotificationMessages.DISCOUNT}`)
      }
    }
  }, [discount, primary_service, quoteUserReg, setValue])

  const navigate = useNavigate()

  const onSubmit = (data: QuoteServicesProps) => {
    //filter out the services should not be null,undefined and should not have primaryservice
    let services = (data?.service && data?.service.filter(item => item !== null && item !== undefined)) || []
    services = services.filter(item => item !== primary_service?.id)

    if (primary_service) {
      services = [...services, primary_service?.id]
    }

    const obj = {
      ...props.quoteUserReg,
      service: services,
      others: data?.others && data?.others,
      discount: discount,
    }
    if (obj.others === '') delete obj.others
    setQuoteUserReg && setQuoteUserReg(obj)
    setStage && setStage(stage)
  }

  const handleServices = () => {
    const servicesRecord = getValues('service')
    //exculding the primaryServices

    const services = (servicesRecord && servicesRecord.filter(item => item !== null && item !== undefined && item !== primary_service?.id)) || []
    if (services && services.length > 1) {
      const appliedDiscount = calculateDiscount(services, discount)

      setHasServices(`${appliedDiscount}${NotificationMessages.DISCOUNT}`)
    } else {
      setHasServices('')
    }
  }
  return (
    <>
      <Flex align="start" justify="center" css={{ ...QouteLayout, margin: '40px 0' }}>
        <FormWrapper controlWidth={{ maxWidth: 700 }}>
          <Flex align="center">
            <BackNavArrowIcon fontSize={24} style={{ cursor: 'pointer' }} onClick={() => props?.setStage && props.setStage(Stages.STAGE1)} />
            <Flex as="h2" justify={'center'} css={userRegHeadingCss}>
              Get A Quote
            </Flex>
          </Flex>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box css={{ textAlign: 'left' }}>
              <Box css={{ fontSize: '1rem', mb: '1rem', color: '$primary' }}>{QuoteStatement.STEP2}</Box>

              {/* primary services */}

              <Box>
                <Box as="h4" css={{ paddingLeft: 0, fontWeight: '$bold' }}>
                  Primary
                </Box>
                {primary_service && (
                  <MenuList>
                    <Label key={primary_service?.name}>
                      <CheckBoxInput type="checkbox" value={primary_service?.id} data-state={'checked'} disabled />
                      <Box as="p" css={{ fontWeight: '$bold' }}>
                        {serviceLableAssessments[formatServiceName(primary_service?.name ?? '')]} -&nbsp;
                        <Box as="span">{formatServiceName(primary_service?.name ?? '')}</Box>
                      </Box>
                    </Label>
                  </MenuList>
                )}
              </Box>
              {/* sceondary Services */}
              <Box>
                <Box as="h4" css={{ paddingLeft: 0, fontWeight: '$bold' }}>
                  Add-ons
                </Box>

                {secondaryServices?.map(
                  (item, idx) =>
                    // service
                    item?.id !== primary_service?.id && (
                      <MenuList key={idx}>
                        <Label key={item.name}>
                          <Controller
                            name={`service.${item.id}`}
                            control={control}
                            render={({ field }) => (
                              <CheckBoxInput
                                type="checkbox"
                                value={item.value}
                                checked={field.value === item.id}
                                onChange={() => {
                                  const newFieldValue = field.value === item.id ? null : item.id
                                  field.onChange(newFieldValue) // Update the field value
                                  handleServices()
                                }}
                                data-state={field.value === item.id ? 'checked' : 'unchecked'}
                              />
                            )}
                          />
                          <Box as="p" css={{ fontWeight: '$bold' }}>
                            {serviceLableAssessments[formatServiceName(item?.name ?? '')]} -&nbsp;
                            <Box as="span">{formatServiceName(item?.name ?? '')}</Box>
                          </Box>
                        </Label>
                      </MenuList>
                    )
                )}
              </Box>
            </Box>

            {hasServices && (
              <Flex>
                <Box as="h2" css={{ color: '$primary' }}>
                  {hasServices}
                </Box>
              </Flex>
            )}

            <Flex css={FlexWrapCSS}>
              <StyledDropdownWrap className="fullWidth" css={{ wrapStyle, mt: '$3', width: '100%', textAlign: 'left' }}>
                <StyledLabel>Any other services you are interested in</StyledLabel>
                <TextField placeholder="Type a service name here" error={!!errors.others} {...register('others')} css={inputStyle} />
                <StyledHelperText>
                  <ErrorBox css={{ ...ErrorCSS, textAlign: 'left', mt: '$3' }}>{errors.others?.message && errors.others?.message}</ErrorBox>
                </StyledHelperText>
              </StyledDropdownWrap>
            </Flex>
            <Box css={{ ...wrapStyle, textAlign: 'left', padding: 0 }} className="fullWidth">
              <Box as="p">
                Don’t have any service currently?&nbsp;
                <Box
                  as="span"
                  css={{ color: '$primary', cursor: 'pointer' }}
                  onClick={() => navigate(routes.quoteSupportForm, { state: { primaryService: primary_service, secondaryServices: secondaryServices } })}
                >
                  Click here to get one
                </Box>
              </Box>
            </Box>

            <Box css={buttonCss}>
              <Button bordered={true} type="button" onClick={() => navigate(routes.login)}>
                Cancel
              </Button>
              <Button type="submit">Next</Button>
            </Box>
          </Form>
        </FormWrapper>
      </Flex>
    </>
  )
}
export const calculateDiscount = (services: number[], discount: number) => {
  if (services.length > 1) {
    const serviceLength = services.length - 1
    return serviceLength * discount
  } else {
    return 0
  }
}
