export enum ROUTE_ENUM {
  HOME_ROUTE = '/',
  LOGIN_ROUTE = '/login',
  SIGNUP_ROUTE = '/signup',
  FORGOT = '/forgot',
  SET_PASSWORD = '/set-password',
  TWOFA = '/two-fa',
}

export enum FIELD_ICON_POSITION_ENUM {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum PHONE_VALIDATION {
  PHONEERROR = 'Please enter valid phonenum w.r.t country code',
}
export enum FORM_FIELD_TYPE_ENUM {
  EMAIL = 'email',
  PASSWORD = 'password',
  TEXT = 'text',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  NUMBER = 'number',
}

export const MESSAGE_ENUM = {
  SENTINEL_REGISTERATION: 'We are fetching your data and it may take some time',
  ERROR_MESSAGE: 'DLT Server is facing some temporary issue, please try again later',
  VERIFICATION_LINK: 'to login to your account please verify your account. we have sent a verification link to your registered email',
}

export enum ValidationErrors {
  NAME = 'Name is required',
  Message = 'Message is required',
  EMAIL_VALIDATION = 'Email must be a valid email address',
  EMAIL = 'Email is required',
  WEBSITE_URL = 'Website URL is required',
  WEBSITE_URL_VALIDATION = 'Please provide a valid website url',
  INSURANCE_COMPANY = 'Insurance Company is required',
  PORTFOLIO_NUMBER = 'Portfolio number is required',
  DESCRIPTION = 'Description is required',
  ISSUE_NAME = 'Issue name is required',
  COMPANY = 'Company name is required',
  COMPANY_URL = 'Company url is required',
  ADDRESS = 'Address is required',
  PASSWORD = 'Password is required',
  PHONE = 'Phone number is required',
  PHONE_VALIDATION = 'Phone number start with + followed by the country code with 10 digits',
  MATCH_PASSWORD = 'Passwords must match',
  CONFIRM_PASSWORD = 'Confirm password is required',
  DEFAULT = 'Failed to register, Please try again.',
  PASSWORD_VALIDATION = 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  OLD_PASSWORD = 'Old password is required',
  NEW_PASSWORD = 'New password is required',
  FIRST_NAME = 'First name is required',
  LAST_NAME = 'Last name is required',
  FIRST_NAME_VALIDATION = 'First name must have only alphabet shorter than or equal to 10 characters ',
  LAST_NAME_VALIDATION = 'Last name must have only alphabet shorter than or equal to 10 characters ',
  WHITE_SPACE = 'White space not allowed',
  NO_WHITE_SPACE = 'No white space is allowed',
  ADDRESS_VALIDATION = 'Address at least have 5, but not more than 100 characters',
  COMPANY_NAME = 'Company name is required',
  COMPANY_NAME_VALIDATION = 'Company name at least have 2, but not more than 30 characters',
  SENTINEL = 'Sentinel One  API Key is required',
  START_TIME = 'Start time is required',
  TITLE = 'Title is required',
  NO_DEVICES = 'Number of devices is required',
  TIME = 'Time is required',
  DATE = 'Date is required',
  TIME_ZONE = 'Time Zone  is required',
  COMMUNICATION_MEDIUM = 'Communication Medium is required',
  COVERAGE_PLAN = 'Please select one of the  Ransomeware Coverage options',
  TC = 'Please agree to the terms and conditions',
  SERVICESOPTIONS = 'Please select at least on of the services',
  QuotePolicy = 'Please select  one of the policy',
  ADD_SERVICE = 'Please select one of the services',
  USER_NAME = 'Username is required',
  Patch = 'N-Sight-Patch Managment  API Key is required',
  SUPPORT_FORM_ERROR = 'At least one service must be selected',
  FILE_DUPLICATION = 'Duplicate file  found. Please resolve before submitting.',
  SENTINELONE_URL = 'Sentinel One URL is required',
  SENTINELONE_URL_VALIDATION = 'Sentinel One URL must be a valid URL',
  REQUIRED = 'This field is required',
  CostPerInfected_TYPEERROR = 'Cost Per Infected EndPoints must be a number',
  CostPerInfected_POSITIVE = 'Cost Per Infected EndPoints must be greater than zero',
  ANNUAL_REVENUE_TYPEERROR = 'Annual Revenue must be a number',
  ANNUAL_REVENUE_POSITIVE = 'Annual Revenue must be greater than zero',
}

export enum NotificationMessages {
  PASSWORD_UPDATE_SUCCESS = 'Password updated successfully',
  PROFILE_UPDATE_SUCCESS = 'Profile updated successfully',
  PASSWORD_SET_SUCCESS = 'Password set successfully',
  INSURANCE_FORM_SUCCESS = 'Insurance  form submitted successfully',
  SENTINEL_DELETE_SUCCESS = 'Sentinel One API Key deleted',
  SENTINEL_KEY_UPDATE = 'Sentinel key updated successfully',
  RESEND_LINK = 'Verification email sent successfully',
  COMMENT_DELETE = 'Comment deleted successfully',
  COMMENT_EDIT = 'Comment edited successfully',
  SUPPORT_FORM = 'Contact us form submitted sucessfully',
  INITIATE_CLAIM = 'Initiate claim successfully',
  REEVALUATE_CLAIM = 'Re-evaluate claim successfully',
  ACCEPT_CLAIM = 'Claim has been accepted',
  SENTINEL_KEY = 'Sentinel one connected successfully',
  GETAQUOTE = 'Make sure you save else you lose the data with in 10 secs',
  ScoreCard = 'Score Card Added Successfully',
  MANUAL_CLAIM = 'Your manual claim request is submitted successfully',
  QUOTE_SAVE = 'Information saved successfully',
  QUOTE_SUBMIT = 'Get A Quote form submitted successfully',
  CONNECTION_REQUEST = 'Connect Request sent successfully',
  USER_QUOTE_REGISTRATION = 'Your Submission is successfully saved',
  USER_QUOTE_SUPPORT = 'Thank you for your response. Someone from our support team will reach out to you for your query.',
  DISCOUNT = '% Discount Applied',
  RECORD_FOUND = 'Please continue to with rest of the registration information',
  NO_POLICY_EXIST = 'No active policy exist yet',
  REQ_FORM_ERROR = 'You have already saved some information please continue with that.',
  ADD_SERVICE = 'Service Added Successfully',
  COVE = 'Cove Connected Successfully',
  CHANGE_POLICY = 'Policy Changed Successfully',
  Patch_N_Sight = ' N Sight patch Connected Successfully',
  Service_Deactivated = 'Service Disconnected Successfully',
  Change_PaymentMethod = ' Card Details Changed Successfully',
  Manual_Payment = 'Payment Done Successfully',
}

export enum UserStatus {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  BLOCKED = 'Blocked',
}
export enum PolicyStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  CANCEL = 'Cancel Plan',
  Cancelled = 'Cancelled',
  PAYMENT_OVER_DUE = 'Payment Overdue',
}
export const keyEnum = {
  ENTER: 'Enter',
}
export enum ClaimStatus {
  ALL = 'All',
  APPLICABLE = 'Applicable',
  NOT_APPLICABLE = 'Not Applicable',
  CLAIM_RAISED = 'Claim Raised',
  APPROVED = 'Approved',
  DISAPPROVED = 'Disapproved',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  RE_EVALUATE = 'Re-Evaluate',
}
export enum OsNameEnum {
  MAC_OS = 'MAC Os',
  LINUX = 'Linux',
  WINDOWS = 'Windows',
}
export enum ServicesEnum {
  EDR = 'Sentinel One',
  COVE_DATA = 'Cove Data Protection',
  N_SIGHT_PATCH = 'N-Sight-Patch Managment',
  N_SIGHT_AV = 'N-Sight AV (Bitdefender)',
  N_CENTERAL = 'N-Central',
}
export enum ComingSoonServicesEnum {
  Crowd_Strike = 'Crowd Strike',
  Bitdefender_Gravity = 'BitDefender Gravity Zone',
  Malware_Bytes = 'Malware Bytes',
  Windows_Defender = 'Windows Defender',
}

export enum CommunicationMediumEnum {
  WHATSAPP = 'Whatsapp',
  EMAIL = 'Email',
  PHONENUMBER = 'Phone Number',
}

export enum TimeZone {
  MORNING = 'Morning (08:00 AM - 12:00 PM)',
  EVENING = 'Evening (05:00 PM - 08:00PM)',
  AFTERNOON = 'Afternoon (12:00 PM - 05:00 PM)',
  MORNING_VALUE = 'Morning',
  EVENING_VALUE = 'Evening',
  AFTERNOON_VALUE = 'Afternoon',
}

export enum ThreatMonitoringStartDate {
  ASAP = 'Asap',
  IN_MONTH = 'With in a month',
  TWO_TO_FOUR_MONTHS = '2 to 4 months',
  MORE_THAN_4_Months = 'More than 4 months in advance',
}

export enum ERROR_ENUM {
  EMAIL_VERIFIED = 'Your Account was successfully verified.',
  EMAIL_VERIFYING = 'Please wait while we verify your email address...',
}

export const RegPattern = {
  PASSWORD_VALIDATION:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,

  // eslint-disable-next-line no-useless-escape
  URL_VALIDATION: /^(https?|ftp):\/\/|www\.|[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(\/\S*)?$/,
  ADDRESS_VALIDATION: /^[a-zA-Z0-9 @.,!?()]{5,100}$/,
  PHONE_VALIDATION: /^\+[1-9]{1}[0-9]{10,12}$/,
  NO_WHITE_SPACE: /^\S*$/,
  WHITE_SPACE: /^\S/,
  LENGTH_VALIDATION: /^[a-zA-Z0-9 @.,!?()]{0,300}$/,
  FIRST_NAME_VALIDATION: /^(?=.{2,10}$)[A-Za-z]+(?:\s[A-Za-z]+)*\s?$/,
  LAST_NAME_VALIDATION: /^(?=.{2,10}$)[A-Za-z]+(?:\s[A-Za-z]+)*\s?$/,
  COMPANY_NAME_VALIDATION: /^(?=.{2,30}$)[A-Za-z]+(?:\s[A-Za-z]+)*\s?$/,
  SENTINELONE_URL: /^https:\/\/.*\.sentinelone\.net\/$/,
}

export const validTitle = {
  // eslint-disable-next-line
  pattern: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
  message: 'Title name at least have 5, but not more than 50 characters and will not accept only spaces',
}
export const defaultImage = {
  admin: '/images/img-placeholder.png',
  logo: '/images/default_Logo.png',
}

export const ApplicationEnum = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  PAYMENT_OVER_DUE: 'Payment Overdue',
}
export enum SentinelOneFormError {
  ERROR = 'OS need Protection ,atleast one should be selected',
}
export enum ServicesFormError {
  ERROR = ' Primary service must be selected.',
}

export enum ResendVerification {
  TEXT = 'Please enter the Email address associated with your account and we will send you an Email to verify your account',
  SUCCESS_TEXT = 'We have sent a verification Email on your account, please visit your Email account',
}
export enum Stages {
  STAGE1 = 'step1',
  STAGE2 = 'step2',
  STAGE3 = 'step3',
  STAGE4 = 'step4',
  STAGE5 = 'step5',
  STAGE6 = 'step6',
  STAGE7 = 'step7',
}

export enum QuoteStatement {
  STEP2 = 'Select more than one secondary service to get additional discount on premium.',
  NEW_SERVICE = 'A DLT Alert Specialist will review your request and get in touch to discuss options that best fit your companies requirements.',
}
export enum Add_ServiceStatement {
  ADD_SERVICE = 'Add More Then One Secondary Service For Additional Discount',
}
export enum NSightPatchEnum {
  STATUSLABEL = 'statusLabel',
  PATCHURL = 'patchUrl',
  SEVERITYLABEL = 'severityLabel',
}
export enum ModalAcceptText {
  TEXT = 'Initiate Claim',
  ACCEPTED_TEXT = 'Initiating Claim',
}
export enum ServicesAssessmentsEnum {
  EDR = 'Sentinel One',
  COVE_DATA = 'Cove Data Protection',
  N_SIGHT_PATCH = 'N-Sight-Patch Managment',
  N_SIGHT_AV = 'N-Sight AV (Bitdefender)',
  N_CENTERAL = 'N-Central',
  VEEM = 'Veeam',
}
export enum ApiErrorCode {
  CODE = 'ERR_NETWORK',
}

export enum LandingPageVideoLink {
  LINK = 'https://publicbucketdlt.s3.us-east-2.amazonaws.com/DLT+Cover+Video.mp4',
}
