import CSS from 'csstype'
import React, { Fragment, useEffect } from 'react'
import { ModalBlock, ModalBody, ModalDescription, ModalContainer, ModalFooter, ModalHeader, ModalOverlay, ModalTitle } from './style'
import { Button } from 'components/button'
import { ErrorBox, ModalbuttonWrapCss } from 'features/profile/components/EditProfileForm'
import { processAcceptText } from 'utils/function'
import { ModalAcceptText } from 'utils/enum'

type ModalProps = {
  title?: string
  footer?: React.ReactNode
  children?: React.ReactNode
  active?: boolean
  hideModal?: () => void
  onAccept?: () => void
  description?: string
  isLoading?: boolean
  style?: CSS.Properties
  hasError?: boolean
  apiErrorText?: string
  isForm?: boolean
  acceptText: string
  buttonAsAnchor?: boolean
  hrefValue?: string
  isHidden?: boolean
  isFooterHidden?: boolean
}

export const Modal = (props: ModalProps) => {
  const {
    isFooterHidden,
    isHidden,
    title,
    description,
    children,
    active,
    hideModal,
    onAccept,
    style,
    isLoading,
    hasError,
    apiErrorText,
    isForm,
    acceptText,
    buttonAsAnchor,
    hrefValue,
  } = props
  const doc: Document = document
  const body = doc.querySelector('body')
  useEffect(() => {
    active ? body?.classList.add('overflow--hidden--modal') : body?.classList.remove('overflow--hidden--modal')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])
  const cancelButtonText = buttonAsAnchor ? 'Ok' : 'Cancel'
  const acceptButton = buttonAsAnchor ? (
    <Button as="a" href={hrefValue} target="_blank">
      {acceptText}
    </Button>
  ) : (
    <Button type={isForm ? 'submit' : 'button'} disabled={isLoading} onClick={onAccept}>
      {isLoading ? (acceptText === ModalAcceptText.TEXT ? ModalAcceptText.ACCEPTED_TEXT : `${processAcceptText(acceptText)}ing...`) : `${acceptText}`}
    </Button>
  )

  return (
    <Fragment>
      {active && (
        <ModalBlock>
          <ModalOverlay></ModalOverlay>
          <ModalContainer style={style}>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              <ModalDescription>{description}</ModalDescription>
            </ModalHeader>
            <ModalBody>
              <>
                {children}
                {!!hasError && <ErrorBox>{apiErrorText}</ErrorBox>}
              </>
            </ModalBody>
            {!isFooterHidden && (
              <ModalFooter css={ModalbuttonWrapCss}>
                <>
                  {isHidden ? (
                    <>{acceptButton}</>
                  ) : (
                    <>
                      <Button bordered={true} type="button" onClick={hideModal}>
                        {cancelButtonText}
                      </Button>
                      {acceptButton}
                    </>
                  )}
                </>
              </ModalFooter>
            )}
          </ModalContainer>
        </ModalBlock>
      )}
    </Fragment>
  )
}
