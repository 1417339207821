import * as React from 'react'
import { SVGProps } from 'react'

const SvgPhishing = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#phishing_svg__a)">
      <path d="M34.207 30.567a6.437 6.437 0 1 0 0-12.875 6.437 6.437 0 0 0 0 12.875Z" fill="#B79313" />
      <path
        d="M47.294 22.205h-9.649c0-.155.015-.312.015-.467a8.998 8.998 0 0 0-5.443-8.266l-.425-.178a3.5 3.5 0 0 1-2.334-3.304V8.458a3.089 3.089 0 1 0-1.595 0v1.543a5.101 5.101 0 0 0 3.345 4.78l.382.158a7.403 7.403 0 0 1 4.472 6.799c0 .155-.02.312-.03.467h-14.74c0-.155-.024-.31-.024-.467v-2.917l2.602 1.861a.802.802 0 0 0 .922-1.312l-5.125-3.66v6.028c0 .157.017.312.023.467H9.873a2.132 2.132 0 0 0-2.126 2.126v23.76a2.132 2.132 0 0 0 2.126 2.125h37.42a2.133 2.133 0 0 0 2.127-2.126V24.331a2.135 2.135 0 0 0-2.126-2.126ZM27.169 5.469a1.493 1.493 0 1 1 .44 1.053 1.493 1.493 0 0 1-.437-1.053h-.003Zm10.418 30.31a.796.796 0 1 0-1.228 1.015l9.77 11.83H11.023l9.57-11.836a.797.797 0 0 0-.383-1.296.798.798 0 0 0-.86.293L9.354 48.15a.451.451 0 0 1 0-.06v-22.5l16.951 12.717a3.83 3.83 0 0 0 4.375 0l17.153-12.72V48.09c.002.02.002.041 0 .061L37.587 35.78ZM19.915 23.797a8.993 8.993 0 0 0 14.776 4.62.798.798 0 0 0-1.068-1.184 7.4 7.4 0 0 1-12.063-3.436h25.734c.08.002.16.024.23.064a.722.722 0 0 0-.175.094L29.706 37.042a2.246 2.246 0 0 1-2.456 0L9.82 23.958a.819.819 0 0 0-.178-.097c.07-.04.15-.062.23-.064h10.043Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="phishing_svg__a">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgPhishing
