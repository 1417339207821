import { SVGProps } from 'react'

const ScoreB = (props: SVGProps<SVGSVGElement>) => (
  <svg width="40" height="44" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_1_16)">
      <path d="M20 1.14127L39 11.5913V32.4087L20 42.8587L1 32.4087V11.5913L20 1.14127Z" stroke="#2F80ED" stroke-width="2" />
      <path
        d="M13.8349 31V13.5455H20.5167C21.7781 13.5455 22.8263 13.7443 23.6616 14.142C24.5025 14.5341 25.1303 15.071 25.5451 15.7528C25.9656 16.4347 26.1758 17.2074 26.1758 18.071C26.1758 18.7812 26.0394 19.3892 25.7667 19.8949C25.494 20.3949 25.1275 20.8011 24.6673 21.1136C24.207 21.4261 23.6928 21.6506 23.1246 21.7869V21.9574C23.744 21.9915 24.3377 22.1818 24.9059 22.5284C25.4798 22.8693 25.9485 23.3523 26.3121 23.9773C26.6758 24.6023 26.8576 25.358 26.8576 26.2443C26.8576 27.1477 26.6389 27.9602 26.2014 28.6818C25.7639 29.3977 25.1048 29.9631 24.2241 30.3778C23.3434 30.7926 22.2354 31 20.9002 31H13.8349ZM16.9968 28.358H20.3974C21.5451 28.358 22.3718 28.1392 22.8775 27.7017C23.3888 27.2585 23.6445 26.6903 23.6445 25.9972C23.6445 25.4801 23.5167 25.0142 23.261 24.5994C23.0053 24.179 22.6417 23.8494 22.1701 23.6108C21.6985 23.3665 21.136 23.2443 20.4826 23.2443H16.9968V28.358ZM16.9968 20.9688H20.1246C20.6701 20.9688 21.1616 20.8693 21.5991 20.6705C22.0366 20.4659 22.3803 20.179 22.6303 19.8097C22.886 19.4347 23.0138 18.9915 23.0138 18.4801C23.0138 17.804 22.7752 17.2472 22.2979 16.8097C21.8263 16.3722 21.1246 16.1534 20.1928 16.1534H16.9968V20.9688Z"
        fill="#2F80ED"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_16">
        <rect width="40" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ScoreB
