import { styled } from 'styles'
import { Box, Flex } from 'components/elements'
import { Card, CardBorder } from 'components/elements/Card'
import { Button } from 'components/button'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { TextField } from 'components/form'
import { NotificationMessages, ValidationErrors } from 'utils/enum'
import DescriptionField from '@mui/material/TextField'
import { StyledLabel, StyledDropdownWrap, StyledHelperText } from 'components/form/Textfield.styles'
import { validDescription, validIssue, validName } from 'utils/validation'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useSupportRequest } from './api/changePassword'
import SnackBar from 'components/SnackBar'

type InputFields = {
  name: string
  email: string
  coverage: string
  companyName: string
}

type SupportFormProps = {
  formPrefix?: string
}

const SupportForm = ({ formPrefix }: SupportFormProps) => {
  const defaultValues = {
    name: '',
    email: '',
    coverage: '',
    companyName: '',
  }
  const [submitError, setSubmitError] = useState('')
  const { mutate, isLoading } = useSupportRequest()
  const [notification, setNotification] = useState(false)
  const [commentDeleted, setCommentDeleted] = useState(false)

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<InputFields>({
    resolver: yupResolver(loginSchema),
    defaultValues,
  })

  const onSubmit = async (data: InputFields) => {
    setSubmitError('')

    const modifiedData = {
      ...data,
      companyName: `${formPrefix} - ${data.companyName}`,
    }
    mutate(modifiedData, {
      onSuccess: () => {
        setCommentDeleted(true)
        setNotification(true)
      },
      onError: error => {
        if (error instanceof AxiosError) {
          setSubmitError(error.response?.data.message)
        }
      },
    })
    reset()
  }

  return (
    <CardBorder outerCss={{ width: '100%' }}>
      <StyledTeamCard>
        {!!submitError && <Box css={{ color: '$error', mt: '$1' }}>{submitError}</Box>}
        <Flex as="form" css={NegativeMargin} direction="column" onSubmit={handleSubmit(onSubmit)}>
          <Flex css={FlexWrapCSS}>
            <Box css={wrapStyle}>
              <TextField label="Name" placeholder="Name" error={!!errors.name} {...register('name')} css={inputStyle} />
              <StyledHelperText>{errors.name?.message && errors.name?.message}</StyledHelperText>
            </Box>
            <Box css={wrapStyle}>
              <TextField label="Email" placeholder="Email" error={!!errors.email} {...register('email')} css={inputStyle} />
              <StyledHelperText>{errors.email?.message && errors.email?.message}</StyledHelperText>
            </Box>
          </Flex>
          <Flex css={FlexWrapCSS}>
            <Box css={wrapStyle}>
              <TextField label="Company Name" placeholder="Company Name" error={!!errors.companyName} {...register('companyName')} css={inputStyle} />
              <StyledHelperText>{errors.companyName?.message && errors.companyName?.message}</StyledHelperText>
            </Box>
          </Flex>
          <Flex css={FlexWrapCSS}>
            <StyledDropdownWrap className="fullWidth" css={wrapStyle}>
              <StyledLabel>What type of Coverage are you interested in</StyledLabel>
              <DescriptionField multiline rows={3} maxRows={3} className="main" {...register('coverage')} />
              <StyledHelperText>{errors.coverage?.message && errors.coverage?.message}</StyledHelperText>
            </StyledDropdownWrap>
          </Flex>
          <Flex css={{ px: 12, mt: '$5' }}>
            <Button css={{ flexGrow: 1 }} disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </Flex>
        </Flex>
      </StyledTeamCard>
      <SnackBar
        open={notification && commentDeleted}
        onClose={() => {
          setCommentDeleted(false)

          setNotification(false)
        }}
        text={NotificationMessages.SUPPORT_FORM}
        severity={'success'}
      />
    </CardBorder>
  )
}

export const NegativeMargin = {
  'mx': 0,

  '@sm': {
    mx: -12,
  },
}

export const FlexWrapCSS = {
  flexWrap: 'wrap',
  width: '100%',
}

export const StyledTeamCard = styled(Card, {
  'padding': '$6',

  '& .ip': {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '32px',
    color: '$black',
  },
  '& .score': {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    color: '$white',
    textTransform: 'uppercase',
    letterSpacing: '0.5em',
    marginBottom: '$5',
  },
  '& .score-percentage': {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '30px',
    color: '$white',
  },
  '& .date': {
    fontSize: '$sm',
    fontWeight: '$light',
    marginTop: 4,
  },
  '& .heading': {
    fontSize: '14px',
    fontWeight: '$normal',
  },
  '& .sub-heading': {
    fontSize: '$md',
    fontWeight: '$light',
  },
  '& .detail': {
    'fontSize': '16px',
    'fontWeight': '$bold',

    'wordBreak': 'break-word',
    '&.extended': {
      m: '0 $3 0 $5',
      lineHeight: '17px',
    },
  },
  '& .error': {
    fontSize: '$sm',
    fontWeight: '$medium',
    color: '$error',
    textAlign: 'left',
  },
  '@md': {
    '& .date': {
      mt: -20,
    },
    '& .ip': {
      fontSize: '32px',
    },
  },
})

export const inputStyle = {
  width: '100%',
}

export const wrapStyle = {
  'position': 'relative',
  'mb': '$5',
  'width': '100%',

  '@sm': {
    width: '50%',
    px: 12,
  },

  '&.fullWidth': {
    width: '100%',
  },

  '&.noPadding': {
    px: 0,
  },
}

const loginSchema = Yup.object().shape({
  name: Yup.string().required(ValidationErrors.NAME).matches(validName.pattern, validName.message),
  email: Yup.string().email(ValidationErrors.EMAIL_VALIDATION).required(ValidationErrors.EMAIL),
  coverage: Yup.string().matches(validDescription.pattern, validDescription.message).required(ValidationErrors.DESCRIPTION),
  companyName: Yup.string().required(ValidationErrors.COMPANY).matches(validIssue.pattern, validIssue.message),
})

export default SupportForm
