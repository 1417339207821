import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { StripeComponentProps } from 'features/quote/components/type'
import { StripPublicKey } from 'configs/constants'
export const stripePromise = loadStripe(StripPublicKey)

const StripeComponent: React.FC<StripeComponentProps> = ({ WrappedComponent }) => {
  return (
    <Elements stripe={stripePromise}>
      <WrappedComponent />
    </Elements>
  )
}

export default StripeComponent
