import { Box } from 'components/elements'
import { Dispatch, SetStateAction } from 'react'
import { styled } from 'styles'

export type HamburderProps = {
  toggleSidebar?: boolean
  setToggleSidebar?: Dispatch<SetStateAction<boolean>>
  sidebarToggle?: () => void
}

const Hamburder = ({ toggleSidebar, sidebarToggle }: HamburderProps) => {
  return (
    <StyledHamburger onClick={sidebarToggle} className={`${toggleSidebar ? 'is--open' : ''}`}>
      <Box as="span"></Box>
      <Box as="span"></Box>
      <Box as="span"></Box>
      <Box as="span"></Box>
      <Box as="span"></Box>
      <Box as="span"></Box>
    </StyledHamburger>
  )
}

const StyledHamburger = styled('div', {
  'width': 30,
  'height': 24,
  'position': 'relative',
  'transform': 'rotate(0deg)',
  'transition': '.5s ease-in-out',
  'cursor': 'pointer',
  'mr': '1rem',

  'span': {
    'display': 'block',
    'position': 'absolute',
    'height': 3,
    'width': '50%',
    'background': '$white',
    'opacity': 1,
    'transform': 'rotate(0deg)',
    'transition': '.25s ease-in-out',

    '&:nth-child(even)': {
      left: '50%',
      borderRadius: '0 9px 9px 0',
    },
    '&:nth-child(odd)': {
      left: 0,
      borderRadius: '9px 0 0 9px',
    },
    '&:nth-child(1), &:nth-child(2)': {
      top: 0,
    },
    '&:nth-child(3), &:nth-child(4)': {
      top: 10,
    },
    '&:nth-child(5), &:nth-child(6)': {
      top: 20,
    },
  },

  '&.is--open': {
    span: {
      '&:nth-child(1), &:nth-child(6)': {
        transform: 'rotate(45deg)',
      },
      '&:nth-child(2), &:nth-child(5)': {
        transform: 'rotate(-45deg)',
      },
      '&:nth-child(1)': {
        left: 2,
        top: 4,
      },
      '&:nth-child(2)': {
        left: 'calc(50% - 2px)',
        top: 4,
      },
      '&:nth-child(3)': {
        left: '-50%',
        opacity: 0,
      },
      '&:nth-child(4)': {
        left: '100%',
        opacity: 0,
      },
      '&:nth-child(5)': {
        left: 2,
        top: 16,
      },
      '&:nth-child(6)': {
        left: 'calc(50% - 2px)',
        top: 16,
      },
    },
  },

  '@md': {
    display: 'none',
  },
})

export default Hamburder
