import React from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert'
import { Box } from 'components/elements'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={16} ref={ref} variant="filled" {...props} />
})

type SnackBarDTO = {
  open: boolean
  text: string
  severity: AlertColor
  onClose: (status: boolean) => void
}

const SnackBar = ({ open, text, severity, onClose }: SnackBarDTO) => {
  const handleClose = () => {
    onClose(false)
  }

  return (
    <Snackbar
      style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert className={`${severity}`} severity={severity} onClose={handleClose} sx={{ width: '100%' }}>
        <Box as="h3" css={{ marginBottom: 8, fontSize: '$lg' }}>
          {severity}
        </Box>
        {text}
      </Alert>
    </Snackbar>
  )
}

export default SnackBar
