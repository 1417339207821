import { styled } from 'styles'

export const BgCircularPattern = styled('div', {
  'position': 'absolute',
  'zIndex': -1,
  'bgImage': '$bgPatternGradient',
  'size': 370,
  'filter': 'blur(65px)',
  '@ms': {
    size: 410,
  },
})

export const BgCircleImage = styled('div', {
  'position': 'relative',
  'margin': '0 auto 1rem',
  'size': 175,
  'zIndex': 1,

  '@sm': {
    position: 'absolute',
    top: '20%',
    left: '1rem',
    size: 120,
  },

  '@md': {
    top: '12%',
    left: '3%',
  },

  '@lg': {
    top: '15%',
    left: '5%',
    size: 175,
  },
})

export const BrochureImage = styled('div', {
  position: 'absolute',
  zIndex: -1,
  top: -9999,
  left: -9999,
})
