import { useQuery } from '@tanstack/react-query'
import { ApiKey } from 'features/incidents/components/enums'
import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'

export type PlanListingProps = {
  servicesIds?: string
  maxCoverage?: string
}

export const getPlanListingForQuote = async (params: PlanListingProps) => {
  return axios.get(endpoints.getPlanForQuote, { params })
}
export const useGetPlanListingForQuote = (params?: PlanListingProps) => {
  return useQuery([ApiKey.planListing, ApiKey.list, params], () => getPlanListingForQuote(params || {}))
}
