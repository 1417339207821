import GetAQuoteRegister from '../components/GetAQuoteRegister'

const GetAQuotes = () => {
  return <GetAQuoteRegister />
}
export const QouteLayout = {
  'backgroundImage': "url('/images/bg-pattern.png')",
  'pt': '4.25rem',
  'backgroundPosition': 'center',

  '@md': {
    pt: 0,
  },
}

export default GetAQuotes
