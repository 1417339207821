import Hamburder, { HamburderProps } from 'components/icons/hamburger/hamburger'
import { styled } from 'styles/stitches.config'
import Userbox from './Userbox'

const Header = ({ toggleSidebar, sidebarToggle }: HamburderProps) => {
  return (
    <HeaderWrapper>
      <Hamburder sidebarToggle={sidebarToggle} toggleSidebar={toggleSidebar} />
      <Userbox />
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled('div', {
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'space-between',
  'color': '$white',
  'zIndex': 10,
  'padding': '.5rem 1.5rem',
  'height': '$space$headerHeight',
  'backdropFilter': 'blur(3px)',
  'position': 'absolute',
  'top': 0,
  'left': 0,
  'right': 0,
  // adjusting userbox, so that we can get right dropdown position
  '& > .user-box-btn': {
    ml: 'auto',
    py: '$2',
  },
  '@md': {
    px: '2.5rem',
    left: '$sidebarWidth',
  },
})

export default Header
