import * as React from 'react'
import { SVGProps } from 'react'

const SvgDltAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#dlt-alert_svg__a)" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M21 11.666h-4.667a4.667 4.667 0 0 0-4.666 4.667v28A4.667 4.667 0 0 0 16.332 49h23.334a4.666 4.666 0 0 0 4.666-4.667v-28a4.667 4.667 0 0 0-4.666-4.666H35"
        stroke="#fff"
      />
      <path d="M30.333 7h-4.666a4.667 4.667 0 1 0 0 9.333h4.666a4.667 4.667 0 1 0 0-9.333Z" stroke="#fff" />
      <path d="m21 32.667 4.667 4.666L35 28" stroke="#B79313" />
    </g>
    <defs>
      <clipPath id="dlt-alert_svg__a">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgDltAlert
