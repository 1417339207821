import { Card, CardBorder } from 'components/elements/Card'
import { BgCircularPattern } from 'components/elements/BgPattern'
import { styled } from 'styles'
import { Box } from 'components/elements/Box'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, A11y } from 'swiper'
import useMediaQuery from 'hooks/use-media-query'
import { Section } from 'views/style'

const ourNetworks = [
  {
    id: 1,
    image: '/images/our-network-1.png',
    title: 'Blockchain Oracles for Hybrid Smart Contracts | Chainlink',
    description: 'Chainlink is the most widely used oracle network for powering hybrid smart contracts, enabling any blockchain to securely access off-ch...',
    link: 'https://chain.link/',
    linkText: 'chain.link',
  },
  {
    id: 2,
    image: '/images/our-network-6.png',
    title: 'Ensuro | Decentralized capital for insurance',
    description: 'Ensuro wants to be the first decentralized, fully licensed insurer, bringing the DeFi revolution one step ahead. Ensuro will allow everyone... ',
    link: 'https://ensuro.co/',
    linkText: 'ensuro.co',
  },
  {
    id: 3,
    image: '/images/our-network-3.png',
    title: 'SentinelOne | Autonomous AI Endpoint Security Platform | s1.ai',
    description: 'Endpoint security software that defends every endpoint against every type of attack, at every stage in the threat lifecycle',
    link: 'https://www.sentinelone.com/',
    linkText: 'sentinelone.com',
  },
  {
    id: 4,
    image: '/images/our-network-2.png',
    title: 'MISP Open Source Threat Intelligence Platform &amp; Open Standards For....',
    description: 'MISP Open Source Threat Intelligence Platform &amp; Open Standards For Threat Information Sharing - MISP Project',
    link: 'https://www.misp-project.org/',
    linkText: 'misp-project.org',
  },
  {
    id: 5,
    image: '/images/our-network-7.png',
    title: 'Polygon: Blazingly Fast, Low Cost, & Eco-Friendly | Dapps Platform',
    description: 'Institutions, Enterprises, and Governments. Polygon is the best verifiable platform for institutions, enterprises, and governm....',
    link: 'https://polygon.technology/',
    linkText: 'polygon.technology',
  },
  {
    id: 6,
    image: '/images/our-network-5.png',
    title: 'MSP + IT Management Software: RMM, Backup, Security - N-able',
    description: 'Software, resources, and tools for MSPs and IT departments with best-in-class Remote Monitoring & Management, Data Protectio...',
    link: 'http://www.n-able.com/',
    linkText: 'n-able.com',
  },
]

const OurNetwork = () => {
  const isSmallScreen = useMediaQuery(1200)

  return (
    <Section css={{ position: 'relative' }}>
      <BgCircularPattern css={{ left: '-7%', top: '20%' }} />

      <h2 className="section-title">Our Trusted Network</h2>
      {isSmallScreen ? (
        <SliderWrapper>
          <Swiper modules={[Pagination, A11y]} pagination={{ clickable: true }} spaceBetween={50} slidesPerView={1}>
            {ourNetworks.map(network => (
              <SwiperSlide key={network.id}>
                <NetworkCard {...network} />
              </SwiperSlide>
            ))}
          </Swiper>
        </SliderWrapper>
      ) : (
        <StyledWrapper>
          {ourNetworks.map(network => (
            <NetworkCard key={network.id} {...network} />
          ))}
        </StyledWrapper>
      )}
    </Section>
  )
}

type NetworkCardProps = {
  link: string
  linkText: string
  title: string
  description: string
  image: string
}

const NetworkCard = (props: NetworkCardProps) => {
  const { link, linkText, title, description, image } = props

  return (
    <Box as="a" css={{ display: 'flex' }} href={link} target="_blank">
      <CardBorder
        outerCss={{
          'maxWidth': 450,
          'm': '0 auto $5',
          '@xs': { mb: 0 },
          '@sm': { maxWidth: 'unset' },
        }}
      >
        <StyledFigure>
          <img src={image} alt={title} />
          <StyledFigCaption>
            <h3 className="heading">{title}</h3>
            <p className="desc-text">{description}</p>
            <span className="link">{linkText}</span>
          </StyledFigCaption>
        </StyledFigure>
      </CardBorder>
    </Box>
  )
}

const SliderWrapper = styled('div', {
  'maxWidth': 1400,
  'margin': '$6 auto 90px',
  '& .swiper': {
    pb: '$6',
  },
  '& .swiper-pagination-bullet': {
    size: 7,
    borderRadius: '$rounded',
    bgColor: '$gray11',
    opacity: 1,
  },
  '& .swiper-pagination-bullet-active': {
    bgColor: '$primary',
    width: 24,
    borderRadius: 3.5,
  },
  '& .swiper-slide > div': {
    mx: 'auto',
  },
})

const StyledWrapper = styled('div', {
  '@xs': {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '$5',
    maxWidth: 900,
    mx: 'auto',
  },
  '@md': {
    gridTemplateColumns: '1fr 1fr',
    maxWidth: 1300,
  },
  '@lg': {
    gap: '$6',
  },
})

const StyledFigure = styled('figure', Card, {
  'm': 0,
  'p': '$2',
  '& img': {
    objectFit: 'cover',
    borderRadius: '.75rem',
  },
  '@md': {
    'display': 'flex',
    'flexDirection': 'row',
    'gap': '$4',
    '& img': {
      maxWidth: '33%',
    },
  },
})

const StyledFigCaption = styled('figcaption', {
  '& .heading': {
    lineHeight: 1.4,
  },
  '&. desc-text': {
    color: '$gray5',
  },
  '& .link': {
    fontSize: '$xs',
    color: '$gray4',
    display: 'inline-block',
  },
})

export default OurNetwork
