import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'
import { useMutation } from '@tanstack/react-query'

export const insuranceRequest = (data: object) => {
  return axios.post(endpoints.addInsuranceRequests, data)
}

export const useInsuranceRequest = () => {
  return useMutation(insuranceRequest)
}
