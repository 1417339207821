import { AxiosError } from 'axios'
import { styled } from 'styles/stitches.config'
import { insuranceForm } from 'utils/insuranceForm'
import { Box, Card, CardBorder } from 'components/elements'
import { LeftArrowIcon, RightArrowIcon, QuestionBulletIcon, BackNavArrowIcon } from 'components/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, ControllerProps, useForm } from 'react-hook-form'
import { Button } from 'components/button'
import { Flex } from 'components/elements'
import { useNavigate } from 'react-router-dom'
import { useInsuranceRequest } from '../api/insuranceRequest'
import { errorMessage, getDefaultValuesInsuranceForm } from 'utils/function'
import SnackBar from 'components/SnackBar'
import { NotificationMessages } from 'utils/enum'
import { useLocation } from 'react-router-dom'
import { Listing, MenuDropdown } from 'components/menuDropdown'
import { conductCyberAttackTraining, experiencedCyberAttack, howManyEmployees, typesOfSensitiveData } from './Options'
import { routes } from 'configs/constants'
import { ReactQueryStatus } from 'features/incidents/components/enums'
import { inputStyle } from 'views/Support/SupportForm'
import { TextField } from 'components/form'
interface ResponseItem {
  [key: number | string]: string | number
  label: string
  value: string
}

export const InsuranceForm = () => {
  const location = useLocation()
  const { insuranceObj } = location.state || {}
  const [activeStep, setActiveStep] = useState(0)
  const [pageUpperLimit, setPageUpperLimit] = useState(8)
  const [pageLowerLimit, setPageLowerLimit] = useState(0)
  const [cancelForm, setCancelForm] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [notification, setNotification] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [isAnnualRevenuePos, setIsAnnualRevenuePos] = useState(true)
  const navigate = useNavigate()
  const { mutate, isSuccess, status } = useInsuranceRequest()

  const { control, handleSubmit, watch } = useForm({
    defaultValues: getDefaultValuesInsuranceForm(insuranceObj) as any, //TODO:need little restructuring will do that later to remove this any check
  })

  const formValues = watch()
  const questions = useMemo(() => makeInsurancePolicyQuestion(control, setIsAnnualRevenuePos), [control, setIsAnnualRevenuePos])

  useEffect(() => {
    const alldone = Object.values(formValues).every((item: any) => {
      if (typeof item === 'object' && item !== null) {
        return 'value' in item && item.value !== undefined && item.value !== null
      }
      return item !== undefined && item !== null // For items that are not objects (like q2)
    })
    // const alldone = Object.values(formValues).every(i => Boolean(i)) pervious flows
    setAllDone(alldone)
  }, [formValues])
  useEffect(() => {
    if (activeStep + 1 > pageUpperLimit) {
      pageIncrement()
    } else if (activeStep < pageLowerLimit) {
      pageDecrement()
    }
    // eslint-disable-next-line
  }, [activeStep])

  const totalQuestions = questions.length

  const incrementStep = useCallback(() => {
    setActiveStep(p => (p + 1 >= totalQuestions ? p : p + 1))
  }, [totalQuestions])

  const decrementStep = useCallback(() => {
    setActiveStep(p => (p - 1 < 0 ? 0 : p - 1))
  }, [])

  const pageIncrement = () => {
    if (totalQuestions > pageUpperLimit) {
      setActiveStep(pageUpperLimit)
      setPageUpperLimit(pageUpperLimit + 8)
      setPageLowerLimit(pageLowerLimit + 8)
    }
  }

  const pageDecrement = () => {
    if (pageLowerLimit > 0) {
      setActiveStep(pageLowerLimit - 1)
      setPageUpperLimit(pageUpperLimit - 8)
      setPageLowerLimit(pageLowerLimit - 8)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setNotification(true)
      setTimeout(() => {
        setNotification(false)
        if (allDone) navigate(routes.assessments)
      }, 2000)
    }
    // eslint-disable-next-line
  }, [status])

  const onSubmit = (response: any) => {
    const data: Record<string, string | number | ResponseItem> = {}

    Object.keys(response).forEach(item => {
      // for textfield
      if (item === 'q2') {
        data['annualRevenue'] = parseInt(response[item])
      } else {
        // picking the object base on item //like q1 q2
        const questionObj = insuranceForm[item as keyof typeof insuranceForm] // explicitly typing index expression\
        //picking the key over here
        const question = Object.keys(questionObj)[0]

        //possible answere of the question
        const answers = questionObj[question as keyof typeof questionObj]
        if (response[item] !== undefined) {
          data[question] = answers[response[item]] //picking correct ans
        }
        if (response[item]?.value !== undefined) {
          if (!isNaN(response[item]?.value)) {
            data[question] = answers[response[item]?.value]
          } else data[question] = response[item]?.label
        }
      }
    })
    mutate(data, {
      onError: error => {
        if (error instanceof AxiosError) {
          setSubmitError(errorMessage(error))
        }
      },
    })
  }

  const handleCancel = () => {
    setCancelForm(true)
    setTimeout(() => {
      navigate(routes.login)
    }, 10000)
  }
  return (
    <StyledContainer>
      <CardBorder
        outerCss={{
          'maxWidth': 920,
          'width': '100%',
          'height': '100vh',

          '@sm': {
            height: 'auto',
          },
        }}
      >
        <Card css={{ p: '2.5rem', height: '100%', justifyContent: 'center' }}>
          <Flex justify="between" css={{ mb: 0 }}>
            <Flex align="center" css={{ gap: '$2' }}>
              <BackNavArrowIcon fontSize={24} style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
              <Box as="h3" css={{ mb: 0 }}>
                Insurance Form
              </Box>
            </Flex>
          </Flex>
          <StepsBar>
            {/* <IconButton onClick={pageDecrement}>
              <ArrowBackIosNewIcon />
            </IconButton> */}
            {questions.map((_, i) => {
              if (i >= pageLowerLimit && i < pageUpperLimit) {
                return (
                  <QStep
                    key={i}
                    num={i + 1}
                    onClick={() => isAnnualRevenuePos && setActiveStep(i)}
                    isLast={i + 1 === totalQuestions || i + 1 === pageUpperLimit}
                    isActive={i === activeStep}
                    done={formValues[`q${i + 1}`]}
                    isAnnualRevenuePos={isAnnualRevenuePos}
                  />
                )
              } else return <></>
            })}
            {/* <IconButton onClick={pageIncrement}>
              <ArrowForwardIosIcon onClick={pageIncrement} />
            </IconButton> */}
          </StepsBar>

          {!!submitError && <ErrorBox css={{ margin: '1rem auto 0px' }}>{submitError}</ErrorBox>}
          {!isAnnualRevenuePos && <ErrorBox css={{ margin: '1rem auto 0px' }}>Annual Renvenue should be positive number</ErrorBox>}

          <Form onSubmit={handleSubmit(onSubmit)}>
            {questions.map((question, idx) => (
              <fieldset key={idx} hidden={idx !== activeStep}>
                <legend>
                  <QNumber>
                    {idx + 1} <QuestionBulletIcon />
                  </QNumber>
                  {question.text}
                </legend>
                {question.input}
              </fieldset>
            ))}

            {!allDone && (
              <ButtonGroup>
                <button type="button" className="btn btn-left" onClick={decrementStep} disabled={!isAnnualRevenuePos}>
                  <LeftArrowIcon fontSize="18px" />
                </button>

                <button type="button" className="btn btn-right" onClick={incrementStep} disabled={!isAnnualRevenuePos}>
                  <RightArrowIcon fontSize="18px" />
                </button>
              </ButtonGroup>
            )}

            <Flex
              css={{
                justifyContent: 'center',
                mb: '2rem',
                gap: '20px',
                mt: '2rem',
                flexWrap: 'wrap',
              }}
            >
              <Button bordered={true} onClick={handleCancel} type="button">
                Cancel
              </Button>
              <Button type="submit" disabled={status === ReactQueryStatus.LOADING}>
                {allDone ? (isSuccess ? 'Submiting' : 'Submit') : status === ReactQueryStatus.LOADING ? 'Saving' : 'Save'}
              </Button>
            </Flex>
          </Form>
        </Card>
      </CardBorder>

      <SnackBar
        open={notification && isSuccess}
        onClose={() => setNotification(false)}
        text={allDone ? NotificationMessages.INSURANCE_FORM_SUCCESS : NotificationMessages.QUOTE_SAVE}
        severity={'success'}
      />

      <SnackBar open={cancelForm} onClose={() => setCancelForm(false)} text={NotificationMessages.GETAQUOTE} severity={'success'} />
    </StyledContainer>
  )
}

function makeInsurancePolicyQuestion(control: ControllerProps['control'], setIsAnnualRevenuePos: (value: boolean) => void) {
  return [
    // dealing with box questionsaire only
    {
      text: <>What types of sensitive data does your business handle, and how is it stored and protected?</>,
      input: (
        <Controller
          name="q1"
          control={control}
          render={({ field }) => (
            <MenuDropdown
              {...field}
              placeholder="Select..."
              value={field.value}
              list={typesOfSensitiveData}
              setValue={(e: { label: string; value: string }) => {
                field.onChange({
                  label: e.label,
                  value: e.value,
                })
              }}
            />
          )}
        />
      ),
    },
    {
      text: <>What are the annual revenue projections for this year?</>,
      input: (
        <Controller
          name="q2"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              max="9999999"
              min="0"
              type="number"
              label="Annual Revenue($)"
              placeholder="Enter your Annual Revenue($)"
              css={inputStyle}
              onChange={e => {
                const { value } = e.target

                if (value === '') {
                  setIsAnnualRevenuePos(true)
                  field.onChange(null)
                } else {
                  const numericValue = parseInt(value, 10)
                  if (numericValue >= 0) {
                    setIsAnnualRevenuePos(true)
                    field.onChange(numericValue)
                  } else {
                    setIsAnnualRevenuePos(false)
                  }
                }
              }}
            />
          )}
        />
      ),
    },
    {
      text: <>Have you experienced any cyber-attacks or data breaches in the past, and if so, what was the nature and impact of those incidents?</>,
      input: (
        <Controller
          name="q3"
          control={control}
          render={({ field }) => (
            <MenuDropdown
              {...field}
              placeholder="Select..."
              value={field.value}
              list={experiencedCyberAttack}
              setValue={(e: { label: string; value: string }) => {
                field.onChange({
                  label: e.label,
                  value: e.value,
                })
              }}
            />
          )}
        />
      ),
    },
    {
      text: <>How often do you conduct cybersecurity training and awareness programs for your employees?</>,
      input: (
        <Controller
          name="q4"
          control={control}
          render={({ field }) => (
            <MenuDropdown
              {...field}
              placeholder="Select..."
              value={field.value}
              list={conductCyberAttackTraining}
              setValue={(e: { label: string; value: string }) => {
                field.onChange({
                  label: e.label,
                  value: e.value,
                })
              }}
            />
          )}
        />
      ),
    },
    {
      text: <>How many employees do you have?</>,
      input: (
        <Controller
          name="q5"
          control={control}
          render={({ field }) => (
            <MenuDropdown
              {...field}
              placeholder="Select..."
              value={field.value}
              list={howManyEmployees}
              setValue={(e: { label: string; value: string }) => {
                field.onChange({
                  label: e.label,
                  value: e.value,
                })
              }}
            />
          )}
        />
      ),
    },
    // TODO:Might need it later
    // {
    //   text: <>What types of sensitive data does your business handle, and how is it stored and protected? ?</>,
    //   input: (
    //     <Controller
    //       name="q1"
    //       control={control}
    //       render={({ field }) => (
    //         <MenuDropdown
    //           {...field}
    //           placeholder="Select..."
    //           value={field.value}
    //           list={optionsBackups}
    //           setValue={(e: { label: string; value: string }) => {
    //             field.onChange({
    //               label: e.label,
    //               value: e.value,
    //             })
    //           }}
    //         />
    //       )}
    //     />
    //   ),
    // },
    // {
    //   text: 'What are the annual revenue projections for this year?',
    //   input: (
    //     <Controller
    //       name="q2"
    //       control={control}
    //       render={({ field }) => (
    //         <MenuDropdown
    //           {...field}
    //           placeholder="Select..."
    //           value={field.value}
    //           list={totalServersCompanyHave}
    //           setValue={(e: { label: string; value: string }) => {
    //             field.onChange({
    //               label: e.label,
    //               value: e.value,
    //             })
    //           }}
    //         />
    //       )}
    //     />
    //   ),
    // },
    // {
    //   text: <>How many total endpoints are currently in use companywide?</>,
    //   input: (
    //     <Controller
    //       name="q3"
    //       control={control}
    //       render={({ field }) => (
    //         <MenuDropdown
    //           {...field}
    //           placeholder="Select..."
    //           value={field.value}
    //           list={totalEndPointsCompanyHave}
    //           setValue={(e: { label: string; value: string }) => {
    //             field.onChange({
    //               label: e.label,
    //               value: e.value,
    //             })
    //           }}
    //         />
    //       )}
    //     />
    //   ),
    // },
    // {
    //   text: 'Does the organisation perform timely vulnerability and software patching?',
    //   input: <ControlledRadio control={control} name="q4" />,
    // },
    // {
    //   text: <>Are there measures implemented to protect against malicious attachment types in email security solutions?</>,
    //   input: <ControlledRadio control={control} name="q5" />,
    // },
    // {
    //   text: <>Is any Personally Identifiable Information (PII) related data stored in an encrypted state?</>,
    //   input: <ControlledRadio control={control} name="q6" />,
    // },
    // {
    //   text: <>Do employees undergo information security awareness training that covers the new and emerging cyber-attacks?</>,
    //   input: (
    //     <Controller
    //       name="q7"
    //       control={control}
    //       render={({ field }) => (
    //         <MenuDropdown
    //           {...field}
    //           placeholder="Select..."
    //           value={field.value}
    //           list={SecurityTraining}
    //           setValue={(e: { label: string; value: string }) => {
    //             field.onChange({
    //               label: e.label,
    //               value: e.value,
    //             })
    //           }}
    //         />
    //       )}
    //     />
    //   ),
    // },
    // {
    //   text: <>Has the organization implemented a secondary confirmation process to verify the authenticity of finance-related requests?</>,
    //   input: <ControlledRadio control={control} name="q8" />,
    // },
    // {
    //   text: 'Is the network segmented to isolate critical systems, functions, or resources?',
    //   input: <ControlledRadio control={control} name="q9" />,
    // },
    // {
    //   text: <>Does the organization restrict only authorized users to install or run privileged software applications?</>,
    //   input: (
    //     <Controller
    //       name="q10"
    //       control={control}
    //       render={({ field }) => (
    //         <MenuDropdown
    //           {...field}
    //           placeholder="Select..."
    //           value={field.value}
    //           list={authorizedUsers}
    //           setValue={(e: { label: string; value: string }) => {
    //             field.onChange({
    //               label: e.label,
    //               value: e.value,
    //             })
    //           }}
    //         />
    //       )}
    //     />
    //   ),
    // },
    // {
    //   text: <>Does the organization ensure that the password policy is implemented for all systems and applications?</>,
    //   input: (
    //     <Controller
    //       name="q11"
    //       control={control}
    //       render={({ field }) => (
    //         <MenuDropdown
    //           {...field}
    //           placeholder="Select..."
    //           value={field.value}
    //           list={passwordPolicy}
    //           setValue={(e: { label: string; value: string }) => {
    //             field.onChange({
    //               label: e.label,
    //               value: e.value,
    //             })
    //           }}
    //         />
    //       )}
    //     />
    //   ),
    // },
    // {
    //   text: <>Is multifactor authentication implemented for privileged and non-privileged accounts?</>,
    //   input: (
    //     <Controller
    //       name="q12"
    //       control={control}
    //       render={({ field }) => (
    //         <MenuDropdown
    //           {...field}
    //           placeholder="Select..."
    //           value={field.value}
    //           list={multiFactor}
    //           setValue={(e: { label: string; value: string }) => {
    //             field.onChange({
    //               label: e.label,
    //               value: e.value,
    //             })
    //           }}
    //         />
    //       )}
    //     />
    //   ),
    // },
    // {
    //   text: <>Are publicly accessible critical infrastructure systems protected by perimeter security devices?</>,
    //   input: <ControlledRadio control={control} name="q13" />,
    // },
  ]
}

// const ControlledRadio = (props: { control: ControllerProps['control']; name: string }) => {
//   const { control, name } = props

//   return (
//     <Controller
//       control={control}
//       name={name}
//       render={({ field }) => (
//         <RadioGroup value={field.value} onValueChange={field.onChange}>
//           <Radio value="true">Yes</Radio>
//           <Radio value="false">No</Radio>
//         </RadioGroup>
//       )}
//     />
//   )
// }

type QStepProps = {
  num: number
  done?: Listing | string
  onClick?: () => void
  isLast?: boolean
  isActive?: boolean
  isAnnualRevenuePos?: boolean
}
const QStep = ({ num, done, isLast = false, onClick, isActive, isAnnualRevenuePos }: QStepProps) => {
  const object = (typeof done === 'string' ? done : done?.value) || (typeof done === 'number' ? done : null)
  return (
    <>
      <CardBorder outerClassName={`step ${isActive ? 'active' : ''}`} innerCss={{ borderRadius: 'inherit' }}>
        <Card
          css={{
            dflex: 'center',
            borderRadius: 'inherit',
            bgColor: '$gray10',
            bgImage: 'none',
            size: '100%',
            fontWeight: '$bold',
            cursor: isAnnualRevenuePos ? 'pointer' : 'default',
          }}
          as="button"
          onClick={onClick}
        >
          {object ? <Box as="img" css={{ maxWidth: 'initial', width: '120%' }} src="/images/step-check.png" alt="step-done" /> : num}
        </Card>
      </CardBorder>
      {!isLast && <div className="seperator" />}
    </>
  )
}

const StyledContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
})

const StepsBar = styled('div', {
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'flexWrap': 'wrap',
  'marginTop': '1rem',
  '.step': {
    'borderRadius': '50%',
    'fontWeight': '$bold',
    'width': 32,
    'height': 32,
    '&.active': {
      background: '$yellow1',
    },
  },
  '.seperator': {
    height: 2,
    width: 50,
    marginInline: 4,
    borderRadius: 2,
    background: '$gradientYellowReverse',
  },
})

const Form = styled('form', {
  'display': 'flex',
  'flexDirection': 'column',
  'position': 'relative',
  'maxWidth': 600,
  'width': '100%',
  'paddingLeft': '2.5rem',
  'margin': '2rem auto 0',
  'minHeight': '30vh',

  '@sm': {
    margin: '4rem auto 0',
  },
  'fieldset': {
    border: 'none',
    padding: '0 0 2rem',
  },
  'legend': {
    padding: 0,
    fontWeight: '$semibold',
    lineHeight: 1.5,
    marginBottom: '2rem',
  },
  '.select-container': {
    maxWidth: 320,
    margin: 0,
  },
})

const QNumber = styled('span', {
  position: 'absolute',
  left: 0,
  top: 2,
  display: 'inline-flex',
  alignItems: 'center',
  color: '$primaryLight',
  fontSize: '0.875rem',
  svg: {
    width: 11,
    height: 9,
    marginLeft: 3,
  },
})

const ButtonGroup = styled('div', {
  'width': '100%',
  'marginTop': 'auto',

  '.btn': {
    'display': 'inline-flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'width': '40px',
    'height': '40px',
    'border': 'none',
    'cursor': 'pointer',
    'bgColor': '$primary',

    '&:disabled': {
      opacity: 0.5,
    },

    '&:hover': {
      bgColor: '$primaryLight',
    },
  },
  '.btn-left': {
    borderRadius: '8px 0 0 8px',
    borderRight: '1px solid $white',
  },
  '.btn-right': {
    borderRadius: '0 8px 8px 0',
  },
})

const ErrorBox = styled('div', {
  gridColumn: 'span 2',
  gridRow: 'span 3',
  margin: 0,
  color: '$error',
})
