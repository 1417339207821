import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/button'
import { Box, Flex } from 'components/elements'
import { TextField } from 'components/form'
import FormWrapper from 'features/auth/components/FormWrapper'
import { useForm } from 'react-hook-form'
import { Stages, ValidationErrors } from 'utils/enum'
import { ALreadyLoginDTO, QuoteProps } from '../type'
import * as Yup from 'yup'
import { ModalbuttonWrapCss } from 'features/profile/components/EditProfileForm'
import { useEffect, useState } from 'react'
import { errorMessage, getUpperCase } from 'utils/function'
import { useGetUserQouteInfo } from '../../api/getQuoteUserInfo'
import { AxiosError } from 'axios'
import { userRegHeadingCss } from 'views/style'
import { QouteLayout } from 'features/quote/page/GetAQuotes'

const QuoteFormRegistered = (props: QuoteProps) => {
  const { setQuoteUserReg, setStage } = props
  const [submitError, setSubmitError] = useState('')
  const [userEmail, setUserEmail] = useState<string | null>(null)
  const {
    data: qouteData,
    error,
    isFetched,
  } = useGetUserQouteInfo({
    email: userEmail,
  })
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ALreadyLoginDTO>({
    resolver: yupResolver(scheme),
  })
  const onSubmit = async (data: ALreadyLoginDTO) => {
    setUserEmail(data?.email)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (qouteData?.data && isFetched) {
      setSubmitError('')

      const userDetails = qouteData?.data?.user
      const userServices = qouteData?.data?.userPlan?.services
      const primaryService = qouteData?.data?.userPlan?.primaryServiceId
      //service is secondary service and it should not include the primary services
      const service =
        userServices && userServices.filter((item: { serviceId: number }) => item.serviceId !== primaryService?.id).map((item: { serviceId: number }) => item.serviceId)
      const obj = {
        users: userDetails,
        service,
        coverage: qouteData?.data?.userPlan?.maxCoverage,
        others: qouteData?.data?.userPlan?.others,
        planId: qouteData?.data?.userPlan?.planId,
        status: qouteData?.data?.userPlan?.status,
        primaryService: primaryService,
      }
      setQuoteUserReg && setQuoteUserReg(obj)
      setStage && setStage(Stages.STAGE1)
    } else {
      if (error instanceof AxiosError) {
        setSubmitError(errorMessage(error))
      }
    }
  }, [error, isFetched, qouteData, setQuoteUserReg, setStage])

  return (
    <>
      <Flex align="start" justify="center" css={QouteLayout}>
        <FormWrapper style={{ textAlign: 'left' }}>
          <Flex as="h2" justify={'center'} css={userRegHeadingCss}>
            Get A Quote
          </Flex>
          <Flex as="form" direction="column" css={{ gap: '$4' }} onSubmit={handleSubmit(onSubmit)}>
            {!!submitError && (
              <Box as="p" css={{ color: '$error' }}>
                {getUpperCase(submitError)}
              </Box>
            )}
            <TextField placeholder="Email" label="Email" error={!!errors.email} helperText={errors.email?.message} {...register('email')} />

            <Flex css={ModalbuttonWrapCss}>
              <Button bordered={true} type="button" onClick={() => props?.setStage && props?.setStage(Stages.STAGE1)}>
                Cancel
              </Button>
              <Button type="submit">Next</Button>
            </Flex>
          </Flex>
        </FormWrapper>
      </Flex>
    </>
  )
}
const scheme = Yup.object().shape({
  email: Yup.string().email(ValidationErrors.EMAIL_VALIDATION).required(ValidationErrors.EMAIL),
})

export default QuoteFormRegistered
