import * as React from 'react'
import { SVGProps } from 'react'
const SvgLeftArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.83 8.796a1.125 1.125 0 0 1 0-1.591L7.58.455a1.125 1.125 0 1 1 1.59 1.59L3.217 8l5.955 5.954a1.125 1.125 0 0 1-1.591 1.591L.83 8.796Z"
      fill="#fff"
    />
  </svg>
)
export default SvgLeftArrowIcon
