import * as React from 'react'
import { SVGProps } from 'react'

const SvgLinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 9c1.591 0 3.117.584 4.243 1.622C20.368 11.661 21 13.07 21 14.54V21h-4v-6.461c0-.49-.21-.96-.586-1.306a2.088 2.088 0 0 0-1.414-.54c-.53 0-1.04.194-1.414.54A1.777 1.777 0 0 0 13 14.539V21H9v-6.461c0-1.47.632-2.878 1.757-3.917C11.883 9.584 13.41 9 15 9ZM6 9H3v12h3V9ZM4.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      fill="#fff"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgLinkedinIcon
