import { SVGProps } from 'react'

const ScoreC = (props: SVGProps<SVGSVGElement>) => (
  <svg width="40" height="44" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_1_21)">
      <path d="M20 1.14127L39 11.5913V32.4087L20 42.8587L1 32.4087V11.5913L20 1.14127Z" stroke="#E2B93B" stroke-width="2" />
      <path
        d="M27.744 19.4347H24.5565C24.4656 18.9119 24.2979 18.4489 24.0536 18.0455C23.8093 17.6364 23.5053 17.2898 23.1417 17.0057C22.7781 16.7216 22.3633 16.5085 21.8974 16.3665C21.4371 16.2188 20.94 16.1449 20.4059 16.1449C19.457 16.1449 18.6161 16.3835 17.8832 16.8608C17.1502 17.3324 16.5763 18.0256 16.1616 18.9403C15.7468 19.8494 15.5394 20.9602 15.5394 22.2727C15.5394 23.608 15.7468 24.733 16.1616 25.6477C16.582 26.5568 17.1559 27.2443 17.8832 27.7102C18.6161 28.1705 19.4542 28.4006 20.3974 28.4006C20.9201 28.4006 21.4087 28.3324 21.8633 28.196C22.3235 28.054 22.7354 27.8466 23.0991 27.5739C23.4684 27.3011 23.7781 26.9659 24.0281 26.5682C24.2837 26.1705 24.4599 25.7159 24.5565 25.2045L27.744 25.2216C27.6246 26.0511 27.3661 26.8295 26.9684 27.5568C26.5763 28.2841 26.0621 28.9261 25.4258 29.483C24.7894 30.0341 24.0451 30.4659 23.1928 30.7784C22.3406 31.0852 21.3945 31.2386 20.3548 31.2386C18.8207 31.2386 17.4513 30.8835 16.2468 30.1733C15.0423 29.4631 14.0934 28.4375 13.4002 27.0966C12.707 25.7557 12.3604 24.1477 12.3604 22.2727C12.3604 20.392 12.7099 18.7841 13.4087 17.4489C14.1076 16.108 15.0593 15.0824 16.2638 14.3722C17.4684 13.6619 18.832 13.3068 20.3548 13.3068C21.3263 13.3068 22.2298 13.4432 23.065 13.7159C23.9002 13.9886 24.6445 14.3892 25.2979 14.9176C25.9513 15.4403 26.4883 16.0824 26.9087 16.8438C27.3349 17.5994 27.6133 18.4631 27.744 19.4347Z"
        fill="#E2B93B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_21">
        <rect width="40" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ScoreC
