import { axios } from './axios'
import storage from 'utils/storage'
import { LoginResponse, User } from 'features/auth/types'

export type SentinelKeyDTO = {
  sentinelOneApiKey?: string
}

export type RegisterDTO = {
  firstName: string
  lastName: string
  email: string
  websiteUrl?: string
  companyWebsite?: string
  companyName?: string
  phoneNum: string
  password: string
  confirmPassword: string
}

export async function register(data: RegisterDTO) {
  return axios.post('/user/register', data)
}

export type LoginCredentialsDTO = {
  email: string
  password: string
}

export async function loginWithEmailAndPassword(data: LoginCredentialsDTO): Promise<User> {
  const resp = await axios.post<LoginResponse>('/user/login', data)
  storage.setToken(resp.data.token)
  return resp.data.user
}

export async function logout() {
  storage.clearToken()
}

export async function getUserIdentity(): Promise<User> {
  let user = null
  if (storage.getToken()) {
    const resp = await axios.get('/user/profile')
    user = resp.data
  }
  return user
}

type ResetPasswordDTO = {
  jwt: string
  password: string
  confirmPassword: string
}

export function resetPassword(data: ResetPasswordDTO): Promise<any> {
  return axios.post('/user/reset/password', data)
}

type UpdatePasswordDTO = {
  oldPassword: string
  password: string
  confirmPassword: string
}

export function updatePassword(data: UpdatePasswordDTO): Promise<any> {
  return axios.post('/user/change/password', data)
}
