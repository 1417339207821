import { AmexIcon, DinersIcon, DiscoverIcon, EloIcon, JCBIcon, MasterIcon, VisaIcon } from 'components/icons'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { styled } from 'styles'
import { StripeLabel } from 'features/incidents/components/style'
import { ErrorBox } from 'features/profile/components/EditProfileForm'
import { getUpperCase } from 'utils/function'
import { Button } from 'components/button'
import { Box, Flex } from 'components/elements'
import { useNavigate } from 'react-router'
import { routes } from 'configs/constants'
import { StripeInputProps } from 'features/quote/components/type'
import { StripeCardType, StripePaymentStatus } from 'features/incidents/components/enums'
import { StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement } from '@stripe/stripe-js'
import { buttonCss } from 'features/quote/components/GetAQuoteProcess/CoveragePlan'

export type StripeInputElement =
  // stripe comp type merged with custom type
  | (StripeCardNumberElement & {
      _implementation?: {
        _empty: boolean
      }
    })
  | (StripeCardExpiryElement & {
      _implementation?: {
        _empty: boolean
      }
    })
  | (StripeCardCvcElement & {
      _implementation?: {
        _empty: boolean
      }
    })
  | null
export const renderCardIcon = (cardType: string) => {
  switch (cardType) {
    case StripeCardType.VISA:
      return <VisaIcon />
    case StripeCardType.MASTER:
      return <MasterIcon />
    case StripeCardType.AMEX:
      return <AmexIcon />
    case StripeCardType.DISCOVER:
      return <DiscoverIcon />
    case StripeCardType.JCB:
      return <JCBIcon />
    case StripeCardType.DINERS:
      return <DinersIcon />
    case StripeCardType.ELO:
      return <EloIcon />

    default:
      return null
  }
}
export const customInputStyle = {
  base: {
    'fontSize': '16px',
    'fontWeight': '700',
    'lineHeight': '24px',
    'color': 'white',
    '::placeholder': {
      color: '#A1A1A1',
    },
  },

  invalid: {
    color: '#FF0303',
  },
}
const isElementEmpty = (element: StripeInputElement | null) => {
  return element?._implementation?._empty
}
const StripePayment = (props: StripeInputProps) => {
  const { onSubmit, isLoading, IsOpenInModal, handleCancel, isChangedCard } = props
  const stripe = useStripe()
  const elements = useElements()
  const [cardType, setCardType] = useState<string | null>('visa')
  const [submitError, setSubmitError] = useState('')

  //custom error handling
  const [isCardNumberError, setIsCardNumberError] = useState(false)
  const [isCardExpiryError, setIsCardExpiryError] = useState(false)
  const [isCardCVCError, setIsCardCVSError] = useState(false)
  const navigate = useNavigate()
  const handleCardNumberChange = (event: { brand: string | null }) => {
    setCardType(event?.brand)
  }

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (!stripe || !elements) {
      setSubmitError('Stripe.js or Elements is not available')
      return
    }
    // Ensure CardNumberElement, CardExpiryElement, and CardCvcElement are mounted
    const cardNumberElement = elements.getElement(CardNumberElement)
    const cardExpiryElement = elements.getElement(CardExpiryElement)
    const cardCvcElement = elements.getElement(CardCvcElement)
    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      setSubmitError('One or more card elements are not mounted')
      return
    }

    //custom error handling
    const cardNumberError = isElementEmpty(cardNumberElement) ? true : false
    const cardExpiryError = isElementEmpty(cardExpiryElement) ? true : false
    const cardCvcError = isElementEmpty(cardCvcElement) ? true : false
    setIsCardNumberError(cardNumberError)
    setIsCardCVSError(cardCvcError)
    setIsCardExpiryError(cardExpiryError)
    // Set the overall error message based on the first non-empty error

    if (cardNumberError || cardExpiryError || cardCvcError) {
      setSubmitError('Please provide the complete information')
      return
    } else {
      setSubmitError('')
    }
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    })

    if (error && error.message) {
      setSubmitError(error.message)
    } else {
      // Explicitly type paymentMethod to avoid 'never' type
      const typedPaymentMethod = paymentMethod as { id?: string }

      if (typedPaymentMethod && typedPaymentMethod.id) {
        onSubmit(typedPaymentMethod.id)
      } else {
        // paymentMethod is undefined or doesn't have an 'id' property
        setSubmitError('Invalid payment method')
      }
    }
  }

  //custom UI error handling is the feild is empty
  const handleCardNumberBlur = () => {
    if (!stripe || !elements) {
      setSubmitError('Stripe.js or Elements is not available')
      return
    }
    const cardNumberElement = elements.getElement(CardNumberElement)
    if (cardNumberElement) {
      const cardNumberError = isElementEmpty(cardNumberElement) ? true : false
      setIsCardNumberError(cardNumberError)
    }
  }

  const handleCardNumberFocus = () => {
    setIsCardNumberError(false)
  }
  const handleCardExpiryBlur = () => {
    const cardExpiryElement = elements?.getElement(CardExpiryElement)

    if (cardExpiryElement) {
      const cardExpiryError = isElementEmpty(cardExpiryElement) ? true : false
      setIsCardExpiryError(cardExpiryError)
    }
  }

  const handleCardExpiryFocus = () => {
    setIsCardExpiryError(false)
  }

  const handleCardCvcBlur = () => {
    const cardCvcElement = elements?.getElement(CardCvcElement)
    if (cardCvcElement) {
      const cardCvcError = isElementEmpty(cardCvcElement) ? true : false
      setIsCardCVSError(cardCvcError)
    }
  }

  const handleCardCvcFocus = () => {
    setIsCardCVSError(false)
  }
  return (
    <>
      <StripeBox>
        {!!submitError && <ErrorBox css={{ textAlign: 'left', mb: '$7' }}>{getUpperCase(submitError)}</ErrorBox>}

        <StripeLabel className="Label">Card Number</StripeLabel>
        <Flex className={`CardBorder${isCardNumberError ? ' bg-error' : ' bg-succes'}`}>
          <Flex align={'center'} css={{ m: '0 $2 ' }}>
            {cardType ? renderCardIcon(cardType) : null}
          </Flex>
          <CardNumberElement
            className="Card"
            options={{ style: customInputStyle, placeholder: '4242 4242 4242 4242' }}
            onChange={handleCardNumberChange}
            onBlur={handleCardNumberBlur}
            onFocus={handleCardNumberFocus}
          />
        </Flex>
      </StripeBox>
      <StripeBox>
        <StripeLabel className="Label">Expiry Date</StripeLabel>
        <CardExpiryElement
          className={` Card CardBorder${isCardExpiryError ? ' bg-error' : ' bg-succes'}`}
          options={{ style: customInputStyle }}
          onBlur={handleCardExpiryBlur}
          onFocus={handleCardExpiryFocus}
        />
      </StripeBox>
      <StripeBox>
        <StripeLabel className="Label">CVC</StripeLabel>
        <CardCvcElement
          className={` Card CardBorder${isCardCVCError ? ' bg-error' : ' bg-succes'}`}
          options={{ style: customInputStyle }}
          onBlur={handleCardCvcBlur}
          onFocus={handleCardCvcFocus}
        />
        {IsOpenInModal ? (
          <Flex justify={'center'} wrap={'wrap'} css={{ gap: 28, mt: '$5' }}>
            <Button size="sm" bordered={true} type="button" onClick={handleCancel}>
              Cancel
            </Button>
            {isChangedCard ? (
              <Button size="sm" type="button" onClick={handleSubmit} disabled={!stripe || isLoading}>
                {isLoading ? StripePaymentStatus.CHANGING : StripePaymentStatus.CHANGE}
              </Button>
            ) : (
              <Button size="sm" type="button" onClick={handleSubmit} disabled={!stripe || isLoading}>
                {isLoading ? StripePaymentStatus.PAYING : StripePaymentStatus.PAY}
              </Button>
            )}
          </Flex>
        ) : (
          <Box css={{ ...buttonCss, mt: '$5' }}>
            <Button bordered={true} type="button" onClick={() => navigate(routes.login)}>
              Cancel
            </Button>
            <Button type="button" onClick={handleSubmit} disabled={!stripe || isLoading}>
              {isLoading ? StripePaymentStatus.PAYING : StripePaymentStatus.PAY}
            </Button>
          </Box>
        )}
      </StripeBox>
    </>
  )
}
export const StripeBox = styled('div', {
  '.Card': {
    padding: '0.75rem 1rem',
    width: '100%',
  },
  '.CardBorder': {
    'background': '$white1A',
    'borderRadius': '4px',
    '&.bg-succes': {
      border: '1px solid $border',
    },
    '&.bg-error': {
      border: '1px solid $error',
    },
  },
  '.Label': {
    marginBottom: '$2',
  },
})

export default StripePayment
