import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'
import { useMutation } from '@tanstack/react-query'

type ConnectDTO = {
  email: string
}
export const addConnectionRequest = async (data: ConnectDTO) => {
  return axios.post(endpoints.connectRequest, data)
}
export const useAddConnectionRequest = () => {
  return useMutation(addConnectionRequest)
}
