import * as React from 'react'
import { SVGProps } from 'react'

const SvgPanickedTeam = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.547 47.263a11.078 11.078 0 1 1 22.155 0m-2.493-7.35a9.38 9.38 0 0 1 15.041 7.48m-32.209-7.48A9.381 9.381 0 0 0 4 47.392m29.006-23.405c0 2.966-2.415 8.137-5.381 8.137s-5.381-5.18-5.381-8.155a5.382 5.382 0 0 1 10.754 0l.008.018Zm13.423 3.56c0 2.512-2.039 7-4.55 7-2.511 0-4.55-4.523-4.55-7a4.554 4.554 0 1 1 9.108 0h-.008Zm-37.608 0c0 2.512 2.039 7 4.55 7 2.511 0 4.55-4.523 4.55-7a4.554 4.554 0 1 0-9.108 0h.008Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.625 12.76 39.405 8M13.625 12.76 17.405 8M41.505 12.76l-4.92 6.22 4.92-6.22ZM19.505 12.76l-4.92 6.22 4.92-6.22ZM35.625 12.76h5.88M13.625 12.76h5.88"
      stroke="#B79313"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgPanickedTeam
