export const optionsBackups = [
  {
    label: 'Yes, backups are offsite, but remain untested.',
    value: 1,
  },
  {
    label: 'Yes, backups are offsite and tested at minimal once a year.',
    value: 2,
  },
  {
    label: 'No, backups are only locally stored.',
    value: 3,
  },
  {
    label: 'No, we do not have backups for any business-critical machines.',
    value: 4,
  },
]

export const businessPlan = [
  {
    label: 'Yes, fully developed and tested.',
    value: 1,
  },
  {
    label: 'We have a BCP updated but not tested.',
    value: 2,
  },
  {
    label: 'We have a BCP but not updated or tested.',
    value: 3,
  },
  {
    label: 'We do not have a BCP.',
    value: 4,
  },
]
export const totalServersCompanyHave = [
  {
    label: '<5',
    value: 1,
  },
  {
    label: '<10',
    value: 2,
  },
  {
    label: '<25',
    value: 3,
  },
  {
    label: '>25',
    value: 4,
  },
]
export const totalEndPointsCompanyHave = [
  {
    label: '<25',
    value: 1,
  },
  {
    label: '<100',
    value: 2,
  },
  {
    label: '<200',
    value: 3,
  },
  {
    label: '>200',
    value: 4,
  },
]
export const inventoryHardware = [
  {
    label: 'Asset inventory is maintained for all assets.',
    value: 1,
  },
  {
    label: 'Asset inventory is updated once a year.',
    value: 2,
  },
  {
    label: 'Asset inventory was taken upon first deployment but has not been updated.',
    value: 3,
  },
  {
    label: 'We do not track asset inventory.',
    value: 4,
  },
]

export const SecurityTraining = [
  {
    label: 'Security training is provided at onboarding but not ongoing.',
    value: 1,
  },
  {
    label: 'Security training is provided yearly to all employees.',
    value: 2,
  },
  {
    label: 'We do not require security training.',
    value: 3,
  },
]

export const authorizedUsers = [
  {
    label: 'Only authorized users can install and run privileged software.',
    value: 1,
  },
  {
    label: 'Any user can install software but cannot run privileged software.',
    value: 2,
  },
  {
    label: 'Any user can run privileged software but not install programs.',
    value: 3,
  },
  {
    label: 'No any user can install and run privileged software.',
    value: 4,
  },
]

export const passwordPolicy = [
  {
    label: 'Password policy is defined and implemented via group policy.',
    value: 1,
  },
  {
    label: 'Password policy is defined and recommended but not enforced.',
    value: 2,
  },
  {
    label: 'Password policy is undefined but strong passwords are recommended.',
    value: 3,
  },
  {
    label: 'Password policy is undefined, and no recommendations are given.',
    value: 4,
  },
]

export const multiFactor = [
  {
    label: 'Multifactor is enabled for both privileged and non-privileged accounts.',
    value: 1,
  },
  {
    label: 'Multifactor is enabled for admins but not non-privileged accounts.',
    value: 2,
  },
  {
    label: 'Multifactor is available but not configured.',
    value: 3,
  },
  {
    label: 'Multifactor is NOT available or configured.',
    value: 4,
  },
]

export const vulnerability = [
  {
    label: 'Vulnerability and security assessments are conducted every other week.',
    value: 1,
  },
  {
    label: 'Assessments are conducted once a month.',
    value: 2,
  },
  {
    label: 'Assessments are conducted yearly.',
    value: 3,
  },
  {
    label: 'Assessments are conducted upon deployment and not again.',
    value: 4,
  },
  {
    label: 'Security and vulnerability assessments do not occur.',
    value: 5,
  },
]

export const cloudServices = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 2,
  },
  {
    label: 'We do not have Cloud Services (Not applicable).',
    value: 3,
  },
]

export const cloudSecurity = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 2,
  },
  {
    label: 'We do not use cloud Services (Not applicable).',
    value: 3,
  },
]
export const typesOfSensitiveData = [
  {
    label: 'No compliance and no backups are running.',
    value: 1,
  },
  {
    label: 'No compliance but we backup workstations.',
    value: 2,
  },
  {
    label: 'Some compliance but we do not backup.',
    value: 3,
  },
  {
    label: 'High compliance levels and we backup. ',
    value: 4,
  },
  {
    label: 'Unsure ',
    value: 5,
  },
]
export const experiencedCyberAttack = [
  {
    label: 'No, we have not been impacted by a cyber threat and have not actively attempted to prevent one.',
    value: 1,
  },
  {
    label: 'No, we have not been impacted by a cyber threat and we employ a solution to keep it that way. ',
    value: 2,
  },
  {
    label: 'Yes, we were hit by a targeted malware that took us offline.',
    value: 3,
  },
  {
    label: 'Yes, we were impacted by ransomware that came via a bad email.',
    value: 4,
  },
  {
    label: 'Yes, we were hit by a DDoS',
    value: 5,
  },
  {
    label: 'Yes, we have been hit with multiple types of threats.',
    value: 6,
  },
]
export const annualRevenue = [
  {
    label: '<$1mm',
    value: 1,
  },
  {
    label: '<$5mm',
    value: 2,
  },
  {
    label: '<$20mm',
    value: 3,
  },
  {
    label: '<$50mm',
    value: 4,
  },
  {
    label: '<$100mm ',
    value: 5,
  },
  {
    label: 'More than $100mm',
    value: 6,
  },
]
export const conductCyberAttackTraining = [
  {
    label: 'We do not give cyber training but have a list of policy guidelines.',
    value: 1,
  },
  {
    label: 'We do not have official cyber training, but we do train people internally on what to do and not to do online.',
    value: 2,
  },
  {
    label: 'We do offer cyber training to employees when they are initially onboarded, but not as a continuing education.',
    value: 3,
  },
  {
    label: 'We ensure all employees have cyber training once a year.',
    value: 4,
  },
]
export const howManyEmployees = [
  {
    label: '1-25',
    value: 1,
  },
  {
    label: '25-50',
    value: 2,
  },
  {
    label: '51-100',
    value: 3,
  },
  {
    label: '101-250',
    value: 4,
  },
  {
    label: '251-500',
    value: 5,
  },
  {
    label: '501-1000',
    value: 6,
  },
  {
    label: '1000+',
    value: 7,
  },
]
