import { useQuery } from '@tanstack/react-query'
import { ApiKey } from 'features/incidents/components/enums'
import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'

export type MerchantAffiliateProps = {
  affiliateId: string
}

export const getMerchantAffiliateDetails = async (params: MerchantAffiliateProps) => {
  return axios.get(endpoints.merchantAffiliateDetails, { params })
}
export const useGetMerchantAffiliateDetails = (params: MerchantAffiliateProps) => {
  return useQuery([ApiKey.merchantAffiliateDetails, ApiKey.details, params], () => getMerchantAffiliateDetails(params))
}
