import { TextField } from 'components/form'
import { Box, Flex } from 'components/elements'
import { Button } from 'components/button'
import { FlexWrapCSS, inputStyle, NegativeMargin, wrapStyle } from 'views/Support/SupportForm'
import { ErrorBox, FormBtnWrap, ModalbuttonWrapCss } from 'features/profile/components/EditProfileForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, FormControl, Tooltip } from '@mui/material'
import { StyledDropdownWrap, StyledLabel } from 'components/form/Textfield.styles'
import {  useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { PHONE_VALIDATION, ValidationErrors, RegPattern, Stages } from 'utils/enum'
import * as Yup from 'yup'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { routes } from 'configs/constants'
import { useNavigate } from 'react-router-dom'
import { Form1Props, QuoteDTO } from './type'
import FormWrapper from 'features/auth/components/FormWrapper'
import { userRegHeadingCss } from 'views/style'
import { errorMessage, getUpperCase } from 'utils/function'
import { BackNavArrowIcon } from 'components/icons'
import { useSignupByQuote } from '../api/signupByQuote'
import { AxiosError } from 'axios'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { ToolTipcss } from 'features/incidents/components/style'
import { QouteLayout } from '../page/GetAQuotes'

export const GetAQuoteForm = (props: Form1Props) => {
  const { quoteUserReg, setQuoteUserReg, setStage, apiError } = props
  const user = quoteUserReg?.users
  const [submitError, setSubmitError] = useState('')
  const [phoneNum, setPhoneNumber] = useState<string>(user?.phoneNum || '')
  const [hasError, setHasError] = useState('')
  const navigate = useNavigate()
  const { mutate, isLoading } = useSignupByQuote()

 
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<QuoteDTO>({
    resolver: yupResolver(getAQoute),
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      companyName: user?.companyName || '',
      phoneNum: user?.phoneNum || '',
      merchantAffiliateId: user?.merchantAffiliateId,
    },
  })
 

  const onSubmit: SubmitHandler<QuoteDTO> = async data => {
    if (hasError !== '') return
    setSubmitError('')

    const services = quoteUserReg?.service
    //box carrier is paid ,  skip Payment
    const newObj = {
      ...quoteUserReg,
      ...data,
      services,
    }
    data.merchantAffiliateId
    setQuoteUserReg?.({
      ...quoteUserReg,
      users: {
        ...data,
        merchantAffiliateId: data.merchantAffiliateId === '' ? null : data.merchantAffiliateId,
      },
      status: true,
    })

    delete newObj.service
    delete newObj.users
    if (newObj.merchantAffiliateId === '') {
      newObj.merchantAffiliateId = null
    }
    mutate(newObj, {
      onSuccess: () => {
     
          setStage?.(Stages.STAGE5)
      },
      onError: error => {
        if (error instanceof AxiosError) {
          setSubmitError(errorMessage(error))
        }
      },
    })
  }

  const PhoneNumber = (newValue: string | undefined) => {
    if (newValue !== undefined) {
      setPhoneNumber(newValue)
      setValue('phoneNum', newValue, { shouldValidate: true })
      if (isValidPhoneNumber(newValue)) setHasError('')
      else {
        setHasError(PHONE_VALIDATION.PHONEERROR)
      }
    }
  }

  return (
    <>
      <Flex justify="center" css={{ ...QouteLayout, m: '$4 0' }}>
        <FormWrapper controlWidth={{ maxWidth: 700 }} style={{ textAlign: 'left' }}>
          
          <Flex align="center">
              <BackNavArrowIcon fontSize={24} style={{ cursor: 'pointer' }} onClick={() => props?.setStage && props.setStage(Stages.STAGE3)} />
            <Flex as="h2" justify={'center'} css={userRegHeadingCss}>
              Get A Quote
            </Flex>
          </Flex>
          <Flex as="form" css={NegativeMargin} direction="column" onSubmit={handleSubmit(onSubmit)}>
            {!!submitError && <ErrorBox css={{ textAlign: 'left', mb: '$3' }}>{getUpperCase(submitError)}</ErrorBox>}
            {!!apiError && <ErrorBox css={{ textAlign: 'left', mb: '$3' }}>{getUpperCase(apiError)}</ErrorBox>}

            <Flex css={FlexWrapCSS}>
              <Box css={wrapStyle}>
                <TextField
                  label="First Name"
                  placeholder="First Name"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  {...register('firstName')}
                  css={inputStyle}
                />
              </Box>
              <Box css={wrapStyle}>
                <TextField label="Last Name" placeholder="Last Name" error={!!errors.lastName} helperText={errors.lastName?.message} {...register('lastName')} css={inputStyle} />
              </Box>
            </Flex>
            <Flex css={FlexWrapCSS}>
              <Box css={wrapStyle}>
                <TextField label="Email" placeholder="Email" error={!!errors.email} helperText={errors.email?.message} {...register('email')} css={inputStyle} />
              </Box>
              <Box css={wrapStyle}>
                <TextField
                  label="Company Name"
                  placeholder="Company Name"
                  error={!!errors.companyName}
                  helperText={errors.companyName?.message}
                  {...register('companyName')}
                  css={inputStyle}
                />
              </Box>
            </Flex>
            <Flex css={FlexWrapCSS}>
              <Box css={wrapStyle}>
                <StyledDropdownWrap style={{ margin: 0, maxWidth: '520px' }} className="phoneNumber">
                  <FormControl fullWidth className={`phoneNumber ${errors.phoneNum && 'phoneNumberError'}`}>
                    <StyledLabel>Phone Number</StyledLabel>
                    <PhoneInput
                      international
                      defaultCountry="US"
                      countryCallingCodeEditable={false}
                      placeholder="Phone number"
                      value={phoneNum}
                      onChange={newValue => {
                        PhoneNumber(newValue)
                      }}
                    />
                  </FormControl>
                  <Box as="p" className="errorText">
                    {hasError}
                  </Box>
                  {!!errors.phoneNum && (
                    <Box as="p" className="errorText">
                      {errors.phoneNum?.message}
                    </Box>
                  )}
                </StyledDropdownWrap>
              </Box>
              <Box css={wrapStyle}>
                <Flex wrap={'wrap'} css={{ columnGap: '10px' }}>
                  <StyledLabel>Carrier AffiliateId</StyledLabel>
                  <Tooltip
                    PopperProps={{ sx: ToolTipcss }}
                    title={
                      <Box as="span" css={{ color: '$black' }}>
                        This is the affiliate ID for reference of the carrier through which you came to know of DLT.
                      </Box>
                    }
                    placement="bottom"
                    enterTouchDelay={0}
                  >
                    <Box>
                      <HelpOutlineIcon />
                    </Box>
                  </Tooltip>
                </Flex>

                <TextField
                  placeholder="Affiliate Id"
                  error={!!errors.merchantAffiliateId}
                  helperText={errors.merchantAffiliateId?.message}
                  {...register('merchantAffiliateId')}
                  css={inputStyle}
                />
              </Box>
            </Flex>

            <Flex css={{ ...ModalbuttonWrapCss, ...FormBtnWrap }}>
              <Button bordered={true} type="button" onClick={() => navigate(routes.login)} size="lg">
                Cancel
              </Button>
            
                <Button type="submit" size="lg" disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <CircularProgress size={19} sx={{ color: 'var(--colors-white)' }} />
                    </>
                  ) : (
                    'Next'
                  )}
                </Button>
             
            </Flex>
          </Flex>
        </FormWrapper>
      </Flex>
    </>
  )
}
export const getAQoute = Yup.object().shape({
  firstName: Yup.string()
    .required(ValidationErrors.FIRST_NAME)
    .matches(RegPattern.NO_WHITE_SPACE, ValidationErrors.NO_WHITE_SPACE)
    .matches(RegPattern.FIRST_NAME_VALIDATION, ValidationErrors.FIRST_NAME_VALIDATION),

  lastName: Yup.string()
    .required(ValidationErrors.LAST_NAME)
    .matches(RegPattern.NO_WHITE_SPACE, ValidationErrors.NO_WHITE_SPACE)
    .matches(RegPattern.LAST_NAME_VALIDATION, ValidationErrors.LAST_NAME_VALIDATION),
  email: Yup.string().email(ValidationErrors.EMAIL_VALIDATION).required(ValidationErrors.EMAIL),
  companyName: Yup.string()
    .required(ValidationErrors.COMPANY_NAME)
    .matches(RegPattern.WHITE_SPACE, ValidationErrors.WHITE_SPACE)
    .matches(RegPattern.COMPANY_NAME_VALIDATION, ValidationErrors.COMPANY_NAME_VALIDATION),
  phoneNum: Yup.string().required(ValidationErrors.PHONE),
  merchantAffiliateId: Yup.string().nullable(),
})
