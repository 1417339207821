import { styled } from 'styles'

interface Props {
  question?: string
  onChange?: (newValue: boolean) => void
}

export const Checkbox = ({ question, onChange }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    if (onChange) onChange(newValue)
  }
  return (
    <>
      <StyledCheckbox type="checkbox" onChange={handleChange} />
      <h4 className="detail extended">{question}</h4>
    </>
  )
}

const StyledCheckbox = styled('input', {})
