import type * as Stitches from '@stitches/react'

const grayDark = {
  gray1: '#333333',
  gray2: '#4F4F4F',
  gray3: '#828282',
  gray4: '#BDBDBD',
  gray5: '#E0E0E0',
  gray6: '#434554',
  gray7: '#7B7979',
  gray8: '#524F4F',
  gray9: '#303742',
  gray10: '#3d3f4e',
  gray11: '#d9d9d9',
  gray12: '#5B5B5B',
  gray13: '#0000001A',
}
const blueDark = {
  blue1: '#2F80ED',
  blue2: '#32385b50',
  blue3: '#4a4e6a',
  blue4: '#94A3B8',
  blue5: '#32385b',
  blue6: '#1F2545',
  blue33: '#2F80ED33',
}
const greenDark = {
  green: '#27AE60',
  green1: '#52C41A',
  green33: '#27AE6033',
}
const yellowDark = {
  yellow: '#E2B93B',
  yellow1: '#f9c50d',
  yellow33: '#E2B93B33',
}
const redDark = {
  red: '#EB5757',
  red33: '#EB575733',
}
const purpleDark = {
  purple: '#7b2cbf',
}
const brownDark = {
  brown: '#6c3f3f',
}

export const defaultTokens = {
  colors: {
    // generic colors
    white: '#ffffff',
    white5: '#FFFFFF0D',
    white10: '#ffffff10',
    white1A: '#ffffff1a',
    white66: '#ffffff66',
    white77: '#F7F8F9',
    black: '#04000A',
    black00: '00000000',
    black33: '#00000033',
    black40: '#00000040',
    black39: '#2D2D39',
    black80: '#00000080',
    background: '#0D1023',
    foreground: '$white',
    //semantic colors
    ...grayDark,
    ...blueDark,
    ...greenDark,
    ...redDark,
    ...yellowDark,
    ...purpleDark,
    ...brownDark,
    // brand colors
    primaryLight: '#E9D16B',
    primary: '#B79313',
    primary10: '#B7931310',
    primaryMain: '#FCCA00',
    primaryBorder: '#FF9E00',
    primaryHover: '#917205',
    error: '#FF0D0D',
    success500: '#10B981',
    // misc
    text: '$white',
    textSubtle: '$gray4',
    textDim: '$gray5',
    link: '$blue700',
    border: '#F1F5F9',
    border1: 'rgba(255, 255, 255, 0.70)',
    beigeBackGround: ' rgba(235, 87, 87, 0.20)',

    linearGradientBlue: '153.32deg, rgba(255, 255, 255, 0.3) -65.62%, rgba(255, 255, 255, 0.1) 83.28%',
    linearGradientGray: `90deg, rgba(217, 217, 217, 0.1) 0%, rgba(217, 217, 217, 0) 100%`,
    linearGradientYellow: `153.32deg, $primary -65.62%, rgba(233, 209, 107, 0.1) 83.28%`,
    cardGradient: 'linear-gradient($linearGradientBlue)',
    gradientYellow: `linear-gradient(300.96deg, $primary 18.75%, rgba(183, 147, 19, 0.3) 81.54%)`,
    gradientBlue: 'linear-gradient(90deg, rgba(42, 38, 75, 0) 1.34%, #58C3FF 100%)',
    gradientYellowReverse: 'linear-gradient(296.15deg, $primary 16.47%, rgba(183, 147, 19, 0.3) 53.84%)',
    gradientGray: 'linear-gradient($linearGradientGray)',
    borderGradient: `linear-gradient(
        142.08deg, 
        rgba(255, 255, 255, 0.7) -5.03%, 
        rgba(188, 188, 188, 0.85) 21.3%, 
        rgba(13, 16, 35, 0.2) 49.04%, 
        rgba(187, 187, 187, 0.5) 70.19%)`,
    multiGradient: `$linearGradientYellow, $borderGradient`,
    aboutGradient: `linear-gradient(90.18deg, rgba(50, 56, 91, 0.57) 0%, rgba(26, 31, 65, 0.57) 100%)`,
    chipGradient: `linear-gradient(90.18deg, rgba(50, 56, 91, 1) 0%, rgba(26, 31, 65, 1) 100%)`,
    downloadGradient: `linear-gradient( 90.18deg, $background 0%, $background 15%)`,
    offcanvasGradient: `linear-gradient(
        90.18deg, 
        #32385B 0%, #1A1F41 100%)`,
    bgPatternGradient: `radial-gradient(
        30.8% 30.8% at 57.79% 54.71%, 
        rgba(43, 24, 103, 0.2) 0%, 
        rgba(56, 35, 131, 0.2) 48.71%, 
        rgba(69, 40, 150, 0.2) 78.16%, 
        rgba(118, 36, 142, 0.2) 100%)`,
    bgPatternGradient1: `radial-gradient(41.68% 41.68% at 57.79% 54.71%, rgba(43, 24, 103, 0.20) 0%, rgba(56, 35, 131, 0.20) 48.71%, rgba(69, 40, 150, 0.20) 78.16%, rgba(118, 36, 142, 0.20) 100%)`,
  },
  fonts: {
    grotesk: 'Space Grotesk',
    inter: 'Inter',
    lexend: "'Lexend Deca', sans-serif",
  },
  fontSizes: {
    'xs': '0.75rem',
    'sm': '0.875rem',
    'base': '1rem',
    'md': '1.15rem',
    'lg': '1.25rem',
    'xl': '1.5rem',
    '2xl': '2rem',
    '3xl': '2.25rem',
    '4xl': '3rem',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {},
  letterSpacings: {},
  radii: {
    sm: '4px',
    md: '8px',
    base: '12px',
    lg: '14px',
    xl: '18px',
    squared: '33%',
    rounded: '50%',
    pill: '50%',
  },
  space: {
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '24px',
    6: '32px',
    7: '38px',
    8: '48px',
    10: '64px',
    12: '80px',
    13: '100px',
    14: '120px',
    headerHeight: '61px',
    sidebarWidth: '300px',
  },
  sizes: {
    1: '4px',
    2: '8px',
    3: '12px',
  },
  shadows: {
    md: '0px 4px 24px -1px $black33',
  },
}

export const defaultUtils = {
  bg: (value: Stitches.PropertyValue<'backgroundColor'>) => ({
    background: value,
  }),
  bgImage: (value: Stitches.PropertyValue<'backgroundColor'>) => ({
    backgroundImage: value,
  }),
  bgColor: (value: Stitches.PropertyValue<'backgroundColor'>) => ({
    backgroundColor: value,
  }),

  dflex: (value: Stitches.PropertyValue<'alignItems'>) => ({
    display: 'flex',
    alignItems: value,
    justifyContent: value,
  }),

  p: (value: Stitches.PropertyValue<'padding'>) => ({
    padding: value,
  }),
  pt: (value: Stitches.PropertyValue<'paddingTop'>) => ({
    paddingTop: value,
  }),
  pr: (value: Stitches.PropertyValue<'paddingRight'>) => ({
    paddingRight: value,
  }),
  pb: (value: Stitches.PropertyValue<'paddingBottom'>) => ({
    paddingBottom: value,
  }),
  pl: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
    paddingLeft: value,
  }),
  px: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
    paddingLeft: value,
    paddingRight: value,
  }),
  py: (value: Stitches.PropertyValue<'paddingTop'>) => ({
    paddingTop: value,
    paddingBottom: value,
  }),

  m: (value: Stitches.PropertyValue<'margin'>) => ({
    margin: value,
  }),
  mt: (value: Stitches.PropertyValue<'marginTop'>) => ({
    marginTop: value,
  }),
  mr: (value: Stitches.PropertyValue<'marginRight'>) => ({
    marginRight: value,
  }),
  mb: (value: Stitches.PropertyValue<'marginBottom'>) => ({
    marginBottom: value,
  }),
  ml: (value: Stitches.PropertyValue<'marginLeft'>) => ({
    marginLeft: value,
  }),
  mx: (value: Stitches.PropertyValue<'marginLeft'>) => ({
    marginLeft: value,
    marginRight: value,
  }),
  my: (value: Stitches.PropertyValue<'marginTop'>) => ({
    marginTop: value,
    marginBottom: value,
  }),

  size: (value: Stitches.PropertyValue<'width'>) => ({
    width: value,
    height: value,
  }),

  linearGradient: (value: Stitches.PropertyValue<'backgroundImage'>) => ({
    backgroundImage: `linear-gradient(${value})`,
  }),
  textGradient: (value: Stitches.PropertyValue<'backgroundImage'>) => ({
    'backgroundImage': `linear-gradient(${value})`,
    'WebkitBackgroundClip': 'text',
    'WebkitTextFillColor': 'transparent',
    '&::selection': {
      WebkitTextFillColor: '$colors$text',
    },
  }),
}

const commonTheme = {
  theme: defaultTokens,
  media: {
    xs: '(min-width: 475px)',
    sm: '(min-width: 600px)',
    bssm: '(min-width: 767px)',
    md: '(min-width: 991px)',
    lg: '(min-width: 1199px)',
    xl: '(min-width: 1399px)',
    motion: '(prefers-reduced-motion)',
    hover: '(any-hover: hover)',
    dark: '(prefers-color-scheme: dark)',
    light: '(prefers-color-scheme: light)',
  },
  utils: defaultUtils,
}

export default commonTheme
