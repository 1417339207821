import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiKey } from 'features/incidents/components/enums'
import { axios } from 'lib/axios'
import { endpoints } from 'utils/endpoints'

export const addCoveActivation = (data: object) => {
  return axios.post(endpoints.activateCoveActivation, data)
}

export const useAddCoveActivation = () => {
  const queryClient = useQueryClient()
  return useMutation(addCoveActivation, {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiKey.policies, ApiKey.list])
    },
  })
}
