import * as React from 'react'
import { SVGProps } from 'react'

const SvgOffice = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#office_svg__a)" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path d="M7 49h42" stroke="#fff" />
      <path d="M21 18.666h2.333M21 28h2.333M21 37.334h2.333M32.666 18.666H35M32.666 28H35M32.666 37.334H35" stroke="#B79313" />
      <path d="M11.666 49V11.667A4.667 4.667 0 0 1 16.334 7h23.334a4.666 4.666 0 0 1 4.666 4.667V49" stroke="#fff" />
    </g>
    <defs>
      <clipPath id="office_svg__a">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgOffice
