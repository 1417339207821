import { Link, useLocation } from 'react-router-dom'
import { styled } from '../styles/stitches.config'
import { Box } from './elements/Box'
import { CloseIcon, MenuIcon } from './icons'
import { Flex } from './elements/Flex'
import { useCallback, useState } from 'react'
import { IconButton } from './button'
import { routes } from 'configs/constants'
import useAuth from 'hooks/use-auth'
import HeaderUserbox from './sidebarLayout/Header/Userbox'
import { UserStatus } from 'utils/enum'

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { pathname } = useLocation()
  const isHome = pathname === routes.home
  const isAbout = pathname === routes.about
  const isSupport = pathname === routes.support
  const isCoverage = pathname === routes.warranty
  const isBlog = pathname === routes.blog
  // const isGetAQoute = pathname === routes.qoute
  const isLogin = pathname === routes.login
  const { user, isLoggingIn } = useAuth()

  const closeSidebar = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <StyledHeader>
        <Flex css={{ flexShrink: 0 }}>
          <IconButton className="menu-open" onClick={() => setIsOpen(true)} aria-label="Open main menu">
            <MenuIcon fontSize={24} aria-hidden="true" />
            <span className="sr-only">Open main menu</span>
          </IconButton>
          <Box as={Link} to="/" css={{ display: 'inline-flex', alignItems: 'center' }}>
            <Box as="img" css={{ 'width': 160, 'ml': '1rem', '@sm': { ml: '2rem' }, '@lg': { width: 220, ml: 0 } }} src="/images/dlt-alert-logo.png" alt="logo" />
          </Box>
        </Flex>

        <StyledNav aria-label="Main menu" aria-expanded={isOpen}>
          <Flex className="menu-close" align="center" css={{ p: '$4', pb: '2rem' }}>
            <IconButton onClick={closeSidebar}>
              <CloseIcon fontSize={24} aria-hidden="true" />
              <span className="sr-only">Close main menu</span>
            </IconButton>
            <Box as={Link} to="/" css={{ display: 'inline-flex', alignItems: 'center' }}>
              <Box as="img" css={{ width: 187 }} src="/images/dlt-alert-logo.png" alt="logo" />
            </Box>
          </Flex>

          <StyledUl>
            <li onClick={closeSidebar}>
              <StyledNavLink className={isHome ? 'active' : undefined} to={routes.home}>
                Home
              </StyledNavLink>
            </li>
            <li onClick={closeSidebar}>
              <StyledNavLink className={isAbout ? 'active' : undefined} to={routes.about}>
                About
              </StyledNavLink>
            </li>
            <li onClick={closeSidebar}>
              <StyledNavLink className={isSupport ? 'active' : undefined} to={routes.support}>
                Contact Us
              </StyledNavLink>
            </li>
            <li onClick={closeSidebar}>
              <StyledNavLink className={isCoverage ? 'active' : undefined} to={routes.warranty}>
                Coverage
              </StyledNavLink>
            </li>
            <li onClick={closeSidebar}>
              <StyledNavLink className={isBlog ? 'active' : undefined} to={routes.blog}>
                Blog
              </StyledNavLink>
            </li>
            <Box
              as="li"
              className="self-center"
              css={{
                'display': 'none',
                '@lg': {
                  display: 'block',
                },
              }}
            >
              <StyledSeparator />
            </Box>
            {user?.status === UserStatus.ACCEPTED && isLoggingIn ? (
              <>
                <StyledNavLink className={isAbout ? 'active' : undefined} to={routes.incidents}>
                  Dashboard
                </StyledNavLink>
                <li className="self-center">
                  <HeaderUserbox />
                </li>
              </>
            ) : (
              <>
                <li onClick={closeSidebar}>
                  <StyledNavLink className={isLogin ? 'active' : undefined} to={routes.login}>
                    Login
                  </StyledNavLink>
                </li>
                {/* Commenting right now,might need it later */}
                {/* <li className="self-center">
                  <Button css={{ ml: '1rem' }} as={Link} to={routes.register} className="login-btn">
                    REGISTER
                  </Button>
                </li> */}
              </>
            )}
            {/* <li onClick={closeSidebar}>
              <StyledNavLink className={isGetAQoute ? 'active' : undefined} to={routes.qoute}>
                Get a quote
              </StyledNavLink>
            </li> */}
          </StyledUl>
        </StyledNav>
      </StyledHeader>
      <StyledBackdrop hidden={!isOpen} onClick={closeSidebar} />
    </>
  )
}

const StyledHeader = styled('header', {
  'position': 'fixed',
  'width': '100%',
  'top': 0,
  'left': 0,
  'zIndex': 101,
  'padding': '1rem 1.15rem',
  'bgImage': '$headerGradient',
  'backdropFilter': 'blur(20px)',
  'borderRadius': '0px 0px 15px 15px',

  '.login-btn': {
    margin: '0 0 0.75rem 1rem',
  },

  '@lg': {
    'position': 'static',
    'width': 'auto',
    'display': 'flex',
    'alignItems': 'center',
    'left': 0,
    // padding is in conjunction with userbox styles
    'borderRadius': '0px 0px 15px 15px',
    'backdropFilter': 'blur(20px)',
    'mx': 54,
    'linearGradient': '$linearGradientGray',

    '& .menu-open': {
      display: 'none',
    },
    // adjusting userbox, so that we can get right dropdown position
    '&  .user-box-btn': {
      paddingRight: 'calc(15px + 32px)',
      paddingBlock: '22px',
    },
    // doing like this because of userbox
    '& .login-btn': {
      my: 0,
    },
  },
})

const StyledNav = styled('nav', {
  'position': 'fixed',
  'left': 0,
  'top': 0,
  'height': '100vh',
  'transform': 'translateX(-100%)',
  'transition': 'transform 0.3s ease, box-shadow 0.3s ease',
  'bg': '$offcanvasGradient',
  'backdropFilter': 'blur(20px)',
  'borderRadius': '0px 15px 15px 0px',
  'minWidth': 320,

  '&[aria-expanded="true"]': {
    transform: 'translateX(0%)',
    outline: 'none',
    boxShadow: '3px 0 12px $black40',
    ml: 0,
  },

  '@lg': {
    'position': 'static',
    'height': 'auto',
    'transition': 'none',
    'bg': 'none',
    'borderRadius': 0,
    'backdropFilter': 'none',
    'ml': 'auto',
    'transform': 'none',
    'alignSelf': 'stretch',

    '& .menu-close': {
      display: 'none',
    },
  },
})

const StyledUl = styled('ul', {
  'my': 0,
  'ml': 'auto',
  'listStyle': 'none',
  'p': 0,

  '@lg': {
    'display': 'flex',
    'width': 'auto',
    'height': '100%',

    '& .self-center': {
      alignSelf: 'center',
    },
  },
})

// can't directly use NavLink due to an issue with stitches
const StyledNavLink = styled(Link, {
  'display': 'block',
  'border': '1px solid $white66',
  'borderRadius': '12px',
  'margin': '1rem 15px',
  'linearGradient': '$linearGradientGray',
  'mb': '$3',
  'pl': '$4',
  'py': 10,
  'color': '$white',
  'fontSize': '$base',
  'textTransform': 'uppercase',
  'textDecoration': 'none',

  '@lg': {
    'position': 'relative',
    'dflex': 'center',
    'height': '100%',
    'bg': 'none',
    'border': 'none',
    'fontWeight': '$normal',
    'py': 0,
    'px': '1.4rem',
    'mb': 0,

    '&:after': {
      content: '',
      display: 'block',
      position: 'absolute',
      bottom: '0rem',
      width: '100%',
      height: '2px',
      backgroundColor: 'transparent',
      zIndex: 1,
    },

    '&:hover': {
      '&:after': {
        backgroundColor: '$primaryBorder',
      },
    },

    '&.active': {
      '&:after': {
        backgroundColor: '$primaryBorder',
      },
    },
  },

  '@xl': {
    px: '2rem',
  },
})

const StyledBackdrop = styled('div', {
  position: 'fixed',
  inset: '0',
  zIndex: 100,
  background: '$black80',
  cursor: 'default',
})

const StyledSeparator = styled('div', {
  'height': 1,
  'width': '100%',
  'my': '$4',

  '@lg': {
    bgColor: '$gray3',
    width: 1,
    height: 42,
    my: 0,
  },
})

export default Header
