import * as React from 'react'
import { SVGProps } from 'react'

const SvgDottedLineMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 1a1 1 0 0 0-2 0h2ZM7.293 41.707a1 1 0 0 0 1.414 0l6.364-6.364a1 1 0 0 0-1.414-1.414L8 39.586l-5.657-5.657A1 1 0 0 0 .93 35.343l6.364 6.364ZM7 3a1 1 0 0 0 2 0H7Zm2 4a1 1 0 0 0-2 0h2Zm-2 4a1 1 0 1 0 2 0H7Zm2 4a1 1 0 1 0-2 0h2Zm-2 4a1 1 0 1 0 2 0H7Zm2 4a1 1 0 1 0-2 0h2Zm-2 4a1 1 0 1 0 2 0H7Zm2 4a1 1 0 1 0-2 0h2Zm-2 4a1 1 0 1 0 2 0H7Zm2 4a1 1 0 1 0-2 0h2ZM7 1v2h2V1H7Zm0 6v4h2V7H7Zm0 8v4h2v-4H7Zm0 8v4h2v-4H7Zm0 8v4h2v-4H7Zm0 8v2h2v-2H7Z"
      fill="url(#dotted-line-mobile_svg__a)"
    />
    <defs>
      <linearGradient id="dotted-line-mobile_svg__a" x1={8} y1={41.714} x2={8} y2={-1.143} gradientUnits="userSpaceOnUse">
        <stop stopColor="#B79313" />
        <stop offset={1} stopColor="#B79313" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgDottedLineMobile
