import { Link } from 'react-router-dom'
import { styled } from 'styles'
import { Box } from './elements/Box'
import { Flex } from './elements/Flex'
import { routes } from 'configs/constants'
import moment from 'moment'

const Footer = () => {
  return (
    <StyledFooter>
      <StyledWrapper>
        <Flex direction="column" css={{ gridArea: 'logo', pr: '4rem' }}>
          <Box as="img" src="/images/dlt-alert-logo.png" alt="dlt alert company logo" css={{ maxWidth: 190 }} />
          <Box as="img" src="/images/dlt-warranty.png" alt="dlt alert company logo" css={{ maxWidth: 100 }} />
        </Flex>
        <LinksColumn css={{ gridArea: 'links' }}>
          <h4>Quick Links</h4>
          <ul>
            <li>
              <Link to={routes.home}>Home</Link>
            </li>
            <li>
              <Link to={routes.about}>About</Link>
            </li>
          </ul>
        </LinksColumn>

        <LinksColumn css={{ gridArea: 'social' }}>
          <h4>Socials</h4>
          <ul>
            <li>
              <a href="https://www.linkedin.com/company/dlt-alert/" rel="noreferrer" target="_blank">
                Linkedin
              </a>
            </li>
            <li>
              <a href="https://twitter.com/DLTAlert" rel="noreferrer" target="_blank">
                Twitter
              </a>
            </li>
          </ul>
        </LinksColumn>

        <LinksColumn css={{ gridArea: 'contact' }}>
          <h4>Contact Us</h4>
          <ul>
            <li>
              <a href="mailto:info@dltalert.com">info@dltalert.com</a>
            </li>
          </ul>
        </LinksColumn>
        <LinksColumn css={{ gridArea: 'terms' }}>
          <Box as="a" href="/terms-of-service.html" css={{ pt: '0px !important', mb: '$2 !important' }}>
            Terms & Conditions
          </Box>

          <Box as="a" href="/privacy-policy.html">
            Privacy & Policy
          </Box>
        </LinksColumn>
      </StyledWrapper>
      <Box as="p" css={{ fontSize: '$xs', textAlign: 'center', mb: 0 }}>
        © {moment().year()} DLT Alert. All rights reserved.
      </Box>
    </StyledFooter>
  )
}

const StyledFooter = styled('footer', {
  bgColor: '$black',
  px: '$4',
  py: '$7',
})

const StyledWrapper = styled('div', {
  'display': 'grid',
  'gridTemplateAreas': `
    "logo logo"
    "links social"
    "contact contact"
    "terms terms"`,
  'rowGap': '$6',
  'mb': '$7',

  '@sm': {
    gridTemplateAreas: `
      "logo . ."
      "links social contact terms"`,
    gridAutoColumns: 'minmax(100px, 1fr)',
  },

  '@md': {
    gridTemplateAreas: `
      "logo links social contact terms"`,
    maxWidth: 'fit-content',
    mt: 27,
    mx: 'auto',
  },
})

const LinksColumn = styled('div', {
  'display': 'flex',
  'flexDirection': 'column',
  'alignItems': 'flex-start',
  '& > ul': {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  '& > h4': {
    fontWeight: '$normal',
    fontSize: '$base',
    mb: '$3',
  },
  '& a, & .info': {
    display: 'inline-block',
    fontSize: '$sm',
    color: '$white',
    opacity: 0.75,
    py: '$2',
    mb: '$1',
    textDecoration: 'none',
  },
})

export default Footer
