import { Box } from 'components/elements'
import { styled } from 'styles'
import SidebarMenu from './SidebarMenu'
import { useNavigate } from 'react-router-dom'
import { routes } from 'configs/constants'
import { HamburderProps } from 'components/icons/hamburger/hamburger'

const Sidebar = ({ toggleSidebar, sidebarToggle }: HamburderProps) => {
  const navigate = useNavigate()

  return (
    <Box
      css={BackdropFilterCSS}
      className={`${toggleSidebar ? 'show--backdrop' : ''}`}
      onClick={event => {
        const clickedElement = event.target as HTMLElement
        const isSettingElement = clickedElement.classList.contains('is--setting')
        const isSettingElementChild = clickedElement.closest('.is--setting')
        if (!isSettingElement && !isSettingElementChild) {
          sidebarToggle?.()
        }
      }}
    >
      <SidebarWrapper className={`${toggleSidebar ? 'show--sidebar' : ''}`}>
        <Box css={{ pl: 34, pt: 54, pb: 30, cursor: 'pointer' }} onClick={() => navigate(routes.home)}>
          <img width="190" src="/images/dlt-alert-logo.png" alt="logo" />
        </Box>
        <SidebarMenu />
      </SidebarWrapper>
    </Box>
  )
}

const BackdropFilterCSS = {
  'position': 'fixed',
  'width': '100%',
  'height': '100vh',
  'background': '$white1A',
  'backdropFilter': 'blur(3px)',
  'opacity': 0,
  'pointerEvents': 'none',
  'transition': 'opacity .25s ease-in-out',
  'zIndex': 50,

  '&.show--backdrop': {
    pointerEvents: 'all',
    opacity: 1,
  },
  '@md': {
    pointerEvents: 'all',
    opacity: 1,
    width: 0,
  },
}

const SidebarWrapper = styled('div', {
  'display': 'flex',
  'flexDirection': 'column',
  'minWidth': '$space$sidebarWidth',
  'width': '$space$sidebarWidth',
  'color': '$trueWhite70',
  'height': '100%',
  'bgImage': '$offcanvasGradient',
  'position': 'fixed',
  'left': 0,
  'top': 0,
  'transform': 'translateX(-100%)',
  'transition': 'transform .25s ease-in-out',

  '&.show--sidebar': {
    transform: 'translateX(0)',
  },
  '@md': {
    transform: 'translateX(0)',
  },
})

export default Sidebar
