import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { Main } from 'views/style'

export function MainLayout() {
  return (
    <>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  )
}
