import * as React from 'react'
import { SVGProps } from 'react'

const SvgKeyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#key-icon_svg__a)" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path d="M9.333 22.167a4.667 4.667 0 1 0 0-9.333 4.667 4.667 0 0 0 0 9.333ZM12.658 14.175l9.508-9.508M21 5.833l2.333 2.334M17.5 9.334l2.333 2.333" />
    </g>
    <defs>
      <clipPath id="key-icon_svg__a">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgKeyIcon
