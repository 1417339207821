import { Flex } from 'components/elements'
import { Card, CardBorder } from 'components/elements/Card'
import React from 'react'
import { styled } from 'styles'

type FormWrapperProps = {
  children: React.ReactNode
  title?: string
  text?: JSX.Element | string
  style?: React.CSSProperties
  logo?: string
  logoHidden?: boolean
  logoStyle?: React.CSSProperties
  controlWidth?: React.CSSProperties
}

function FormWrapper(props: FormWrapperProps) {
  const { children, title, logoHidden, text, style, logo = '/images/dlt-alert-logo.png', logoStyle, controlWidth } = props
  return (
    <Flex css={BodyItemMiddle} style={controlWidth}>
      <CardBorder>
        <StyledCard style={style}>
          {logoHidden && logo && <Logo src={logo} alt="website-logo" style={logoStyle} />}
          {title && <Title css={{ mb: text ? '$3' : '$5' }}>{title}</Title>}
          {text && <SubText>{text}</SubText>}
          {children}
        </StyledCard>
      </CardBorder>
    </Flex>
  )
}

export const BodyItemMiddle = {
  'minHeight': 'calc(100vh - 85px)',
  'alignItems': 'center',
  'justifyContent': 'center',
  'maxWidth': 550,
  'width': '100%',

  '& > div': {
    width: '100%',
  },
}

const StyledCard = styled(Card, {
  'padding': '2.5rem 1.5rem',
  'textAlign': 'center',

  '@md': {
    padding: '3.75rem',
  },
})

export const Logo = styled('img', {
  display: 'block',
  margin: '0 auto',
  maxWidth: 240,
  marginBottom: 40,
})

const Title = styled('h2', {
  textTransform: 'capitalize',
  textAlign: 'center',
})

const SubText = styled('p', {
  mb: '$6',
  textAlign: 'center',
  color: '$gray4',
})

export default FormWrapper
