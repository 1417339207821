import * as React from 'react'
import { SVGProps } from 'react'
const SvgRightArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.83 15.546a1.125 1.125 0 0 1 0-1.591L6.784 8 .83 2.046A1.125 1.125 0 0 1 2.42.455l6.75 6.75c.44.439.44 1.151 0 1.59l-6.75 6.75c-.439.44-1.151.44-1.59 0Z"
      fill="#fff"
    />
  </svg>
)
export default SvgRightArrowIcon
