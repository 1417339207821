import { styled } from 'styles'
import { TextField } from 'components/form'
import { Box } from 'components/elements/Box'
import { BgCircularPattern } from 'components/elements/BgPattern'
import { Button } from 'components/button/Button'
import { useState } from 'react'
import { useAddConnectionRequest } from './api/connectionRequest'
import SnackBar from 'components/SnackBar'
import { NotificationMessages } from 'utils/enum'
import { Section, StyledWrapper } from 'views/style'

const C2Action = () => {
  const [email, setEmail] = useState('')
  const [notification, setNotification] = useState(false)
  const { mutate, isLoading } = useAddConnectionRequest()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const payload = {
      email: email,
    }
    mutate(payload, {
      onSuccess: () => {
        setEmail('')
        setNotification(true)
      },
    })
  }

  return (
    <Section css={{ position: 'relative' }}>
      <BgCircularPattern css={{ right: '-7%', top: '25%' }} />
      <StyledWrapper css={SolutionCss}>
        <Box css={{ maxWidth: 650, mx: 'auto' }}>
          <Box css={{ textAlign: 'center' }}>
            <Box as="h2">DLT Alert Solutions</Box>
            <Box as="h3" css={{ fontWeight: '$normal' }}>
              DLT Alert offers embedded warranty protection from ransomware and cyber-attacks with automated claims approvals and immediate financial payouts.
            </Box>
          </Box>
          <StyledForm onSubmit={handleSubmit} css={{ m: '2.5rem auto 0' }}>
            <TextField fullWidth type="email" required placeholder="Enter Email" value={email} onChange={e => setEmail(e.target.value)} />
            <Button type="submit" size="lg" disabled={isLoading}>
              {isLoading ? 'Connecting...' : 'Connect'}
            </Button>
          </StyledForm>
        </Box>
      </StyledWrapper>

      <SnackBar
        open={notification}
        onClose={() => {
          setNotification(false)
        }}
        text={NotificationMessages.CONNECTION_REQUEST}
        severity={'success'}
      />
    </Section>
  )
}

const StyledForm = styled('form', {
  'display': 'flex',
  'flexDirection': 'column',
  'margin': 'auto',
  'mb': '$2',
  'gap': '$4',
  '& .input-wrapper': {
    minHeight: 55,
    flexBasis: '100%',
  },
  '& button': {
    minWidth: 163,
  },
  '@md': {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 510,
    gap: '$5',
  },
})
export const SolutionCss = {
  width: '90%',
  bg: '$cardGradient',
  margin: 'auto',
  maxWidth: '1300px',
}

export default C2Action
