import { Box } from 'components/elements'
import { styled } from 'styles'

export const Main = styled('main', {
  'padding': '0 1.15rem',
  'overflow': 'hidden',
  'bgImage': "url('/images/bg-pattern.png')",
  'backgroundSize': 'auto 640px',
  'backgroundRepeatY': 'no-repeat',
  'marginTop': 72,
  'minHeight': 'calc(100vh - 100px)',

  '.section-title': {
    mb: '$6',
    textAlign: 'center',
  },

  '@sm': {
    '.section-title': {
      mb: '$8',
      fontSize: '2rem',
    },
  },

  '@md': {
    marginTop: 0,
    padding: '0 54px',
  },
})

export const Section = styled('section', {
  'mb': '$6',
  'mt': '$6',
  '@md': {
    mb: '$13',
    mt: '$13',
  },
  '@lg': {
    mb: '$10',
    mt: '$10',
  },
})

export const CardTextBox = {
  'maxWidth': 630,
  'mb': 40,
  '@md': { mb: 0 },
}

export const DateOverlap = {
  position: 'absolute',
  right: '.75rem',
  top: '.75rem',
}

export const ItemChipOverlap = {
  position: 'absolute',
  left: '.75rem',
  bottom: '.75rem',
}

export const StyledWrapper = styled('div', {
  'bgImage': '$aboutGradient',
  'borderRadius': '$base',
  'backdropFilter': 'blur(20px)',

  'p': '$4',
  'py': '$6',
  '& p': {
    color: '$red',
    pr: 12,
  },
  '@sm': {
    p: '$6',
  },
  '@md': {
    p: '$8',
  },
})

export const rightStyle = {
  right: '-10%',
  top: '55%',
}

export const leftStyle = {
  left: '-8%',
  bottom: '-20%',
}

export const bodyStyle = {
  display: 'flex',
  justifyContent: 'center',
}

export const BoxTextCenter = {
  display: 'flex',
  alignItems: 'center',
}

export const FormStyle = {
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '2rem',
  maxWidth: 650,
  margin: 'auto',
}

export const ProfileItem = {
  'pb': '1.25rem',
  'mb': '1.25rem',
  'borderBottom': '2px solid $primary',

  '.MuiAvatar-root': {
    size: '4rem',
  },
}

export const ProfileTextCSS = {
  pl: '1.5rem',
  flexDirection: 'column',
}

export const ContentImage = styled(Box, {
  'display': 'flex',
  'flex': '0 0 calc(100% - 0.5rem)',
  '@md': {
    flex: '0 0 calc(30% - 0.5rem)',
  },
})
export const BlogContentSection = styled(Box, {
  'display': 'flex',
  'flexDirection': 'column',

  'flex': '0 0 calc(100% - 0.5rem)',
  '@md': {
    flex: '0 0 calc(70% - 0.5rem)',
  },
})

export const BlogSection = {
  marginTop: '.5rem',
}

export const CustomRadioButton = styled('input', {
  'width': '12px',
  'height': '12px',
  'backgroundColor': 'transparent',
  'display': 'inline-flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'flexShrink': 0,
  'padding': '0px',
  'border': '1.5px solid var(--colors-blue4)',
  'borderRadius': '50%',
  'color': 'var(--colors-fg)',
  'overflow': 'hidden',
  '&[data-state="unchecked"]': {
    userSelect: 'none',
    appearance: 'none',
  },
  '&[data-state="checked"]': {
    accentColor: 'var(--colors-primary)',
  },
})
export const CheckBoxInput = styled('input', {
  // Your styling for the unchecked state
  '&[data-state="unchecked"]': {
    appearance: 'none',
    width: '20px',
    height: '20px',
    border: '2px solid $gray7',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    position: 'relative',
  },
  // Your styling for the checked state (with tick)
  '&[data-state="checked"]': {
    appearance: 'none',
    width: '20px',
    height: '20px',
    border: '2px solid transparent',
    borderRadius: '4px',
    outline: 'none',
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: '$primary',
    accentColor: 'var(--colors-primary)',
  },
  // Styling for the tick mark when checked
  '&[data-state="checked"]::before': {
    content: '""',
    position: 'absolute',
    top: '24%', // Adjust the top position as needed
    left: '16%', // Adjust the left position as needed
    width: '11px',
    height: '5px',
    border: '2px solid white',
    borderImage: 'initial',
    borderTop: 'none',
    borderRight: 'none',
    opacity: 1,
    transform: 'rotate(306deg)', // Rotate the tick to the desired angle (306 degrees)
    transition: 'transform 0.2s ease',
  },
})

export const userRegisterQuoteCss = {
  textAlign: 'left',
  cursor: 'pointer',
  marginTop: '10px',
}
export const userRegHeadingCss = {
  marginTop: '$4',
  marginBottom: '$5',
  textAlign: 'center',
  width: '100%',
}
export const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
})

export const Label = styled('label', {
  display: 'flex',
  gap: '1rem',
  marginBottom: 14,
})
export const ErrorCSS = {
  fontSize: '$sm',
  color: '$error',
  textAlign: 'end',
}
