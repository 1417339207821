import { styled, type CSS } from 'styles'
import { Box } from './Box'

export const StyledCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  bg: '$cardGradient',
  boxShadow: '0px 4px 24px -1px $black33',
  borderRadius: '$base',
})

export const Card = StyledCard

type CardBorderProps = {
  children: React.ReactNode
  innerCss?: CSS
  innerClassName?: string
  outerClassName?: string
  outerCss?: CSS

  borderWidth?: CSS['padding']
  borderRadius?: CSS['borderRadius']
}

export const CardBorder = (props: CardBorderProps) => {
  const { children, borderWidth = 2, borderRadius = '$base', innerCss, outerCss, innerClassName, outerClassName } = props

  return (
    <Box
      className={outerClassName}
      css={{
        bgImage: '$borderGradient',
        borderRadius,
        padding: borderWidth,
        ...outerCss,
      }}
    >
      <Box
        className={innerClassName}
        css={{
          bgColor: '$background',
          size: '100%',
          borderRadius,
          ...innerCss,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
