import { globalCss } from './stitches.config'

export const globalStyles = globalCss({
  'html': {
    fontSize: '16px',
    boxSizing: 'border-box',
    colorScheme: 'dark',
  },
  '*, *:before, *:after': {
    boxSizing: 'inherit',
  },
  'body': {
    margin: 0,
    padding: 0,
    backgroundColor: '$background',
    fontFamily: '$inter',
    color: '$text',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    textRendering: 'optimizeLegibility',
    overFlowX: 'hidden',
  },
  '.overflow--hidden': {
    'overflow': 'hidden',
    '&--modal': {
      overflow: 'hidden !important',
    },

    '@md': {
      overflow: 'visible',
    },
  },
  'h1, h2, h3, h4, h5, h6': {
    margin: '0 0 1rem',
  },
  'h2, h3, h4, h5, h6': {
    fontFamily: '$inter',
  },
  'h1': {
    fontSize: '$2xl',
    fontWeight: '$semibold',
    lineHeight: 1,
  },
  'h2': {
    fontSize: '$xl',
    fontWeight: '$semibold',
    lineHeight: 1,
  },
  'h3': {
    fontSize: '$lg',
    fontWeight: '$semibold',
    lineHeight: 2,
  },
  'h4': {
    fontSize: '$base',
    fontWeight: '$semibold',
    lineHeight: 1.5,
  },
  'h5': {
    fontSize: '$sm',
    fontWeight: '$semibold',
    lineHeight: 1.25,
  },
  'p': {
    'fontFamily': '$inter',
    'fontSize': '$base',
    'fontWeight': '$normal',
    'lineHeight': 1.5,
    'margin': '0 0 1rem',

    '&.small': {
      fontSize: '$sm',
    },
  },
  'a, span': {
    fontFamily: '$inter',
    fontSize: '$base',
    color: '$white',
    fontWeight: '$normal',
    lineHeight: 1.5,
    textDecoration: 'none',
    margin: '0',
  },
  'b': {
    fontFamily: '$inter',
    fontSize: '$base',
    fontWeight: '$semibold',
    lineHeight: 1.5,
    margin: '0 0 1rem',
  },
  'button': {
    fontFamily: '$grotesk !important',
    fontWeight: '$medium',
    padding: 0,
    border: 'none',
    cursor: 'pointer',
  },
  'th': {
    fontWeight: 500,
  },
  'img': {
    maxWidth: '100%',
  },
  'video': {
    maxWidth: '100%',
  },
  '.primary': {
    color: '$primary',
  },
  '.gray3': {
    color: '$gray3',
  },
  '.fontBold': {
    fontWeight: '$bold',
  },
  '.sr-only': {
    position: 'absolute',
    border: 0,
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
  },
  // controversial way to do this
  '#root': {
    maxWidth: '1920px',
    margin: '0 auto',
  },
  // TODO: improve and limit the scope of these styles
  [`.MuiTypography-root, 
    .MuiIconButton-root, 
    .MuiClockNumber-root, 
    .MuiPickersCalendarHeader-label,
    .MuiPickersDay-root, 
    .MuiMenuItem-root`]: {
    fontFamily: '$grotesk !important',
    color: '$gray5 !important',
  },
  '.MuiTablePagination-root': {
    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
      marginBottom: 0,
    },
  },
  '.MuiChip-root': {
    '&.MuiChip-colorDefault': {
      'background': '$chipGradient',
      'padding': '0.3rem 0.1rem',
      'borderRadius': '0.5rem',
      'height': 'auto',

      'svg.MuiChip-avatar': {
        color: '$primary',
      },
    },
    '&.MuiChip-outlined': {
      padding: 0,
      borderColor: '$primary',
      background: '$aboutGradient',
    },
  },
  '.MuiCardMedia-root': {
    '&.MuiCardMedia-media': {
      '&.custom-class': {
        '@sm': {
          maxWidth: '500px',
          width: 'auto',
        },
      },
    },
  },
  '.MuiTabs': {
    '&-root': {
      '.MuiButtonBase-root': {
        'display': 'grid',
        'minHeight': 'auto',
        'color': '$white',
        'border': '1px solid',
        'borderColor': '$primary',
        'borderRadius': 8,
        'transition': 'background .2s',
        'fontFamily': '$inter',
        'fontWeight': '$normal',
        'fontSize': '1rem',

        '&.Mui-selected': {
          color: '$white',
          background: '$primary',
        },
      },
      '.MuiTabs-flexContainer': {
        paddingBottom: 4,
        gap: 10,
        flexWrap: 'wrap',
      },
    },
    '&-indicator': {
      display: 'none',
    },
  },
  '.MuiPaper-root, .MuiPickersDay-root': {
    'backgroundColor': '$blue5 !important',

    '&.MuiAlert-filled': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1.5rem 1.5rem 2rem !important',
      borderRadius: '.75rem !important',
      minWidth: 300,
    },

    '.MuiAlert-icon': {
      order: 2,
      margin: 0,
      padding: '0 0 1rem',

      svg: {
        width: '3.75rem',
        height: '3.75rem',
      },
    },
    '.MuiAlert-message': {
      padding: 0,
      textAlign: 'center',
      order: 3,
    },
    '.MuiAlert-action': {
      'padding': 0,

      '.MuiButtonBase-root': {
        'marginTop': -8,

        '.MuiSvgIcon-root': {
          path: {
            fill: '$white',
          },
        },
      },
    },
  },
  '.MuiClock-pin, .MuiClockPointer-root': {
    backgroundColor: '$primaryLight !important',
  },
  '.MuiClockPointer-thumb': {
    backgroundColor: '$primary !important',
    borderColor: '$primary !important',
  },
  '.MuiButton-text': {
    fontFamily: '$grotesk !important',
    fontSize: '0.875rem !important',
    fontWeight: '$medium !important',
    color: '$primary !important',
  },
  '.MuiPickersToolbar-content .Mui-selected': {
    borderRadius: 8,
    backgroundColor: '$background !important',
    padding: 5,
  },
  '.MuiPickersDay-today': {
    color: '$primary !important',
    borderColor: '$primary !important',
  },
  '.MuiPickersDay-root.Mui-selected': {
    backgroundColor: '$primary !important',
  },
  '.dropdown': {
    '&Width': {
      'left': '0 !important',
      'right': '0 !important',
      'borderRadius': '.5rem',
      'zIndex': 10,
      'maxHeight': 300,
      'overflow': 'overlay',

      '&::-webkit-scrollbar': {
        width: '4px',
      },

      '&::-webkit-scrollbar-track': {
        background: 'transparent',
        borderRadius: '2px',
      },

      '&::-webkit-scrollbar-thumb': {
        background: '$white',
        borderRadius: '2px',
      },

      '.MuiPaper-root': {
        backgroundColor: 'transparent',
      },
    },
    '&List': {
      'color': '$white',
      'padding': '.75rem 1rem',
      'cursor': 'pointer',
      '&:hover': {
        backgroundColor: '$background40',
      },
    },

    '&-hidden': {
      position: 'absolute',
      top: '-9999px',
      zIndex: '-10',
    },
  },
  '.table--mobile': {
    'border': 'none !important',

    'tbody': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    'tr': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      border: '1px solid $gray8',
      borderRadius: 14,
      mb: '1rem',

      td: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    '.detail--view': {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  'tr.expanded': {
    'background': '$background',
    'position': 'fixed',
    'top': 0,
    'left': 0,
    'right': 0,
    'bottom': 0,
    'zIndex': 10,
    'overflowY': 'auto',
    'margin': 0,
    'borderRadius': 0,
    'border': 0,

    '.back--button': {
      justifyContent: 'unset',
      p: '1.5rem',
      width: '100%',
      borderRadius: '0 0 8px 8px',
      background: '$aboutGradient',
      boxShadow: '0 1px 0 $white66',
    },
    '&--bg-color': {
      //directly assign bcz of safari
      background: '-webkit-linear-gradient( 90.18deg,  #32385B 0%, #1A1F41 100%)',
    },
    '.detail-box': {
      'border': 0,
      'width': '100%',
      'height': '100%',
      'p': 0,

      '@md': {
        padding: '2rem 0px 1rem',
      },
    },
    '.detail-container': {
      'padding': '1.5rem',

      '@md': {
        padding: '0 4rem',
      },
    },
    '@md': {
      //directly assign bcz of safari
      background: '-webkit-linear-gradient( 90.18deg,  #32385B 0%, #1A1F41 100%)',
      position: 'static',
      height: 'auto',
    },
  },
  '.minWidth': {
    '&-50': {
      minWidth: '50px',
    },
    '&-75': {
      minWidth: '75px',
    },
    '&-100': {
      minWidth: '100px',
    },
    '&-125': {
      minWidth: '125px',
    },
    '&-150': {
      minWidth: '150px',
    },
    '&-175': {
      minWidth: '175px',
    },
    '&-200': {
      minWidth: '200px',
    },
    '&-225': {
      minWidth: '225px',
    },
    '&-250': {
      minWidth: '250px',
    },
  },
  '.success': {
    'color': '$green',
    '&Bg': {
      'color': '$green',
      'background': '$green33',
      '&--only': {
        background: '$green33',
      },
    },
    'path': {
      fill: '$green',
    },
  },
  '.info': {
    'color': '$blue1',
    '&Bg': {
      'color': '$blue1',
      'background': '$blue33',
      '&--only': {
        background: '$blue33',
      },
    },
    'path': {
      fill: '$blue1',
    },
  },
  '.warning': {
    'color': '$yellow',
    '&Bg': {
      'color': '$yellow',
      'background': '$yellow33',
      '&--only': {
        background: '$yellow33',
      },
    },
    'path': {
      fill: '$yellow',
    },
  },
  '.error': {
    'color': '$red',
    '&Bg': {
      'color': '$red',
      'background': '$red33',
      '&--only': {
        background: '$red33',
      },
    },
    'path': {
      fill: '$red',
    },
  },
  '.primarys': {
    'color': '$primary',
    '&Bg': {
      'color': '$white',
      'background': '$primary',
      '&--only': {
        background: '$primary',
      },
    },
    'path': {
      fill: '$red',
    },
  },
  '#tempContainer': {
    '.stepperBox': {
      padding: '0 0 1rem',
    },
  },
  '.social-icons': {
    'columnGap': '.5rem',
    'padding': '1rem 0',

    'button': {
      'background': '$downloadGradient',
      'padding': '.25rem',
      'borderRadius': 6,
      '&:hover': {
        background: '$offcanvasGradient',
      },
    },
    '&.icons-details': {
      button: {
        'background': '$offcanvasGradient',
        '&:hover': {
          background: '$white10',
        },
      },
    },
  },
})
